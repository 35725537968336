import {  CircularProgress, Divider, ListItem, ListItemText, MenuItem, Select, InputLabel, FormControl, Typography, Stack } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import useThemeStore from "src/store/themeStore";
import { useFilterStore } from "src/store/filterStore";
import NotificationAlert from "../Alert";
import CustomText from "../CustomText";
import { GetFinanceDetailPeriodeList } from "src/api/finance";
import CustomInput from "../CustomInput";

interface DropdownPeriodePicker {
  id: string;
  name: string;
  disabled?: boolean;
  defaultValue?: string;
  fetchCondition:string;
  value?: string;
  onChange?: (e: any) => void;
}

export default function CustomUserDropdown({
  id,
  name,
  disabled,
  defaultValue,
  value,
  fetchCondition,
  onChange,
}: DropdownPeriodePicker) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [periodeList, setPeriodeList] = useState<any[]>([]);
  const [selectedPeriode, setSelectedPeriode] = useState<any | null>(null);

  const { theme } = useThemeStore();

  // Fetch users based on the condition (either from props or other logic)
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await GetFinanceDetailPeriodeList(fetchCondition);
      setPeriodeList([
        { id_periode: "Select a Period",
          period_start:"",
          period_end:"",
          disable:true,
          period_label:"Select a Period"
         }, 
        ...(response?.data || [])
      ]);      setSelectedPeriode({})
    } catch (error: any) {
      console.error("Error fetching users:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fetchCondition) {
      fetchUsers();
    }
  }, [fetchCondition]); 

  const handleChange = (event: any) => {
    const selectedPeriode = periodeList.find((user: any) => user.id_periode === event.target.value);
    setSelectedPeriode(selectedPeriode);
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: selectedPeriode.id_periode, // Format date to only include the year
        },
      });
    }
  };


  return (
<CustomInput
  onChange={handleChange}
  required
  size="small"
  id="periode_year"
  name="periode_year"
  select
  value={
    selectedPeriode?.id_periode || 
    (periodeList.length ? periodeList[0]?.id_periode : '') // Fallback to placeholder if no data
  }
  defaultValue=""
>
  {/* Add a placeholder */}
  {periodeList.map((option) => (
    <MenuItem key={option?.id_periode} value={option?.id_periode} disabled={option?.disable}>
      {option?.period_label}
    </MenuItem>
  ))}
</CustomInput>

  );
}
