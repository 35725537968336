import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomText from "src/components/main/CustomText";
import DifferenceIcon from "@mui/icons-material/Difference";
import { Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, MenuItem, Modal, Stack } from "@mui/material";
import CustomDivider from "src/components/main/CustomDivider";
import useThemeStore from "src/store/themeStore";
import CustomButton from "src/components/main/CustomButton";
import { DecimalFormatter } from "src/utils/formatter";
import { hexToRgba } from "src/utils/utils";
import CustomPaper from "src/components/main/CustomPaper";
import { useCallback, useEffect, useState } from "react";
import CustomDropbox from "src/components/main/CustomDropbox";
import ModalConfirm from "src/components/main/ModalConfirm";
import { addIncentiveBonunusses, disburseIncentive } from "src/api/finance";
import { useNavigate } from "react-router-dom";
import NewInputNominal from "src/components/main/NewInputNominal";
import CustomInput from "src/components/main/CustomInput";
import NotificationAlert from "src/components/main/Alert";
import { AddMappingMenu, GetRoleMenuUnasignee } from "src/api/access-management/role";
import { getRoleUser } from "src/api/user";
import { useParameterStore } from "src/store/parameterStore";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    data: any;
    refetch: () => void;
    onDetail?:boolean
    roleId:string;
    fetchRole:() => void;
  }



export default function ModalAddMenuManagement(props: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen, onClose, data, refetch,onDetail,roleId } = props;
    const theme = useTheme();
    const { theme: myTheme } = useThemeStore();
    const [menuList, setmenuList] = useState<any[]>([]);
    const [selectedMenu, setSelectedMenu] = useState<string[]>([]);
    const [addedData,setAddedData] = useState<boolean>(false);


const fetchMenuData = async () => {
  try {
    // Fetch data for the menu based on the roleId
    const response = await GetRoleMenuUnasignee(roleId);
    
    // Check if the response is valid and has data
    const menuData = response?.data || [];

    // Set the menu list with a default option followed by the fetched data
    setmenuList([
      { 
        id: "Select a Menu", 
        menu_name: "", 
        menu_title: "", 
        disable: true 
      },
      ...menuData
    ]);

    // Reset the selected menu
  } catch (error) {
    // Handle error if fetching the data fails
    console.error("Error fetching role menu:", error);
  }
};

  useEffect(() => {
      fetchMenuData();
  }, [addedData]); 
  


  
  
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [doneAction, setDoneAction] = useState<string[]>([]);
  
    const [formData, setFormData] = useState<AddMenu>({
        role_id:roleId,
        menu_id:"",
        selectedMenu:[],
      });
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData: any) => ({ ...prevData, [name]: value }));
      };
  
    const handleSubmit = async () => {

      try {
        const response = await AddMappingMenu(formData);
      } catch (error: any) {
        console.error("Error fetching Leave detail:", error);
      }
        refetch()
        setAddedData(!addedData)
        setFormData({
          role_id:roleId,
          menu_id:"",
          selectedMenu:[],
        })
        setSelectedMenu([]);
        onClose();
    //   
    };
    const handleClose = () => {
      onClose();
    };

 const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value, checked } = event.target;

  setSelectedMenu((prevSelectedMenu) => {
    const updatedMenu = checked
      ? [...prevSelectedMenu, value] // Add the selected value
      : prevSelectedMenu.filter((id) => id !== value); // Remove the unselected value

    // Update formData with the new selectedMenu
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedMenu: updatedMenu,
    }));

    return updatedMenu;
  });
};
  
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <CustomPaper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "auto",
            boxShadow: 24,
            pt: 2,
            overflow:"auto"
          }}
        >
          <Box sx={{ width: "100%", flexGrow: 1 }}>
            <Box sx={{ px: 4 }}>
              <Stack direction={"row"} gap={2}>
                <DifferenceIcon
                  style={{ color: myTheme?.icon }}
                  fontSize="large"
                />
                <CustomText variant="h6" gutterBottom>
                  Add Menu Access
                </CustomText>
              </Stack>
              <CustomDivider sx={{ mt: 1 }} />
              <Box sx={{ py: 2 }}>
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  alignContent={"center"}
                  justifyContent={"flex-start"}
                >
                  <CustomText
                    variant="subtitle1"
                    fontWeight={"bold"}
                    gutterBottom
                  >
                    {data?.year_month}
                  </CustomText>
                </Stack>
                <Grid container columnSpacing={2}>
                  <Grid item sm={4}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Role Code
                      </CustomText>
                      <CustomText variant="subtitle1" fontWeight={700}>
                        {data?.Code}
                      </CustomText>
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Role
                      </CustomText>
                      <CustomText variant="subtitle1" fontWeight={700}>
                        {data?.Name}
                      </CustomText>
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Menu List
                      </CustomText>
                      <div style={{ maxHeight: '600px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
  <FormGroup>
    {/* Render checkboxes for each menu item */}
    {menuList.map((option) => (
      <FormControlLabel
        key={option?.id}
        control={
          <Checkbox
            value={option?.id}
            checked={selectedMenu.includes(option?.id)} // Check if the option is selected
            onChange={handleChangeSelect}
            disabled={option?.disable} // Disable the checkbox if option is disabled
            sx={{
              color: 'white', // Change the default color
              '&.Mui-checked': {
                color: 'white', // Change the checked color
              },
            }}
          />
        }
        label={`${option?.menu_name} - ${option?.menu_title}`}
      />
    ))}
  </FormGroup>
</div>

                    </Stack>
                  </Grid>
                </Grid>  
                <Box mt={2}>
                    <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                      <CustomButton
                        variant="contained"
                        onClick={() => setConfirmDialog(true)}
                      >
                        Add Menu
                      </CustomButton>
                    </Box>
                </Box>
              </Box>
            </Box>
  
            <ModalConfirm
              text="Are you sure you want to Add Menu ?"
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              onSubmit={handleSubmit}
            />
          </Box>
        </CustomPaper>
      </Modal>
    );
}
