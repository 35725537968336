import { Box } from "@mui/material";
import ShippingAddress from "../../OrderCreate/components/ShippingAddress";
import CustomButton from "src/components/main/CustomButton";

export default function ListShippingAddress() {
  return (
    <Box>
      <ShippingAddress />
      <Box textAlign={"right"}>
        <CustomButton variant="contained">Save</CustomButton>
      </Box>
    </Box>
  );
}
