import {
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import InputDate from "src/components/main/Datepicker";
import { useEffect, useState } from "react";
import FilterAccordion from "src/components/main/Filter/FilterAccordion";
import FilterAccordionDetail from "src/components/main/Filter/FilterAccordionDetail";
import FilterHeader from "src/components/main/Filter/FilterHeader";
import CustomCheckbox from "src/components/main/CustomCheckbox";
import { TASK_STATUSES } from "src/constants/task";

interface Props {
  mode: string;
  filter: FilterTable;
  onChangeFilter: (name: string, value: any) => void;
}

export default function Filter({ filter, onChangeFilter,mode }: Props) {
  const { theme } = useThemeStore();
  const [tempStartDate, setTempStartDate] = useState<string>(filter.start_date);
  const [tempEndDate, setTempEndDate] = useState<string>(filter.end_date);
  const [showMore, setShowMore] = useState<boolean>(false);

  // Change filter only if both start date and end date exist
  useEffect(() => {
    if (tempStartDate && tempEndDate) {
      onChangeFilter("start_date", tempStartDate);
      onChangeFilter("end_date", tempEndDate);
    }
    if (tempStartDate === "" && tempEndDate === "") {
      onChangeFilter("start_date", "");
      onChangeFilter("end_date", "");
    }
  }, [tempStartDate, tempEndDate]);

  const filterData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "start_date") {
      setTempStartDate(value);
      value > tempEndDate && tempEndDate !== "" && setTempEndDate(value);
    }

    if (name === "end_date") {
      setTempEndDate(value);
    }
  };

 
  const handleFilterStatus = (event: any) => {
    const filterStatus = event.target;
    if (filterStatus.checked) {
      onChangeFilter("status", [...filter.status, filterStatus.name]);
    } else {
      onChangeFilter(
        "status",
        filter.status.filter((status) => status !== filterStatus.name)
      );
    }
  };

  return (
    <Box>
      <FilterHeader />
      <FilterAccordion
        defaultExpanded
        variant="outlined"
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          aria-controls="panel1-content"
        >
          <CustomText>Status</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          {TASK_STATUSES.slice(0, showMore ? TASK_STATUSES.length : 4).map(
            (item) => (
              <FormGroup key={item}>
         <FormControlLabel
                control={<CustomCheckbox sx={{ color: theme?.checkbox }} />}
                label={item}
                name={item}
                id={item}
                disabled={mode === "all" ? false : true}
                checked={filter.status.includes(item) ? true : false}
                onChange={handleFilterStatus}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: theme?.inputLabel,
                    "&.Mui-disabled": {
                      color: theme?.inputTextDisabled,
                    },
                  },
                }}
              />
              </FormGroup>
            )
          )}
          {TASK_STATUSES.length > 4 && (
            <Link
              component="button"
              variant="body2"
              color={theme?.textLink}
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {showMore ? "Show Less" : "Show More"}
            </Link>
          )}
        </FilterAccordionDetail>
      </FilterAccordion>
      <FilterAccordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          id="date"
        >
          <CustomText>Due Date</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                Start Date
              </CustomText>
              <InputDate
                id="start_date"
                name="start_date"
                value={filter.start_date}
                onChange={(e) => filterData(e)}
              />
            </Stack>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                End Date
              </CustomText>
              <InputDate
                id="end_date"
                name="end_date"
                minDate={tempStartDate}
                value={filter.end_date}
                onChange={(e) => filterData(e)}
              />
            </Stack>
          </Stack>
        </FilterAccordionDetail>
      </FilterAccordion>
    </Box>
  );
}
