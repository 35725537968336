import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InquiryItemList } from "src/api/item";
import NotificationAlert from "src/components/main/Alert";
import CustomCard from "src/components/main/CustomCard";
import CustomDivider from "src/components/main/CustomDivider";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import { formatRelativeTime, hexToRgba } from "src/utils/utils";

const data = [
  {
    id: "M-MRS-030724-011.1",
    status: "Supplier Bidding",
  },
  {
    id: "M-MRS-030724-012.1",
    status: "Upload Design",
  },
  {
    id: "M-MRS-030724-013.1",
    status: "Supplier Bidding",
  },
  {
    id: "M-MRS-030724-014.1",
    status: "Waiting Approval From Client",
  },
  {
    id: "M-MRS-030724-015.1",
    status: "Supplier Bidding",
  },
  {
    id: "M-MRS-030724-016.1",
    status: "Shipment From Supplier",
  },
  {
    id: "M-MRS-030724-017.1",
    status: "Shipment to Client",
  },
  {
    id: "M-MRS-030724-018.1",
    status: "Supplier Bidding",
  },
  {
    id: "M-MRS-030724-019.1",
    status: "Supplier Bidding",
  },
  {
    id: "M-MRS-030724-020.1",
    status: "Supplier Bidding",
  },
];

export default function RecentUpdates() {
  const { theme } = useThemeStore();
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const fetchRecentUpdates = async () => {
    try {
      const response = await InquiryItemList({
        order_key: "updated_at",
        sort_type: "DESC",
        page: 1,
        limit: 10,
      });
      response?.data?.list ? setData([...response.data.list]) : setData([]);
    } catch (error: any) {
      console.error("Error fetching order list:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  useEffect(() => {
    fetchRecentUpdates();
  }, []);

  const handleClick = (id: string) => {
    navigate(`/item/${id}`);
  };

  return (
    <CustomCard text="Recent Updates" sx={{ height: "100%", mt: 0 }}>
      <Box mt={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  border: "none",
                  padding: "0px 0px 0px 10px",
                  color: theme?.inputLabel,
                  backgroundColor: theme?.bgSecondary,
                }}>
                <CustomText variant="body1" margin={"5px"} fontWeight={700}>
                  Item ID
                </CustomText>
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                  padding: 0,
                  color: theme?.inputLabel,
                  backgroundColor: theme?.bgSecondary,
                }}>
                <CustomText variant="body1" margin={"5px"} fontWeight={700}>
                  Status
                </CustomText>
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                  padding: "0px 10px 0px 0px",
                  color: theme?.inputLabel,
                  backgroundColor: theme?.bgSecondary,
                }}>
                <CustomText
                  variant="body1"
                  textAlign={"right"}
                  margin={"5px"}
                  fontWeight={700}>
                  Time
                </CustomText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: any, index: number) => {
              return (
                <TableRow
                  onClick={() => handleClick(item?.id)}
                  key={index}
                  className="hover:opacity-50 cursor-pointer"
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "rgba(255,255,255, 0.1)" },
                  }}>
                  <TableCell
                    style={{
                      borderBottom: `1px solid ${theme?.tableBorder}`,
                      padding: "6px",
                    }}>
                    <CustomText variant="body2" fontWeight={400}>
                      {item?.id}
                    </CustomText>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: `1px solid ${theme?.tableBorder}`,
                      padding: "6px",
                    }}>
                    <CustomText variant="body2" fontWeight={400}>
                      {item?.status}
                    </CustomText>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: `1px solid ${theme?.tableBorder}`,
                      // padding: "0px 10px 0px 0px",
                      padding: "6px",
                    }}>
                    <CustomText
                      variant="body2"
                      textAlign={"right"}
                      fontWeight={400}>
                      {formatRelativeTime(item?.updated_at?.split(".")[0])}
                    </CustomText>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </CustomCard>
  );
}
