import {
  Box,
  Stack,
  Grid,
  InputAdornment,
} from "@mui/material";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import InputDate from "src/components/main/Datepicker";
import NewInputNominal from "src/components/main/NewInputNominal";

interface Props {
  disabled?: boolean;
  value?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function ReportForms({ disabled, value, onChange }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Box width={"100%"}>
      <Grid container columnSpacing={4}>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Report ID</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="id"
              name="id"
              value={value?.id || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Revenue (Crypto)</CustomText>
            <NewInputNominal
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="crypto"
              name="crypto"
              value={value?.crypto || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Salary Cost</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="salaryCost"
              name="salaryCost"
              value={value?.salaryCost || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Date</CustomText>
            <InputDate
              onChange={handleChange}
              disabled={disabled}
              id="date"
              name="date"
              value={value?.date || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Revenue (USD)</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="usd"
              name="usd"
              value={value?.usd || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Nett Profit</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="profit"
              name="profit"
              value={value?.profit || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
        <Stack my={"10px"}>
            <CustomText variant="body1">Revenue (IDR)</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="idr"
              name="idr"
              value={value?.idr || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Item Cost</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="itemCost"
              name="itemCost"
              value={value?.itemCost || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Status</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="status"
              name="status"
              value={value?.status || ""}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
