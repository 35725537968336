import { Box } from "@mui/material";
import CustomText from "../CustomText";
import useThemeStore from "src/store/themeStore";

export default function FilterHeader() {
  const { theme } = useThemeStore();

  return (
    <Box
      height={73}
      display={"flex"}
      flexDirection={"row"}
      alignContent={"center"}
      sx={{
        backgroundColor: theme?.tableHeader,
        borderRadius: "8px 8px 0 0",
        borderTop: `1px solid ${theme?.border}`,
        borderLeft: `1px solid ${theme?.border}`,
        borderRight: `1px solid ${theme?.border}`,
      }}
    >
      <Box sx={{ display: "inline-block", alignContent: "center", ml: 2 }}>
        <CustomText variant="subtitle1" fontWeight={700} align="center">
          Filter
        </CustomText>
      </Box>
    </Box>
  );
}
