import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import CustomInput from "src/components/main/CustomInput";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import {
  currencies,
  getCurrencySymbol,
  paymentMethod,
} from "src/utils/paramData";
import CustomButton from "src/components/main/CustomButton";
import CustomDropbox from "../CustomDropbox";
import { useState, useCallback, useEffect } from "react";
import {
  getDataPelunasan,
  getWAMessageOrderPelunasan,
  orderRepayment,
} from "src/api/order";
import { useLocation, useNavigate } from "react-router-dom";
import NewInputNominal from "../NewInputNominal";
import { getCurrentTime, parseAmount } from "src/utils/formatter";
import ModalConfirm from "../ModalConfirm";
import useThemeStore from "src/store/themeStore";
import CheckIcon from "@mui/icons-material/Check";
import { GetShipmentByCustomer } from "src/api/shipment";
import NotificationAlert from "../Alert";
import { useLoadingStore } from "src/store/loadingStore";
import { formatPhoneNumber } from "src/utils/utils";

interface Props {
  refetch: () => void;
  orderData: any;
}
export default function CompletePayment({ refetch, orderData }: Props) {
  const [message, setMessage] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const { showNotification } = useLoadingStore();
  const handleCopyClipboard = async () => {
    const { waMessage, phoneNumber } = await fetchWAMessage();
    if (waMessage) {
      navigator.clipboard
        .writeText(decodeURIComponent(waMessage?.replace(/\+/g, " ")))
        .then(() => {
          showNotification("Message copied to clipboard", "success");
        });
    }
  };

  const fetchWAMessage = async () => {
    try {
      const res = await getWAMessageOrderPelunasan({
        order_id: data.order_id,
        shipping_cost: Number(data.shipping_cost),
        address_id: data.address_id,
      });

      const waMessage = res?.data?.wa_message || "";
      const phoneNumber = res?.data?.no_telp || "";

      // Return both phoneNumber and waMessage
      return { waMessage, phoneNumber };
    } catch (error) {
      showNotification("Error", "error");
      return { waMessage: "", phoneNumber: "" }; // Return empty values in case of error
    }
  };

  const fetchShippingAddress = async () => {
    try {
      const response = await GetShipmentByCustomer(orderData?.customer_id);
      if (response?.data !== null) {
        setShippingList(response?.data);
        const index = response.data.findIndex((item: { id: any }) => {
          return item?.id === orderData?.shipment_id; // Use optional chaining
        });
        if (index !== -1) {
          setSelected(index);
        }
      }
    } catch (error: any) {
      console.error("Error fetching customer shipment:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };
  function convertToNumber(str: string, defaultValue: number = 0): number {
    const num: number = Number(str);
    return isNaN(num) ? defaultValue : num;
  }

  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState<any>({});
  const [shippingList, setShippingList] = useState<any[]>([]);
  const [data, setData] = useState<OrderCompletePaymentData>(
    {} as OrderCompletePaymentData
  );
  const [openModalSubmit, setOpenModalSubmit] = useState<boolean>(false);
  const { theme } = useThemeStore();
  const [selected, setSelected] = useState(0);

  const id = useLocation().pathname.split("/").pop();

  const fetchDataPelunasan = async () => {
    const res = await getDataPelunasan(id!);
    setData({
      ...res?.data,
      shipping_cost: 0,
      amount: res?.data?.amount?.toString(),
      address_id: orderData?.shipment_id,
      payment_method: paymentMethod?.filter(
        (item: any) => item?.currency === res?.data?.currency
      )[0].value,
    });
  };

  const handleWhatsAppClick = async () => {
    const { waMessage, phoneNumber } = await fetchWAMessage();

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const url = `https://wa.me/${formattedPhoneNumber}?text=${waMessage}`;

    if (waMessage) {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    fetchDataPelunasan();
    fetchShippingAddress();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const fileReader = useCallback((acceptedFiles: any[]) => {
    const fileDataArray: any[] = [];

    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr: any = reader.result;
        const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
        if (matches) {
          const mimeType = matches[1];
          const base64Data = matches[2];

          fileDataArray.push({
            bukti_payment_base64: base64Data,
            tipe_file: mimeType.split("/")[1],
          });

          if (fileDataArray.length === acceptedFiles.length) {
            setPaymentData((prevData: any) => ({
              ...prevData,
              attachments: [...(prevData.attachments || []), ...fileDataArray],
            }));
          }
        } else {
          console.error("Invalid file data");
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handlePaymentFileUpload = (acceptedFiles: any[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setPaymentData((prevData: any) => {
        const updatedAttachments = [...prevData.attachments];
        updatedAttachments.splice(index, 1);
        return {
          ...prevData,
          attachments: updatedAttachments,
        };
      });
    },
    [setPaymentData]
  );

  const handleSubmit = async () => {
    try {
      if (
        !data.payment_method?.trim() ||
        !data.shipping_cost?.trim() ||
        !paymentData?.attachments ||
        paymentData?.attachments.length === 0
      ) {

        return NotificationAlert({
          message: "All required fields must be filled.",
          status: "error",
        });
      }

      // Submit data
      const req: ReqOrderCompletePayment = {
        order_id: data?.order_id,
        payment_cost: parseAmount(data?.amount),
        payment_currency: data?.currency,
        payment_method: data?.payment_method,
        bukti_payment: paymentData?.attachments,
        shipping_cost: parseAmount(data?.shipping_cost),
        address_id: data?.address_id,
      };

      await orderRepayment(req);

      await refetch();
    } catch (error) {
      console.error("Error submitting payment:", error);
    }
  };

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalSubmit(true);
  };

  const handleSelectShipment = (index: number) => {
    setSelected(index);
    data.address_id = shippingList[index]?.id;
  };

  return (
    <Stack style={{ flexDirection: "row", gap: 24 }}>
      <Box
        component={"form"}
        onSubmit={handleFormSubmit}
        sx={{ width: { xs: "70%", md: "60%", lg: "50%" } }}>
        <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}>
            <CustomText variant="body1" fontWeight={"bold"}>
              Upload Customer Complete Payment 30%
            </CustomText>
            <CustomText variant="body2">{getCurrentTime()}</CustomText>
          </Stack>
          <Box width={"80%"}>
            <Stack
              direction={"row"}
              display={"flex"}
              mt={2}
              justifyContent={"space-between"}
              spacing={4}>
              <Stack width={"50%"}>
                <CustomText variant="body1">Currency</CustomText>
                <CustomInput
                  required
                  disabled
                  size="small"
                  id="currency"
                  name="currency"
                  onChange={handleChange}
                  select
                  value={data?.currency || currencies[0].value}>
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </CustomInput>
              </Stack>
              <Stack width={"50%"}>
                <CustomText variant="body1" required>
                  Payment Method
                </CustomText>
                <CustomInput
                  required
                  size="small"
                  id="payment_method"
                  name="payment_method"
                  select
                  onChange={handleChange}
                  value={data?.payment_method}>
                  {paymentMethod
                    .filter((item) => item.currency === data?.currency)
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </CustomInput>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              display={"flex"}
              mt={2}
              justifyContent={"space-between"}
              spacing={4}>
              <Stack width={"50%"}>
                <CustomText variant="body1">Down Payment</CustomText>
                <NewInputNominal
                  required
                  disabled
                  value={data?.payment_dp?.toString()}
                  onChange={handleChange}
                  size="small"
                  id="payment_dp"
                  name="payment_dp"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCurrencySymbol(data?.currency)}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack width={"50%"}>
                <CustomText variant="body1" required>
                  Shipping Cost
                </CustomText>
                <NewInputNominal
                  required
                  value={data?.shipping_cost}
                  onChange={handleChange}
                  size="small"
                  id="shipping_cost"
                  name="shipping_cost"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCurrencySymbol(data?.currency)}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
            <Stack mt={2}>
              <CustomText variant="body1">Remaining Payment</CustomText>
              <NewInputNominal
                required
                disabled
                value={data?.sisa_payment?.toString()}
                onChange={handleChange}
                size="small"
                id="sisa_payment"
                name="sisa_payment"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack mt={2}>
              <CustomText variant="body1">Final Amount</CustomText>
              <NewInputNominal
                required
                disabled
                value={
                  (
                    convertToNumber(data?.sisa_payment) +
                    convertToNumber(data?.shipping_cost)
                  )?.toString() || "0"
                }
                size="small"
                id="final_amount"
                name="final_amount"
                onChange={handleChange}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Box mt={2}>
              <CustomText variant="body1" gutterBottom required>
                Proof of Payment
              </CustomText>
              <CustomDropbox
                files={paymentData?.attachments}
                filesType="base64"
                onUpload={handlePaymentFileUpload}
                deleteByIndex={true}
                onDeleteByIndex={handlePaymentFileDelete}
              />
            </Box>
            <Box mt={4}>
              <CustomText variant="body1">Send a Message</CustomText>
              <Stack
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  gap: { xs: 1, md: 2 },
                }}
                mt={2}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}>
                <Stack>
                  <CustomButton
                    variant="contained"
                    onClick={handleWhatsAppClick}>
                    WA.me Link
                  </CustomButton>
                </Stack>
                <Stack>
                  <CustomText variant="body1">or</CustomText>
                </Stack>
                <Stack>
                  <CustomButton
                    variant="contained"
                    onClick={() => handleCopyClipboard()}>
                    Copy to clipboard
                  </CustomButton>
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Box textAlign={"right"} mt={2}>
            <CustomButton variant="contained" type="submit">
              Submit
            </CustomButton>
          </Box>
        </CustomPaper>
        <ModalConfirm
          text="Are you sure to do this action?"
          open={openModalSubmit}
          onClose={() => setOpenModalSubmit(false)}
          onSubmit={handleSubmit}
        />
      </Box>
      <Box
        component={"form"}
        onSubmit={handleFormSubmit}
        sx={{ width: { xs: "70%", md: "60%", lg: "50%" } }}>
        <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
          <CustomText variant="body1" fontWeight={"bold"} mb={2}>
            Shipping Address
          </CustomText>
          <Box width={"100%"} flexDirection={"row"}>
            <Grid spacing={2}>
              {shippingList &&
                shippingList.map((item, index) => (
                  <Grid
                    item
                    xs={6}
                    key={index}
                    sx={{ marginBottom: 2 }}
                    onClick={() => handleSelectShipment(index)}>
                    <Paper
                      style={{ padding: 20, width: "100%" }}
                      sx={{
                        background: theme?.inputPrimary,
                        border:
                          index === selected
                            ? `1px solid ${theme?.button100}`
                            : "none",
                      }}>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"}>
                            <CustomText
                              variant="body1"
                              gutterBottom
                              fontWeight={600}>
                              {item?.recipient_name}
                            </CustomText>
                            <CustomText variant="body1">
                              &ensp;({item?.type_shipment})
                            </CustomText>
                          </Stack>
                          <CustomText variant="body1" gutterBottom>
                            {item?.recipient_contact}
                          </CustomText>
                          <CustomText variant="body1" gutterBottom>
                            {item?.address}
                          </CustomText>
                          <CustomText variant="body1" gutterBottom>
                            {item.country}
                          </CustomText>
                          <CustomText variant="body1" gutterBottom>
                            {item?.city}, {item?.province}, {item?.postal_code}
                          </CustomText>
                          <CustomText
                            variant="body1"
                            gutterBottom
                            fontWeight={"bold"}
                            mt={2}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                textDecoration: "underline",
                                WebkitTextFillColor: theme?.inputLabelHover,
                              },
                            }}
                            onClick={() =>
                              item?.id
                                ? navigate(
                                    `/customer/${orderData?.customer_id}`
                                  )
                                : navigate("/order/create/shipping")
                            }>
                            Edit Address
                          </CustomText>
                        </Stack>
                        <Box>
                          {selected === index ? (
                            <CustomButton
                              variant="contained"
                              startIcon={<CheckIcon />}
                            />
                          ) : (
                            <CustomButton
                              variant="contained"
                              onClick={() => handleSelectShipment(index)}>
                              Select
                            </CustomButton>
                          )}
                        </Box>
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </CustomPaper>
      </Box>
    </Stack>
  );
}
