import { Box, Card, InputAdornment, Modal, Stack ,IconButton} from "@mui/material";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { useEffect, useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import PageTitle from "src/components/main/CustomText/PageTitle";
import useThemeStore from "src/store/themeStore";
import { ROLE_LIST } from "src/constants/user";
import { DelteRole, InquiryRolePaginationList } from "src/api/access-management/role";
import ModalAddRole from "./components/modalAddRole";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModalConfirm from "src/components/main/ModalConfirm";
import { useParameterStore } from "src/store/parameterStore";
import { getRoleUser } from "src/api/user";


export default function AccessManagementList() {
  const { theme } = useThemeStore();
  const [leaves, setLeaves] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({ data: {}, status: "" });
  const [notes, setNotes] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string>("");

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    setOpenModalDelete(true);
    setSelectedData(id);
  };

  const navigate = useNavigate();

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await InquiryRolePaginationList({
        status: filter.status,
        id: filter.key,
        order_key: filter.order_key,
        sort_type: filter.sort_type,
        start_date: filter.start_date,
        end_date: filter.end_date,
        page: filter.page,
        limit: filter.limit,
      });
      response.data?.data ? setLeaves(formatData(response?.data?.data)) : setLeaves([]);
      handleFilterChange("total_page", response?.data?.total_page);
      response?.data?.totalPage < filter.page && handleFilterChange("page", 1);
    } catch (error: any) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [    filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.order_date_start,
    filter.order_date_end,]);

  const onClickDetail = (id: string) => {
    navigate(`/access-management/${id}`);
  };
  const [openModalAdd, setopenModalAdd] = useState<boolean>(false);

  const onClose = () => {
    setopenModalAdd(false);
  };

  
  const formatData = (data: any) => {
    const processedData = data?.map((item: any) => {
      return {
        ...item,
        actions: (
          <Stack direction={"row"} spacing={1} justifyContent={"center"}>
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255,255,255, 0.2)",
              },
            }}
            onClick={(e) => handleDelete(e, item?.id)}
          >
            <DeleteOutlineOutlinedIcon color="error" />
          </IconButton>
          </Stack>
        ),
      };
    });
  
    return processedData;
  };

  const handleActionAddMenu = () => {

    setopenModalAdd(true);
};
const onCloseModal = () => {
  setOpenModalDelete(false);
  setSelectedData("");
};
const onDelete = async () => {
  try {
    const response = await DelteRole(selectedData);
  } catch (error: any) {
    console.error("Error fetching Leave detail:", error);
  }
  onCloseModal();
  fetchData();
  fetchROleData();
};


const { setRoleList } = useParameterStore();


    
const fetchROleData = async () => {
  try {
    const response = await getRoleUser();
    setRoleList(response?.data?.data);
    // Reset the selected menu
  } catch (error) {
    // Handle error if fetching the data fails
    console.error("Error fetching role menu:", error);
  }
};


  return (
    <Box>
      <PageTitle>Access Management</PageTitle>
      <Card>
        <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Role List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
              
                <CustomButton
                  variant="contained"
                  onClick={handleActionAddMenu}
                  startIcon={<AddIcon />
                  }
                >
                  Add Role
                </CustomButton>
              </Box>
            </Box>
        </Card>
        <CustomTable
          columns={ROLE_LIST}
          data={leaves}
          onClickDetail={onClickDetail}
          filter={filter}
          onChangeFilter={handleFilterChange}
        />
              <ModalConfirm
        text="Are you sure you want to delete this Role?"
        open={openModalDelete}
        onSubmit={onDelete}
        onClose={onCloseModal}
      />
      <ModalAddRole
        isOpen={openModalAdd}
        onClose={onClose}
        refetch={fetchData}
      />
    </Box>
  );
}
