import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { currencies } from "src/utils/paramData";
import PurchasingForm from "../components/PurchasingForm";
import PageTitle from "src/components/main/CustomText/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { getPurchaseItemDetail } from "src/api/purchase";

export default function PurchasingDetail() {
  const [data, setData] = useState<PurchaseItem>({
    type: "PurchaseItem",
    currency: currencies.length > 0 ? currencies[0].value : "",
  } as PurchaseItem);

  const id = useLocation().pathname.split("/").pop();
  const navigate = useNavigate();

  const fetchData = async () => {
    const response = await getPurchaseItemDetail(id!);

    if (response?.status !== 200) {
      navigate("/purchase/purchasing");
    }

    setData(response?.data?.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const req = {
      ...data,
    };
  };

  return (
    <Box>
      <PageTitle>Purchasing Details</PageTitle>
      <PurchasingForm
        disabled={true}
        data={data}
        onChange={() => {}}
        onSubmit={handleSubmit}
        onUpload={() => {}}
        onDelete={() => {}}
      />
    </Box>
  );
}
