import { Box, Stack, Chip } from "@mui/material";
import CustomPaper from "../../CustomPaper";
import CustomText from "../../CustomText";

interface Props {
  data: any;
}
export default function ShipmentDetail({ data }: Props) {
  return (
    <CustomPaper sx={{ p: 2, width: "fit-content", mt: 2 }}>
      <Box display={"flex"} flexDirection={"row"} gap={4}>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Shipment ID
          </CustomText>
          <CustomText variant="subtitle1" fontWeight={700}>
            {data?.id}
          </CustomText>
        </Stack>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Expedition
          </CustomText>
          <CustomText variant="subtitle1" fontWeight={700}>
            {data?.expedition}
          </CustomText>
        </Stack>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Resi
          </CustomText>
          <CustomText variant="subtitle1" fontWeight={700}>
            {data?.resi}
          </CustomText>
        </Stack>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Status
          </CustomText>
          <CustomText variant="subtitle1" fontWeight={700}>
            {data?.status}
          </CustomText>
        </Stack>
      </Box>
    </CustomPaper>
  );
}
