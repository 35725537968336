import { SxProps, TextField, TextFieldProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomInputProps extends Omit<TextFieldProps, "variant"> {
  sx?: SxProps;
}

const CustomInput: React.FC<CustomInputProps> = (props) => {
  const { sx, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <TextField
      SelectProps={{
        MenuProps: {
          sx: { maxHeight: "300px" },
        },
      }}
      autoComplete="off"
      sx={{
        "& .MuiOutlinedInput-root": {
          background: theme?.inputPrimary,
          "& fieldset": {
            borderColor: theme?.inputBorder, // Warna border input
          },
          "&:hover fieldset": {
            borderColor: theme?.inputBorder, // Warna border input saat hover
          },
          "&.Mui-focused fieldset": {
            borderColor: theme?.inputBorder, // Warna border input saat dalam keadaan aktif (focus)
          },
        },
        "& .MuiInputBase-root": {
          color: theme.inputText, // Warna teks input
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: theme?.inputTextDisabled, // Warna teks input disabled
        },
        "&& .MuiInputLabel-root": {
          color: theme.inputLabel, // Warna teks label
        },
        "&& .MuiTypography-root": {
          color: theme.inputText, // Warna teks input
        },
        "& input[type='number']": {
          "-moz-appearance": "textfield", // Firefox
          "&::-webkit-outer-spin-button": {
            display: "none", // Chrome, Safari, and Opera
          },
          "&::-webkit-inner-spin-button": {
            display: "none", // Chrome, Safari, and Opera
          },
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default CustomInput;
