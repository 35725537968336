import { VisibilityOff, Visibility } from "@material-ui/icons";
import { InputAdornment, IconButton, SxProps, TextFieldProps } from "@mui/material";
import { useState } from "react";
import CustomInput from "src/components/main/CustomInput";
import useThemeStore from "src/store/themeStore";

interface Props extends Omit<TextFieldProps, "variant"> {
  sx?: SxProps;
}

export default function Password(props: Props) {
  const { sx, ...otherProps } = props;
  const { theme } = useThemeStore();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <CustomInput
      {...otherProps}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              sx={{ color: theme?.buttonText }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        ...sx,
      }}
    />
  );
}
