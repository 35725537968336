export default {
  primary100: "#D9D9D9",
  primary200: "#8E8E8E",
  black100: "#201F21",
  red100: "#DD1D1D",
  gray100: "#252525",
  gray200: "#3B3B3B",
  white: "#FFFFFF",
  lightred1: "#f77659",
  lightred2: "#F24822",
  lightyellow1: "#faaf46",
  lightyellow2: "#FFA629",
  lightgreen1: "#44c27f",
  lightgreen2: "#14AE5C",
  lightblue1: "#54b3f7",
  lightblue2: "#0D99FF"
};