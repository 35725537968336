import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import PageTitle from "src/components/main/CustomText/PageTitle";
import { createNewUser } from "src/api/user";
import { useState } from "react";
import UserForm from "../components/UserForm";
import { validatePassword } from "src/utils/utils";
import { useParameterStore } from "src/store/parameterStore";
import level from "src/assets/level.json";

export default function UserCreate() {
  const navigate = useNavigate();
  const { role } = useParameterStore();

  const [data, setData] = useState<User>({
    salary: 0,
    role: role[0]?.Id,
    level: level[0].value,
  } as User);
  const [error, setError] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await createNewUser(data);

    navigate("/user");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let newValue = value;

    if (name === "username") {
      if (value.includes(" ")) {
        newValue = value.replace(/\s+/g, "");
      }
    }

    if (name === "email") {
      if (value.includes(" ")) {
        newValue = value.replace(/\s+/g, "");
      }
    }
    if (name === "phone_number") {
      newValue = value.replace(/[^0-9]/g, "");
    }

    if (name === "password") {
      newValue = value.replace(/\s+/g, "");
      if (value && value.length !== 0 && !validatePassword(value)) {
        setError(
          "Password must be at least 8 characters. and Contains Numeric,Capital,and Special Char"
        );
      } else {
        setError("");
      }
    }
    setData((prevData: any) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  return (
    <Box>
      <PageTitle>User Create</PageTitle>
      <Box component={"form"} mt={2} onSubmit={handleSubmit}>
        <UserForm
          title="New User"
          action="create"
          isEdit={true}
          formData={data}
          error={error}
          onChange={handleChange}
        />

        <Box mt={4} display={"flex"} gap={2} justifyContent={"flex-end"}>
          <CustomButton
            onClick={() => navigate("/user")}
            variant="outlined"
            customType="cancel">
            Cancel
          </CustomButton>
          <CustomButton variant="contained" type="submit">
            Create User
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}
