import { HideLoading, ShowLoading } from "src/components/main/Loading";
import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import { sendRequestDELETE, sendRequestGET, sendRequestPOST, sendRequestPUT } from "../sendRequest";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();
  

export const GetCompanyData = async () => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/companySettings");
  const url = process.env.REACT_APP_ENDPOINT_COMPANY || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(
      `${url}/a2b87547-b308-4dae-8cb8-cbdea1835f8a`,
      header
    );

    console.info("ACTION COMPANY DETAIL", res);

    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR COMPANY DETAIL", error);
    throw new Error(error);
  } finally {
    hideLoading();
  }
};

export const UpdateCompanyData = async (data: any) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/companySettings");
  const url = process.env.REACT_APP_ENDPOINT_COMPANY_SETTING || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const formattedSettingData = data?.setting?.map((item: any) => ({
    ...item,
    junior: parseInt(item.junior, 10),
    middle: parseInt(item.middle, 10),
    senior: parseInt(item.senior, 10),
  }));

  const req = {
    company: {
      id: data?.company?.id,
      company_name: data?.company?.company_name,
      company_email: data?.company?.company_email,
      company_code: data?.company?.company_code,
    },
    address: data?.address,
    settinng: formattedSettingData,
  };

  try {
    ShowLoading({});
    const res = await sendRequestPUT(url, req, header);
    console.info("ACTION UPDATE COMPANY DETAIL", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE COMPANY DETAIL", error);
    throw new Error(error);
  } finally {
    HideLoading();
  }
};

export const updateInsentive = async (data: any) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/companySettings");
  const url = process.env.REACT_APP_ENDPOINT_COMPANY_SETTING || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const formattedSettingData = data?.setting?.map((item: any) => ({
    ...item,
    junior: parseInt(item.junior, 10),
    middle: parseInt(item.middle, 10),
    senior: parseInt(item.senior, 10),
  }));

  try {
    showLoading();
    const res = await sendRequestPOST(url, formattedSettingData, header);
    console.info("ACTION UPDATE INSENTIVE SETTINGS", res);
    showNotification(res.data.message, "success");
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE INSENTIVE SETTINGS", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getCollectiveLeave = async () => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_COLLECTIVE_LEAVE || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestGET(url, header);
    console.info("ACTION GET COLLECTIVE LEAVE", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET COLLECTIVE LEAVE", error);
    // showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
}

export const addCollectiveLeave = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_COLLECTIVE_LEAVE || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION ADD COLLECTIVE LEAVE", res);
    showNotification(res.data.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR ADD COLLECTIVE LEAVE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
}

export const deleteCollectiveLeave = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_COLLECTIVE_LEAVE || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestDELETE(`${url}/${id}`, header);
    console.info("ACTION DELETE COLLECTIVE LEAVE", res);
    showNotification(res.data.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR DELETE COLLECTIVE LEAVE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
}