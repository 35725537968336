import React from 'react';

interface Props {
  items: any[];
}

export default function Legend({ items }: Props) {
  const styles: { [key: string]: React.CSSProperties } = {
    legendContainer: {
      border: '1px solid #444', // Darker border
      padding: '10px',
      width: '100%',
      fontSize: '14px',
      backgroundColor: '#333', // Dark background color
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', // Slightly darker shadow
      marginTop: '10px',
      color: '#fff', // White text for dark theme
    },
    legendRow: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '20px',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
    },
    colorBox: {
      width: '30px',        // Default size for square/rectangle
      height: '30px',
      marginRight: '10px',
      borderRadius: '0%',   // Default for square
    },
    label: {
      fontSize: '14px',
      color: '#fff', // Ensure label text is also white
    },
  };

  return (
    <div style={styles.legendContainer}>
      <h4 style={{ color: '#fff' }}>Legend</h4> {/* White color for heading */}
      <div style={styles.legendRow}>
        {items.map((item, index) => (
          <div key={index} style={styles.legendItem}>
            <span
              style={{
                ...styles.colorBox,
                backgroundColor: item.fillColor || item.color,  // Use fillColor if defined, otherwise use the base color
                borderColor: item.borderColor || item.color,    // Border color (defaults to the item color)
                borderWidth: item.borderWidth || 2,             // Default border width is 2
                borderStyle: 'solid',
                width: item.shape === 'circle' ? '20px' : '30px',
                height: item.shape === 'circle' ? '20px' : '30px',
                borderRadius: item.shape === 'circle' ? '50%' : '0%', // If circle, make it round
                transform: item.shape === 'triangle' ? 'rotate(45deg)' : 'none', // Triangle rotation
              }}
            ></span>
            <span style={styles.label}>{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
