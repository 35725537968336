import { Box, Chip, Stack } from "@mui/material";
import { useState } from "react";
import { approveTask, reviseTask } from "src/api/task";
import CustomButton from "../../CustomButton";
import CustomInput from "../../CustomInput";
import CustomPaper from "../../CustomPaper";
import CustomText from "../../CustomText";
import InputDate from "../../Datepicker";
import ModalConfirm from "../../ModalConfirm";
import { useProcessStore } from "src/store/processStore";
import ClientApproveItem from "../../Process/ClientApproveItem";

export default function TaskDetail({ data, refetch }: any) {
  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [revData, setRevData] = useState<any>({});
  const [openModalRevision, setOpenModalRevision] = useState<boolean>(false);
  const [openModalApprove, setOpenModalApprove] = useState<boolean>(false);
  const { dataTask } = useProcessStore();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRevData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleRevision = async () => {
    const req: TaskRevision = {
      task_id: data?.task_id,
      main_day: totalDay,
      ...revData,
    };
    await reviseTask(req);
    await refetch();
    setRevData({});
    setIsRevision(false);
  };

  const handleApprove = async () => {
    const req: TaskApproval = {
      task_id: data?.task_id,
      is_completed: data?.task_id.startsWith("MP") ? true : false,
    };
    await approveTask(req);
    await refetch();
    setOpenModalApprove(false);
  };

  const totalDay =
    Math.floor(
      (new Date(revData?.end_date).getTime() -
        new Date(revData?.start_date).getTime()) /
        (1000 * 60 * 60 * 24)
    ) + 1;

  return (
    <CustomPaper sx={{ p: 2, width: "fit-content", mt: 2 }}>
      <Box display={"flex"} flexDirection={"row"} gap={4}>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Assignee
          </CustomText>
          <CustomText variant="subtitle1" fontWeight={700}>
            {data?.user_name || "Unassigned"}
          </CustomText>
        </Stack>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Task Deadline
          </CustomText>
          <CustomText variant="subtitle1" fontWeight={700}>
            {data?.end_date?.split("T")[0] || "-"}
          </CustomText>
        </Stack>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Task Priority
          </CustomText>
          <Chip
            color={
              data?.priority === "Low"
                ? "success"
                : data?.priority === "Medium"
                ? "warning"
                : "error"
            }
            sx={{ width: "fit-content" }}
            label={data?.priority}
          />
        </Stack>
        <Stack mb={2}>
          <CustomText variant="body2" gutterBottom={true}>
            Status
          </CustomText>
          {data?.id?.startsWith("R") &&
          data?.status === "Waiting for Approval" ? (
            <CustomText variant="subtitle1" fontWeight={700}>
              Waiting for Item to be Sent by Worker
            </CustomText>
          ) : (
            <CustomText variant="subtitle1" fontWeight={700}>
              {data?.status}
            </CustomText>
          )}
        </Stack>
      </Box>
      {/* {data?.task_id?.startsWith("M") && dataTask?.[data?.task_id]?.status === "Waiting for Approval" && (
        <ClientApproveItem task_id={data?.task_id} refetch={refetch} />
      )} */}
      {isRevision && (
        <Box>
          <Stack direction={"row"} mt={2} gap={2}>
            <Stack>
              <CustomText variant="body1">Start Date</CustomText>
              <InputDate
                id="start_date"
                name="start_date"
                onChange={handleChange}
                value={revData?.start_date}
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">End Date</CustomText>
              <InputDate
                id="end_date"
                name="end_date"
                onChange={handleChange}
                value={revData?.end_date}
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">Man Days</CustomText>
              <CustomInput
                required
                disabled
                size="small"
                id="main_day"
                name="main_day"
                value={totalDay || 0}
              />
            </Stack>
          </Stack>
          <Stack mt={2}>
            <Stack>
              <CustomText variant="body1">Notes</CustomText>
              <CustomInput
                size="small"
                id="notes"
                name="notes"
                onChange={handleChange}
                value={revData?.notes}
              />
            </Stack>
          </Stack>
        </Box>
      )}
      <Box mt={2} display={"flex"} justifyContent={"flex-end"} gap={2}>
        {isRevision ? (
          <>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={() => setIsRevision(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              disabled={totalDay < 1}
              onClick={() => setOpenModalRevision(true)}
            >
              Submit Revision
            </CustomButton>
          </>
        ) : !data?.task_id?.startsWith("M") &&
          dataTask?.[data?.task_id]?.status === "Waiting for Approval" ? (
          <>
            <CustomButton
              variant="contained"
              onClick={() => setIsRevision(true)}
            >
              Ask For Revision
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => setOpenModalApprove(true)}
            >
              Approve
            </CustomButton>
          </>
        ) : null}
      </Box>
      <ModalConfirm
        text="Are you sure want to ask for revision on this task?"
        open={openModalRevision}
        onClose={() => setOpenModalRevision(false)}
        onSubmit={handleRevision}
      />
      <ModalConfirm
        text="Are you sure want to approve this task?"
        open={openModalApprove}
        onClose={() => setOpenModalApprove(false)}
        onSubmit={handleApprove}
      />
    </CustomPaper>
  );
}
