import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Box, Card, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTable from "../../../components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { InquiryUserList } from "src/api/user";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomButton from "src/components/main/CustomButton";
import useThemeStore from "src/store/themeStore";
import { userRole } from "src/utils/paramData";
import { USER_COLUMNS } from "src/constants/user";
import CustomInput from "src/components/main/CustomInput";
import { useDebouncedCallback } from "use-debounce";
import FilterSearch from "src/components/main/Filter/FilterSearch";

function UserList() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const [userData, setUserData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const onClickDetail = (id: string) => {
    id === undefined ? navigate("/user") : navigate(`/user/${id}`);
  };

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

 

  const fetchData = async () => {
    try {
      const response = await InquiryUserList({
        status: filter.status,
        id: filter.key,
        order_key: filter.order_key,
        sort_type: filter.sort_type,
        start_date: filter.start_date,
        end_date: filter.end_date,
        page: filter.page,
        limit: filter.limit,
      });
      response?.data?.data ? setUserData([...response.data.data]) : setUserData([]);
      handleFilterChange("total_page", response?.data?.total_page);
      response?.data?.totalPage < filter.page && handleFilterChange("page", 1);
    } catch (error: any) {
      console.error("Error fetching item data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [
    filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.order_date_start,
    filter.order_date_end,
  ]);


  

  // const filterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   handleFilterChange("key", value);
  //   const filtered = userData?.filter((item: any) => {
  //     return item?.username?.toLowerCase().includes(value.toLowerCase()) || item?.email?.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setFilteredData(filtered);
  // };

  // const debouncedFilter = useDebouncedCallback(filterSearch, 1000);

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   debouncedFilter(e);
  // };

  return (
    <Box>
      <PageTitle>User</PageTitle>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              User List
            </CustomText>
          </Box>
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            {/* <CustomInput
              placeholder="Search"
              size="small"
              //onChange={handleFilterChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme?.icon }} />
                  </InputAdornment>
                ),
              }}
            /> */}
              <Box>
                <FilterSearch onChangeFilter={handleFilterChange} />
              </Box>
            <CustomButton
              variant="contained"
              onClick={() => navigate("/user/create")}
              startIcon={<AddIcon />}
            >
              Create User
            </CustomButton>
          </Box>
        </Box>
        <CustomTable
          columns={USER_COLUMNS}
          data={userData}
          onClickDetail={onClickDetail}
          identifier={"id"}
          filter={filter}
          sortable={false}
          onChangeFilter={handleFilterChange}
          />
      </Card>
    </Box>
  );
}

export default UserList;
