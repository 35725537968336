export const ORDER_COLUMNS = [
  { title: "Order ID", field: "id", sortable: true, minWidth: 200 },
  { title: "Customer Name", field: "customer_name", sortable: true },
  {
    title: "Amounts",
    field: "amount",
    sortable: false,
    align: "right",
    minWidth: 180,
  },
  { title: "Order Date", field: "created_at", sortable: true },
  { title: "Deadline", field: "deadline", sortable: true },
  { title: "Item Qty", field: "item_qty", sortable: true, align: "right" },
  { title: "Status", field: "status", sortable: true },
];

export const ORDER_STATUSES = [
  "Waiting Shipment",
  "Waiting Payment",
  "On Proccess",
  "Waiting Resi Shipping",
  "Waiting Resi to Cirebon",
  "On Delivery to Cirebon",
  "Received in Cirebon",
  "Canceled",
  "Done",
];
