export const REIMBURSEMENT_STATUS = ["Pending", "Rejected", "Approved", "Paid"];

export const REIMBURSEMENT_COLUMNS = [
  { title: "Periode", field: "label_year_month", sortable: false },
  { title: "User Name", field: "nama_worker", sortable: false },
  { title: "Total", field: "reimburse_count", sortable: false },
  { title: "Total Cost", field: "cost", sortable: false },
  { title: "Status", field: "status", sortable: false },
  { title: "Date", field: "claim_date", sortable: false },
];
