import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetRoleDetail } from "src/api/access-management/role";
import { GetLeaveDetail } from "src/api/user";
import CustomCard from "src/components/main/CustomCard";
import PageTitle from "src/components/main/CustomText/PageTitle";
import Leave from "src/components/main/LeaveDetail";
import ManagementDetail from "src/components/main/management";

export default function DetailManagement() {
  const navigate = useNavigate();
  const id = useLocation().pathname.split("/").pop();

  const [data, setData] = useState<any>({});
  const fetchDetailRole = async () => {
    try {
      const response = await GetRoleDetail(id!);
      response?.data ? setData(response?.data) : setData([]);
    } catch (error: any) {
      console.error("Error fetching Leave detail:", error);
      navigate("/leave");
    }
  };
  useEffect(() => {
    fetchDetailRole();
  }, []);

  return (
    <Box>
      <PageTitle>Role Management Detail</PageTitle>
      <CustomCard text="Management Data">
        <ManagementDetail data={data} listData={data?.list_menu} fetchNewData={fetchDetailRole} roleId={id!}/>
      </CustomCard>
    </Box>
  );
}
