import { Box, Card, Grid, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import IosShareIcon from "@mui/icons-material/IosShare";
import CustomButton from "src/components/main/CustomButton";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ActionDisbursment from "./components/ActionDisbursment";
import PageTitle from "src/components/main/CustomText/PageTitle";
import useThemeStore from "src/store/themeStore";
import { useEffect, useState } from "react";
import { SALARY_COLUMNS } from "src/constants/finance";
import { DecimalFormatter } from "src/utils/formatter";
import Filter from "./components/Filter";
import { downloadReportSalary, getFinanceSalaryList } from "src/api/finance";
import CustomToggle from "src/components/main/CustomToggle";
import { useLoadingStore } from "src/store/loadingStore";
import { useFilterStore } from "src/store/filterStore";

type TMode = "all" | "pending";

export default function FinanceSalary() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const { showNotification } = useLoadingStore.getState();


  const [mode, setMode] = useState<TMode>("all");
  const [data, setData] = useState<FinanceSalary[]>([]);
  const [filteredData, setFilteredData] = useState<FinanceSalary[]>([]);
  const [initialData, setInitialData] = useState<FinanceSalary[]>([]);

  const [checkedData, setCheckedData] = useState<FinanceSalary[]>([]);
  const [openDisbursment, setOpenDisbursment] = useState<boolean>(false);
  const [disburseList, setDisburseList] = useState<string[]>([]);
  const { status, resetFilterStore } = useFilterStore();
  const [attachmentDetail,setattachmentDetail] = useState<string>("");


  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
      if (status.includes("Pending")){
        setMode("pending")
      }else{
        setMode("all")
      }
    
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await getFinanceSalaryList({
        status: filter.status,
        id: filter.key,
        order_key: filter.order_key,
        sort_type: filter.sort_type,
        start_date: filter.start_date,
        end_date: filter.end_date,
        page: filter.page,
        limit: filter.limit,
      });
      response?.data?.data ? setData(formatData([...response.data.data])) : setData([]);
      handleFilterChange("total_page", response?.data?.total_page);
      setInitialData(formatData([...response.data.data]))
      response?.data?.totalPage < filter.page && handleFilterChange("page", 1);
    } catch (error: any) {
      console.error("Error fetching item data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [    
    filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.order_date_start,
    filter.order_date_end,]);

  const formatData = (data: any) => {
    return data?.map((item: any) => {
      return {
        ...item,
        period: item?.period?.split("T")[0].slice(0, 7),
        disbursed_date: item?.disbursed_date?.split("T")[0],
        amount: `Rp ${DecimalFormatter(item?.salary?.toString())}`,
      };
    });
  };


  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setDisburseList(data?.map((item: any) => item.id));
    } else {
      setDisburseList([]);
    }
  };

  const downloadReportFinanceSalaryFunc = async () => {
    await downloadReportSalary({
      status: filter.status,
      id: filter.key,
      order_key: filter.order_key,
      sort_type: filter.sort_type,
      start_date: filter.start_date,
      end_date: filter.end_date,
      page: filter.page,
      limit: filter.limit,
    });
  };


  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const { checked } = event.target;

    if (checked) {
      setDisburseList((prev: any) => [...prev, id]);
    } else {
      setDisburseList((prev: any) =>
        prev.filter((item: string) => item !== id)
      );
    }
  };

  const handleAction = () => {
    const checkedData = data?.filter((item: any) =>
      disburseList.includes(item.id)
    );
    if (checkedData?.length < 1 || !checkedData) {
      showNotification("Please Select at Least 1 Item", "error");
    }else{
      setCheckedData(checkedData);
      setOpenDisbursment(true);
    }
  
  };

  const onClickDetail = async (id: string) => {
    // Find the item with the matching id_grouping
    const checkedDataDetail = initialData.find(
      (checkedItem: any) => checkedItem.id === id
    );
  
    // If item is found, update checkedData
    if (checkedDataDetail) {
      console.log("Attachment",checkedDataDetail)
      const items: FinanceSalary[] = [checkedDataDetail]; // Initialize with the found item
      setCheckedData(items); // Update state
      setattachmentDetail(checkedDataDetail?.attachment)
      setOpenDisbursment(true);
    }
  };

  useEffect(() => {
    // Apply filter status from selected item in dashboard
    handleFilterChange("status", [...status]);
    return () => {
      resetFilterStore();
    };
  }, []);  


  const onClose = () => {
    setOpenDisbursment(false);
    fetchData();
  };

  return (
    <Box>
      <PageTitle>Finance Salary</PageTitle>
      <Grid container columnSpacing={2}>
        <Grid item sm={2}>
          <Filter
            mode={mode}
            filter={filter}
            onChangeFilter={handleFilterChange}
          />
        </Grid>
        <Grid item sm={10}>
          <Card>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Salary List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <Box
                  display={"flex"}
                  gap={2}
                  justifyContent={"space-between"}
                  alignContent={"center"}
                >
           
                  <CustomButton
                    disabled={disburseList?.length === 0 || mode === "all" || !disburseList}
                    variant="contained"
                    onClick={() => handleAction()}
                    startIcon={<TaskOutlinedIcon />}
                  >
                    Action
                  </CustomButton>
                </Box>
                <CustomButton onClick={downloadReportFinanceSalaryFunc} variant="contained" startIcon={<IosShareIcon />}>
                  Export to Excel
                </CustomButton>
              </Box>
            </Box>
            <CustomTable
              columns={SALARY_COLUMNS}
              data={data}
              checkbox={mode !== "all"}
              selectedItem={disburseList}
              rowCount={data?.length || 0} // Add a fallback of 0
              onSelectAll={handleSelectAll}
              onCheckboxChange={handleClick}
              filter={filter}
              onChangeFilter={handleFilterChange}
              sortable={false}
              onClickDetail={onClickDetail}
            />
          </Card>
        </Grid>
      </Grid>
      <ActionDisbursment
        isOpen={openDisbursment}
        onClose={onClose}
        data={checkedData}
        refetch={fetchData}
        attachmentDetai={attachmentDetail}
      />
    </Box>
  );
}
