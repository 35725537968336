import { Box, ImageListItem, Modal } from "@mui/material";
import { useState } from "react";
import CustomPaper from "../../CustomPaper";

interface Props {
  image: Attachment[];
  size?: number;
}

export default function ImageHolder({ image, size = 200 }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<string>("");
  return (
    <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}>
      {image?.length > 0 &&
        image?.map((item: any, index: number) => (
          <ImageListItem
            key={index}
            sx={{
              marginRight: 2,
              width: size,
              height: size,
              borderRadius: 2,
              overflow: "hidden",
            }}
            onClick={() => {
              setItem(item?.url_foto);
              setOpen(true);
            }}>
            <img
              src={
                item?.url_thumb_nail ||
                "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
              }
              loading="lazy"
            />
          </ImageListItem>
        ))}
      <Modal open={open} onClose={() => setOpen(false)}>
        <img
          src={
            item ||
            "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
          }
          onClick={() => setOpen(false)}
          loading="lazy"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            objectFit: "contain",
          }}
        />
      </Modal>
    </Box>
  );
}
