import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { useEffect, useRef, useState } from "react";
import { Box, Slide, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useThemeStore from "src/store/themeStore";
import { GetTaskCalendar, GetUnassignedTask } from "src/api/task";
import { hexToRgba } from "src/utils/utils";
import Paper from "@mui/material/Paper";
import CustomText from "src/components/main/CustomText";
import ConfirmAssignTask from "./ConfirmAssignTask";
import CircleIcon from "@mui/icons-material/Circle";
import useMount from "src/hooks/useMount";

interface Props {
  openDialog: boolean;
  filter: TaskFilter;
  setOpenDialog: () => void;
  setBadge: (num: string) => void;
}

export default function Calendar({ openDialog, filter, setBadge }: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const [unassignedTask, setUnassignedTask] = useState<any>([]);
  const [resource, setResource] = useState<ResourceCalendar[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<AssignTask>({} as AssignTask);
  const [actionType, setActionType] = useState<ActionType>("assign");
  const [eventToRevert, setEventToRevert] = useState<any>(null);

  const isMount = useMount();
  const calendarRef = useRef<FullCalendar>(null);

  const externalEventsRef = useRef<any>(null);

  // Handle drag and drop unassigned task
  useEffect(() => {
    if (externalEventsRef?.current) {
      const draggable = new Draggable(
        externalEventsRef?.current as unknown as HTMLElement,
        {
          itemSelector: ".fc-event",
          eventData: function (eventEl: any) {
            const dataEvent = eventEl.getAttribute("data-event");
            let parsedEvent;
            if (dataEvent) {
              parsedEvent = JSON.parse(dataEvent);
            }
            return {
              title: parsedEvent.title,
              id: parsedEvent.id,
              color: eventEl.style.backgroundColor,
              duration: { days: parsedEvent.duration.days || 3 },
            };
          },
        }
      );
      return () => {
        draggable.destroy();
      };
    }
  }, [openDialog]);

  const formatEvents = (
    events: EventCalendar[],
    company_leaves: CompanyLeavesCalendar[]
  ) => {
    if (!events?.length && !company_leaves?.length) {
      setEvents([
        {
          daysOfWeek: [0, 6],
          display: "background",
          color: "#fcf990",
        },
      ]);
      return;
    }

    // Define deadline for each task type to be displayed in red border as alert
    const taskDeadlineAlert = (taskId: string) => {
      const task = taskId.split("-")[0];
      switch (task) {
        case "M":
          return 3;
        case "MP":
          return 3;
        case "P":
          return 3;
        case "D":
          return 3;
        case "R":
          return 3;
        default:
          return 3;
      }
    };

    const formattedEvents =
      events &&
      events?.map((event: EventCalendar) => {
        const endDate = new Date(event?.end);

        const eta = Math.ceil(
          (endDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
        );

        let className =
          eta < taskDeadlineAlert(event?.taskId) ? "red-border" : "";

        if (event?.priority?.toLowerCase() === "high") {
          className = className + " high";
        }
        if(event?.status?.toLocaleLowerCase() === "done"){
            className = "green-border"
        }
        if(event?.status?.toLocaleLowerCase() === "revision"){
          className = "orange-border"
      }
        return {
          ...event,
          id: event?.taskId,
          color: hexToRgba(event?.color, 0.75),
          className: className,
        };
      });

    const formattedCompanyLeaves =
      company_leaves &&
      company_leaves?.map((leave: CompanyLeavesCalendar) => {
        return {
          ...leave,
          textColor: "black",
        };
      });

    setEvents([
      ...formattedEvents,
      ...formattedCompanyLeaves,
      {
        daysOfWeek: [0, 6],
        display: "background",
        color: "#fcf990",
      },
    ]);
  };

  const fetchTaskCalendar = async () => {
    const response = await GetTaskCalendar(filter);
    const adjustedEvents = adjustEventsEndDate(response?.data?.data);

    setResource(response?.data?.resource);
    formatEvents(adjustedEvents, response?.data?.company_leavse);
  };

  const adjustEventsEndDate = (events: any[]): any[] => {
    return events?.map(event => {
      const endDate = new Date(event.end);
      // Add one day to the end date
      endDate.setDate(endDate.getDate() + 1);
      // Convert back to 'YYYY-MM-DD' format
      const adjustedEndDate = endDate.toISOString().split('T')[0];
  
      return {
        ...event,
        end: adjustedEndDate,
      };
    });
  };

  const fetchUnassignedTask = async () => {
    const response = await GetUnassignedTask();
    setUnassignedTask(response?.data);
    setBadge(response?.data?.length.toString());
  };

  useEffect(() => {
    fetchTaskCalendar();
    fetchUnassignedTask();
  }, [filter]);

  const handleEventClick = (info: any) => {
    if (info.event.id === "") {
      return;
    }
    navigate(`/task/task-list/${info.event.id}`);
  };

  const handleEventReceive = (info: any) => {
    const data = {
      task_id: info.event.id,
      start_date: info.event.startStr,
      end_date: info.event.endStr,
      user_id: info.event._def.resourceIds[0],
    };

    setActionType("assign");
    setTaskData(data);
    setOpenModal(true);
    setEventToRevert(info);
  };

  const handleConfirmSubmit = async () => {
    await fetchUnassignedTask();
    await fetchTaskCalendar();
    setTaskData({} as AssignTask);
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setTaskData({} as AssignTask);
    setOpenModal(false);
    if (eventToRevert) {
      eventToRevert.revert();
    }

    setEventToRevert(null);
  };

  const handleEventDrop = (info: any) => {
    const main_day =
      (new Date(info.event.endStr).getTime() -
        new Date(info.event.startStr).getTime()) /
      (1000 * 3600 * 24);

    const data = {
      task_id: info.event.id,
      start_date: info.event.startStr.split("T")[0],
      end_date: info.event.endStr.split("T")[0],
      user_id: info.event._def.resourceIds[0],
      main_day: main_day,
    };

    setActionType("update");
    setTaskData(data);
    setOpenModal(true);
    setEventToRevert(info);
  };

  return (
    <Box display={"flex"} flexDirection={"row"} mt={2} gap={2}>
      <Box width={"100%"}>
        <style>{`
        .fc {
        background-color: ${theme?.bgPaper}; 
        border: 1px solid ${theme?.tableBorder};
        color: ${theme?.inputLabel};
        border-radius: 4px;
        padding: 0;
      }
      
      .fc-header h2 {
        color: white;
      }

      .fc-event:not(.fc-bg-event) {
        border-radius: 4px;
        margin: 4px;
      }
      
      .fc-daygrid-day-top {
        border-color: white;
        color: white;
      }

      .red-border{
        border: 3px solid red !important;
      }
      .green-border{
        border: 3px solid 	#6f9c3d !important;
      }  
      .orange-border{
        border: 3px solid 	#fe6b40 !important;
      }   

      
      `}</style>
        <FullCalendar
          ref={calendarRef}
          height="80vh"
          schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          // initialView="resourceTimelineYear"
          initialView="resourceTimelineMonth" // Show month view by default
          initialDate={new Date().toISOString().split("T")[0]} // Set today as default
          editable={true}
          eventResourceEditable={true}
          selectable={true}
          droppable={true}
          events={events}
          slotLabelFormat={[
            { month: "long", year: "numeric" }, // top level of text
            {
              weekday: "short",
              month: "numeric",
              day: "numeric",
              omitCommas: true,
            },
          ]}
          headerToolbar={{
            left: "today,prev,next",
            center: "title",
            right: "resourceTimelineYear,resourceTimelineMonth",
          }}
          weekends={true}
          resources={resource}
          resourceGroupField="role"
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          eventReceive={handleEventReceive}
          eventResize={handleEventDrop}
        />
      </Box>
      {openDialog && (
        <Slide in={openDialog} direction="left">
          <Paper
            id="external-events"
            ref={externalEventsRef}
            aria-labelledby="draggable-dialog-title"
            sx={{
              width: openDialog ? 300 : 0,
              padding: 2,
              background: theme?.inputPrimary,
            }}>
            <CustomText variant="body1" fontWeight={700} ml={1}>
              Unassigned Task
            </CustomText>
            <Stack mt={2}>
              {unassignedTask &&
                unassignedTask?.map((item: any) => (
                  // Render unassigned task
                  <Stack
                    key={item?.id}
                    className="fc-event"
                    style={{
                      padding: "2px 10px",
                      background: hexToRgba(item?.color, 0.75),
                      borderRadius: "4px",
                      color: "#fff",
                      cursor: "move",
                    }}
                    // Add data-event attribute to the event. Will be user for drag and drop to calendar
                    data-event={JSON.stringify({
                      title: item?.title,
                      id: item?.taskId,
                      duration: { days: item?.duration },
                    })}>
                    {`${item?.taskId} ${item?.title}`}
                  </Stack>
                ))}
            </Stack>
          </Paper>
        </Slide>
      )}

      <ConfirmAssignTask
        open={openModal}
        onSubmit={handleConfirmSubmit}
        onClose={handleCloseModal}
        action={actionType}
        submitData={taskData}
      />
    </Box>
  );
}

// Render event content to display in calendar
function renderEventContent(eventInfo: any) {
  return (
    <Box sx={{ borderRadius: 50, pr: 2 }}>
      {eventInfo.event.classNames.includes("high") && ( // Display red circle if event is high priority
        <CircleIcon sx={{ fontSize: 12, mx: 0.5 }} color="error" />
      )}
      <b>{`${eventInfo.event.id}`}</b>
    </Box>
  );
}
