import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import useThemeStore from "src/store/themeStore";

interface InputMonthPickerProps {
  id: string;
  name: string;
  disabled?: boolean;
  defaultValue?: string; // Format: "MM"
  value?: string; // Format: "MM"
  onChange?: (e: any) => void;
  calendarYear?: number; // Optional year to show in the calendar
}

export default function InputMonthPicker({
  id,
  name,
  disabled,
  defaultValue,
  value,
  onChange,
  calendarYear,
}: InputMonthPickerProps) {
  const { theme } = useThemeStore();

  const disableKeyboardEntry = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleChange = (date: moment.Moment | null) => {
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: date ? date.format("MM") : "", // Return only the month as MM
        },
      });
    }
  };

  const calendarDate = value
    ? moment(`${calendarYear || moment().year()}-${value}`, "YYYY-MM") // Combine year and month for display
    : defaultValue
    ? moment(`${calendarYear || moment().year()}-${defaultValue}`, "YYYY-MM")
    : calendarYear
    ? moment(`${calendarYear}-01`, "YYYY-MM")
    : moment();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <DatePicker
        views={["month"]} // Show only the month picker
        disabled={disabled}
        onChange={handleChange}
        value={calendarDate} // Set calendar date for display
        openTo="month" // Start at the month view
        sx={{
          "& .MuiOutlinedInput-root": {
            background: `linear-gradient(22.5deg, ${theme?.inputPrimary}, ${theme?.inputSecondary})`,
            "& fieldset": {
              borderColor: theme?.inputBorder,
            },
            "&:hover fieldset": {
              borderColor: theme?.inputBorder,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme?.inputBorder,
            },
            height: "40px",
          },
          "& .MuiInputBase-root": {
            color: theme?.inputText,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: theme?.inputTextDisabled,
            },
            "& .MuiSvgIcon-root": {
              color: theme?.icon,
            },
          },
        }}
        slotProps={{
          field: { clearable: false },
          textField: {
            size: "small",
            fullWidth: true,
            id: id,
            name: name,
            required: true,
            onBeforeInput: disableKeyboardEntry,
          },
        }}
      />
    </LocalizationProvider>
  );
}
