import { create } from "zustand";

type TStatus = "success" | "error";
interface LoadingStore {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
  isNotification: boolean;
  notificationMessage: string;
  notificationStatus: TStatus;
  showNotification: (msg: string, status: TStatus) => void;
  hideNotification: () => void;
}

// Create the layout store
export const useLoadingStore = create<LoadingStore>((set) => ({
  isLoading: false,
  showLoading: () => set({ isLoading: true }),
  hideLoading: () => set({ isLoading: false }),

  isNotification: false,
  notificationMessage: "",
  notificationStatus: "success",
  showNotification: (msg: string, status: TStatus) =>
    set({
      isNotification: true,
      notificationMessage: msg,
      notificationStatus: status,
    }),
  hideNotification: () =>
    set({
      isNotification: false,
      notificationMessage: "",
      notificationStatus: "success",
    }),
}));
