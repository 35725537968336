import { Box, Card, Grid, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { DecimalFormatter } from "src/utils/formatter";
import { getCurrencySymbol } from "src/utils/paramData";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ActionApproval from "./components/ActionApproval";
import Filter from "./components/Filter";
import CustomToggle from "src/components/main/CustomToggle";
import useThemeStore from "src/store/themeStore";
import ActionReimbursement from "./components/ActionReimbursement";
import PageTitle from "src/components/main/CustomText/PageTitle";
import { getDetailGroupingReimbursement, getReimbursementList } from "src/api/reimburse";
import { useFilterStore } from "src/store/filterStore";
import { REIMBURSEMENT_COLUMNS } from "src/constants/reimbursement";
import { AnyCnameRecord } from "dns";
import { useLoadingStore } from "src/store/loadingStore";

type TMode = "all" | "pending" | "unpaid";
export default function Reimbursement() {
   const { showNotification } = useLoadingStore();
  const { theme } = useThemeStore();
  const [mode, setMode] = useState<TMode>("all");
  const [initialData, setInitialData] = useState<Reimbursement[]>([]);
  const [data, setData] = useState<Reimbursement[]>([]);
  const [approvalList, setApprovalList] = useState<string[]>([]);
  const [openApproval, setOpenApproval] = useState<boolean>(false);
  const [openReimburse, setOpenReimburse] = useState<boolean>(false);
  const [checkedData, setCheckedData] = useState<Reimbursement[]>([]);
  const [doneAction, setDoneAction] = useState<string[]>([]);
  const [statusReimburseDetail,setStatusReimburseDetail] = useState<string>("");
  const [attachmentDetail,setattachmentDetail] = useState<string>("");

  const { user_id, status, resetFilterStore } = useFilterStore();


  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    user_id: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
    periode:"",
    periode_year:new Date().getFullYear().toString(),
  });


  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };


  const fetchData = async () => {
    const req: FilterReimbursement = {
      status: filter.status,
      user_id: user_id,
      order_by: filter.order_key,
      order_type: filter.sort_type,
      start_date: filter.start_date,
      end_date: filter.end_date,
      page: filter.page,
      limit: filter.limit,
      periode:filter.periode!,
      periode_year:filter.periode_year!
    };

    const response = await getReimbursementList(req);
    setInitialData(formatData(response?.data));
    setData(formatData(response?.data));
    handleFilterChange("total_page", response?.total_page);
  };

  const resetFilterLocal = () => {
    // setMode("all")
    // setFilter({
    //   status: [],
    //   user_id: [],
    //   key: "",
    //   order_key: "",
    //   sort_type: "",
    //   start_date: "",
    //   end_date: "",
    //   page: 1,
    //   limit: 10,
    //   total_page: 1,
    //   order_date_start: "",
    //   order_date_end: "",
    //   periode:"",
    //   periode_year:new Date().getFullYear().toString(),
    // })
    fetchData()
    setCheckedData([])
  }

  const dataReimburseGroup = async (req:any)=> {
     try {
          const response = await getDetailGroupingReimbursement(req,filter.periode_year!);
          response?.data?.data ? setCheckedData(response?.data?.data) : setCheckedData([]);
          return response?.data?.data
        } catch (error: any) {
          console.error("Error fetching item data:", error);
          return []
        }
  };

  useEffect(() => {
    fetchData();
  }, [
    filter.status,
    user_id,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.periode,
    filter.periode_year,
  ]);

  // useEffect(() => {
  //   return () => {
  //     resetFilterStore();
  //   };
  // }, []);

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const { checked } = event.target;

    if (checked) {
      setApprovalList((prev: any) => [...prev, id]);
    } else {
      setApprovalList((prev: any) =>
        prev.filter((item: string) => item !== id)
      );
    }
  };

  const formatData = (data: any[]) => {
    const processedData = data?.map((item: any, index: number) => {
      return {
        ...item,
        no: index,
        cost: `${getCurrencySymbol("IDR")} ${DecimalFormatter(
          item?.total_cost?.toString()
        )}`,
        claim_date: item?.disburse_date?.split("T")[0],
      };
    });

    return processedData;
  };

  const onClickDetail = async (id: string) => {
    const checkedDataDetail = initialData.find((checkedItem: any) => checkedItem.id_grouping === id);

    if (checkedDataDetail) {
      const  items: any[] = [];
    items.push(checkedDataDetail)
    setStatusReimburseDetail(checkedDataDetail.status)
    setattachmentDetail(checkedDataDetail.reimburse_attachment)

     const res = await dataReimburseGroup(items)
    if(res.length > 0){
      checkedDataDetail?.status === "Pending"
  ? setOpenApproval(true)

: setOpenReimburse(true)

    }
      // Perform actions with `checkedDataDetail`
    } else {
      showNotification("No matching data found.","error");
    }

    
  };

  const handleAction = async () => {
    const checkedDatas = initialData?.filter((item: any) =>
      approvalList.includes(item.id_grouping)
    );

     const res = await dataReimburseGroup(checkedDatas)
    if(res.length > 0){
      mode === "pending" ? setOpenApproval(true) : setOpenReimburse(true);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setApprovalList(data?.map((item: any) => item.reimburse_id));
    } else {
      setApprovalList([]);
    }
  };

  const handleChangeMode = (
    event: React.MouseEvent<HTMLElement>,
    mode: TMode
  ) => {
    if (mode === null) {
      return;
    }
    setMode(mode);
    if (mode === "pending") {
      handleFilterChange("status", ["Pending", "Rejected"]);
    } else if (mode === "unpaid") {
      handleFilterChange("status", ["Approved"]);
    } else {
      handleFilterChange("status", []);
    }
    setApprovalList([]);
  };

  const handleClose = () => {
    setOpenApproval(false);
    setOpenReimburse(false);
    setDoneAction([]);
    resetFilterLocal();
  };

  useEffect(() => {
    // Apply filter status from selected item in dashboard
    handleFilterChange("status", [...status]);
    return () => {
      resetFilterStore();
    };
  }, []);  


  return (
    <Box>
      <PageTitle>Reimbursement</PageTitle>
      <Grid container columnSpacing={2}>
        <Grid item sm={2}>
          <Filter
            mode={mode}
            filter={filter}
            onChangeFilter={handleFilterChange}
          />
        </Grid>
        <Grid item sm={10}>
          <Card>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}>
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Reimbursement List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}>
                {/* <ToggleButtonGroup
                  color="primary"
                  value={mode}
                  exclusive
                  onChange={handleChangeMode}
                  aria-label="Platform"
                  sx={{ backgroundColor: theme?.inputPrimary }}>
                  <CustomToggle label="all" value="all" />
                  <CustomToggle label="pending" value="pending" />
                  <CustomToggle label="unpaid" value="unpaid" />
                </ToggleButtonGroup>
                <CustomButton
                  disabled={
                    !approvalList ||
                    approvalList?.length === 0 ||
                    mode === "all"
                  }
                  variant="contained"
                  onClick={() => handleAction()}
                  startIcon={<TaskOutlinedIcon />}>
                  Action
                </CustomButton> */}
              </Box>
            </Box>
            <CustomTable
              columns={REIMBURSEMENT_COLUMNS}
              data={data}
              checkbox={mode !== "all"}
              selectedItem={approvalList}
              rowCount={data?.length}
              onSelectAll={handleSelectAll}
              onCheckboxChange={handleClick}
              identifier="id_grouping"
              sortable={false}
              filter={filter}
              onChangeFilter={handleFilterChange}
              onClickDetail={onClickDetail}
            />
          </Card>
        </Grid>
      </Grid>

      <ActionApproval
        isOpen={openApproval}
        onClose={handleClose}
        data={checkedData}
        doneAction={doneAction}
        onDoneAction={setDoneAction}
        refetch={fetchData}
        fetchData={fetchData}
      />

      <ActionReimbursement
        isOpen={openReimburse}
        onClose={handleClose}
        data={checkedData}
        refetch={fetchData}
        fetchData={fetchData}
        attachmentDetai={attachmentDetail}
        statusReimburse={statusReimburseDetail}

      />
    </Box>
  );
}
