import { Box, Tabs, Tab, Divider } from "@mui/material";
import React, { useEffect } from "react";
import CustomTabPanel from "src/components/main/CustomTabPanel";
import BreadCrumbs from "src/components/navigators/BreadCrumb";
import InsentiveDetail from "./components/InsentiveDetail";
import InsentiveFinishedTask from "./components/InsentiveFinishedTask";
import CustomText from "src/components/main/CustomText";
import CustomTabs, { CustomTab } from "src/components/main/CustomTabs";
import PageTitle from "src/components/main/CustomText/PageTitle";
import { getFinanceIncentiveDetail } from "src/api/finance";
import { useFinanceStore } from "src/store/financeStore";
import { useNavigate } from "react-router-dom";
import { DecimalFormatter } from "src/utils/formatter";

export default function FinanceInsentiveDetail() {
  const [value, setValue] = React.useState(0);
  const [insentiveData, setInsentiveData] = React.useState<any>({});
  const [insentiveDetail, setInsentiveDetail] = React.useState<any>({});

  const navigate = useNavigate();
  const { id_grouping } = useFinanceStore();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    const response = await getFinanceIncentiveDetail(id_grouping);

    if (response?.status === 200) {
      setInsentiveData(response?.data?.data?.InsetiveData);
      setInsentiveDetail(
        formatIncentiveDetail(response?.data?.data?.InsentiveData)
      );
      return;
    }

    navigate("/finance/incentive");
  };

  const formatIncentiveDetail = (data: any) => {
    return data?.map((item: any) => ({
      ...item,
      startDate: item?.startDate ? item.startDate.split("T")[0] : "", // Handle empty or null startDate
      endDate: item?.endDate ? item.endDate.split("T")[0] : "",       // Handle empty or null endDate
      amount: `Rp ${DecimalFormatter(item?.cost?.toString() || "0")}`, // Handle empty cost gracefully
    }));
  };
  
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <PageTitle>Monthly Insentive Detail</PageTitle>
      <Divider />
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Insentive Detail" />
        <CustomTab label="Insentive Finished Task" />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
        <InsentiveDetail data={insentiveData} fetchNewData={fetchData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <InsentiveFinishedTask
          data={insentiveDetail}
          totalAmount={insentiveData?.total_cost}
          fetchNewData={fetchData}
          dataIncentive={insentiveData}
        />
      </CustomTabPanel>
    </Box>
  );
}
