import * as React from "react";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "src/store/userStore";
import { DoLogin } from "src/api/login";
import NotificationAlert from "src/components/main/Alert";
import { HideLoading, ShowLoading } from "src/components/main/Loading";
import CustomButton from "src/components/main/CustomButton";
import CustomCheckbox from "src/components/main/CustomCheckbox";
import CustomInput from "src/components/main/CustomInput";
import useThemeStore from "src/store/themeStore";
import CustomText from "src/components/main/CustomText";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, InputAdornment, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { getRoleUser } from "src/api/user";
import { useParameterStore } from "src/store/parameterStore";
import { GetTaskType } from "src/api/task";
import { debug, log } from "console";

export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    role,
    token,
    isAuthenticated,
    setUsername,
    setDisplayName,
    setRole,
    setToken,
    setMenuMapping,
    setIsAuthenticated,
    setWebUser,
    menu_mapping,
    webUser,
  } = useUserStore();
  const { setRoleList, setTaskType } = useParameterStore();
  const { theme } = useThemeStore();
  const [imageUrl, setImageUrl] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const req = {
      username: data.get("username")?.toString().trim(),
      password: data.get("password")?.toString().trim(),
    };

    try {
      ShowLoading({});
      const res = await DoLogin(req);

      if (res?.data?.data?.role !== '6bd943b3-2865-4382-a18b-8fd54de46cd2' && !res?.data?.data?.web_user){
        NotificationAlert({ message: "Your User Role is Not A Web User", status: "error" });

      }else{
        setUsername(res?.data?.data?.username);
        setDisplayName(res?.data?.data?.display_name);
        setRole(res?.data?.data?.role);
        setWebUser(res?.data?.data?.web_user)
        setToken(res?.data?.data?.token);
        setMenuMapping(res?.data?.data?.menu_mapping);
        setIsAuthenticated(true);
        NotificationAlert({ message: res?.data?.message, status: "success" });
      }
        
        HideLoading();
    } catch (error: any) {
      HideLoading();
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  const fetchParam = async () => {
    const roleList = await getRoleUser();
    setRoleList(roleList?.data?.data);

    const taskPoint = await GetTaskType();
    setTaskType(taskPoint?.data);
  };

  const handleChange = (event: any) => {
    const trimmedValue = event.target.value.trim();
    event.target.value = trimmedValue;
  };

  useEffect(() => {
    if (token && isAuthenticated) {
      fetchParam(); // Fetch any necessary data
        let targetMenu = "/"
        for (let index = 0; index < menu_mapping?.length; index++) {
          if (!menu_mapping[index]?.hasSubmenu){
            targetMenu = menu_mapping[index]?.menu
            break;
          }
        }
        // window.location.href = targetMenu
        navigate(targetMenu)
      
      
    }
  }, [isAuthenticated, token]);
  
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          "https://api.unsplash.com/photos/random?client_id=IQMuww4PMwZOpTaBPWO45hDaDrwvS7KB6ofRemH1Pmw"
        );
        const data = await response.json();
        setImageUrl(data.urls.regular);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, []);

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", backgroundColor: theme?.bgPrimary }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          background: `url(${imageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        bgcolor={"inherit"}
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square>
        <Box
          sx={{
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "grey" }} />
          <CustomText color={"white"} component="h1" variant="h5">
            Sign in
          </CustomText>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            width={"100%"}>
            <Stack>
              <CustomText variant="body1" gutterBottom={false}>
                Username/Email
              </CustomText>
              <CustomInput
                required
                fullWidth
                id="username"
                name="username"
                autoFocus
                onChange={(event) => handleChange(event)}
              />
            </Stack>

            <Stack mt={2}>
              <CustomText variant="body1">Password</CustomText>
              <CustomInput
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                onChange={(event) => handleChange(event)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        sx={{ color: theme?.buttonText }}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <FormControlLabel
              control={<CustomCheckbox value="remember" color="primary" />}
              label="Remember me"
              style={{
                color: "white",
              }}
            />
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="primary">
              Sign In
            </CustomButton>
            <CustomText variant="body2" color="text.secondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="https://ursawhite.com/">
                Ursawhite
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </CustomText>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
