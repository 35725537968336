import {
  Box,
  Stack,
  Autocomplete,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  MenuItem,
  Grid,
} from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import CustomInput from "../CustomInput";
import CustomText from "../CustomText";
import { useEffect, useState } from "react";
import useThemeStore from "src/store/themeStore";
import { SearchMarketing } from "src/api/user";
import { useDebouncedCallback } from "use-debounce";
import CustomButton from "../CustomButton";
import { useLoadingStore } from "src/store/loadingStore";
import { getCurrentTime } from "src/utils/formatter";
import { checkWorkingDay, CreateTaskWorker } from "src/api/item";
import { useLocation } from "react-router-dom";
import { priorityLevel } from "src/utils/paramData";
import ModalConfirm from "../ModalConfirm";
import InputDate from "../Datepicker";
import CustomPaper from "../CustomPaper";
import NotificationAlert from "../Alert";

interface Props {
  step?: string;
  refetch: () => void;
}
export default function PostMarketing({ step, refetch }: Props) {
  const { theme } = useThemeStore();
  const { showNotification } = useLoadingStore();
  const id = useLocation().pathname.split("/").pop();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [data, setData] = useState<any>({});
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [workDay, setWorkDay] = useState<any>({});

  const fetchSearchAssignee = async (key: string) => {
    if (key === "" || key === null || key === undefined) {
      return;
    }

    setIsLoading(true);
    const response = await SearchMarketing();
    response?.data ? setAssigneeList(response?.data) : setAssigneeList([]);
    await setIsLoading(false);
  };

  const debouncedFetch = useDebouncedCallback(fetchSearchAssignee, 1000);

  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue);
  };

  const handleSelect = (event: any, newValue: any | null) => {
    setSelectedUser(newValue);
    setData((prevData: any) => ({ ...prevData, ["assignee"]: newValue?.id }));
  };

  const filterOptions = (options: any[], { inputValue }: any) => {
    return options.filter((option: { username: string }) =>
      option.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleCheckWorkingDay = async (data: any) => {
    const response = await checkWorkingDay(data);
    setWorkDay(response?.data);
  };

  useEffect(() => {
    if (selectedUser?.id) {
      const req = {
        user_id: selectedUser?.id,
        man_days: 3,
      };
      handleCheckWorkingDay(req);
    }
  }, [selectedUser?.id]);

  const handleSubmit = async () => {
    const form = document.querySelector("form");
    if (!form) return;

    const data = new FormData(form);

    const req: CreateTask = {
      user_id: selectedUser?.id,
      item_id: id!,
      priority: data?.get("priority")?.toString() || priorityLevel[0].value,
      type_task: "MARKETING",
      main_day: 3,
      cost: 0,
    };

    if (Number(req?.main_day) <= 0) {
      return NotificationAlert({
        message: "Main days must be greater than 0.",
        status: "error",
      });
    }

    await CreateTaskWorker(req);
    await refetch();
    setOpenModalCreate(false);
  };

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalCreate(true);
  };

  return (
    <CustomPaper sx={{ width: "50%", p: 2 }}>
      <Box component={"form"} onSubmit={handleFormSubmit}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}>
          <CustomText variant="body1" fontWeight={"bold"}>
            {`Create Task Marketing ${
              step === "TASK-DECON" ? "(Decon Recon)" : "Post-Production"
            }`}
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Grid container columnSpacing={2} rowSpacing={2} mt={2}>
          <Grid item sm={4}>
            <Stack>
              <CustomText variant="body1">Priority</CustomText>
              <CustomInput
                fullWidth
                size="small"
                id="priority"
                name="priority"
                defaultValue={priorityLevel[0].value}
                select>
                {priorityLevel.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
          </Grid>
          <Grid item sm={8}>
            <Stack>
              <CustomText variant="body1">Assign To</CustomText>
              <Autocomplete
                options={assigneeList}
                noOptionsText="Worker Not Found"
                fullWidth
                autoComplete
                getOptionLabel={(option: { username: string; email: string }) =>
                  option?.username
                }
                isOptionEqualToValue={(option, value) =>
                  option.username === value.username
                }
                filterOptions={filterOptions}
                loading={isLoading}
                open={open}
                value={selectedUser}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={handleSelect}
                onInputChange={(_, newInputValue) =>
                  handleInputChange(newInputValue)
                }
                id="assignee"
                componentName="assignee"
                renderInput={(params) => (
                  <CustomInput
                    {...params}
                    id="assignee"
                    name="assignee"
                    placeholder="Search Assignee"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component={"li"}
                    {...props}
                    sx={{
                      backgroundColor: theme?.bgSecondary,
                      color: theme?.inputText,
                      "&.MuiAutocomplete-option.Mui-focused": {
                        backgroundColor: theme?.bgPrimary,
                      },
                    }}>
                    <List sx={{ width: "100%" }}>
                      <ListItem>
                        <ListItemText
                          primary={<CustomText>{option?.username}</CustomText>}
                          secondary={
                            <Fragment>
                              <CustomText>{option?.email}</CustomText>
                            </Fragment>
                          }
                        />
                      </ListItem>
                      <Divider sx={{ borderBottomWidth: 4 }} />
                    </List>
                  </Box>
                )}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack>
              <CustomText variant="body1">Start Date</CustomText>
              <InputDate
                disabled
                id="start_date"
                name="start_date"
                // onChange={handleChange}
                value={workDay?.ActualStartDate}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack>
              <CustomText variant="body1">End Date</CustomText>
              <InputDate
                disabled
                id="end_date"
                name="end_date"
                // onChange={handleChange}
                value={workDay?.EndDate}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack>
              <CustomText variant="body1">Man Days</CustomText>
              <CustomInput
                required
                disabled
                size="small"
                id="main_day"
                name="main_day"
                value={3}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
        </Box>
        <ModalConfirm
          text="Are you sure you want to create task?"
          open={openModalCreate}
          onClose={() => setOpenModalCreate(false)}
          onSubmit={handleSubmit}
        />
      </Box>
    </CustomPaper>
  );
}
