import { Modal, Stack } from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { assignTask, updateTask } from "src/api/task";
import CustomInput from "src/components/main/CustomInput";

interface Props {
  open: boolean;
  submitData: UpdateTaskDate | AssignTask;
  action: ActionType;
  onSubmit: () => void;
  onClose: () => void;
}
export default function ConfirmAssignTask(props: Props) {
  const { open, submitData, action, onSubmit, onClose } = props;

  const handleSubmitAssign = async () => {
    await assignTask(submitData as AssignTask);
    onSubmit();
  };

  const handleSubmitUpdate = async () => {
    await updateTask(submitData as UpdateTaskDate);
    onSubmit();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "auto",
          boxShadow: 24,
          px: 5,
          py: 2,
        }}>
        <Stack alignItems="start" direction="row" gap={2}>
          <CheckCircleIcon color="success" fontSize="large" />
          <CustomText variant="h6" gutterBottom>
            {action === "assign"
              ? "Do you want to assign this task?"
              : "Do you want to update this task?"}
          </CustomText>
        </Stack>

        {action === "update" && (
          <Stack mt={2}>
            <CustomText variant="body1" gutterBottom>
              Notes
            </CustomText>
            <CustomInput size="small" id="notes" name="notes" />
          </Stack>
        )}

        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} mt={2}>
          <CustomButton
            variant="outlined"
            customType="cancel"
            onClick={onClose}>
            Close
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={
              action === "assign" ? handleSubmitAssign : handleSubmitUpdate
            }>
            Submit
          </CustomButton>
        </Stack>
      </CustomPaper>
    </Modal>
  );
}
