import { Autocomplete, Box, CircularProgress, Divider, List, ListItem, ListItemText } from "@mui/material";
import { Fragment, useState } from "react";
import { SearchSupplier } from "src/api/user";
import { useDebouncedCallback } from "use-debounce";
import CustomInput from "../../CustomInput";
import CustomText from "../../CustomText";
import useThemeStore from "src/store/themeStore";
import { useFilterStore } from "src/store/filterStore";

export default function SupplierAutocomplete(props: any) {
  const { ...otherProps } = props ?? {};
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const { setUserId } = useFilterStore();

  const { theme } = useThemeStore();

  const fetchSearchAssignee = async (key: string) => {
    if (key === "" || key === null || key === undefined) {
      return;
    }

    setIsLoading(true);
    const response = await SearchSupplier();
    response?.data ? setAssigneeList(response?.data) : setAssigneeList([]);
    setIsLoading(false); // Reset loading state after API call
  };

  const debouncedFetch = useDebouncedCallback(fetchSearchAssignee, 1000);

  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue);
  };

  const handleSelect = (event: any, newValue: any | null) => {
    setSelectedUser(newValue);
    setUserId(newValue?.id ? [newValue?.id] : []);
  };

  const filterOptions = (options: any[], { inputValue }: any) => {
    return options.filter((option: { username: string }) =>
      option.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Autocomplete
      {...otherProps}
      options={assigneeList}
      noOptionsText="Type Supplier Name..."
      fullWidth
      autoComplete
      getOptionLabel={(option: { username: string; email: string }) =>
        option?.username
      }
      isOptionEqualToValue={(option: any, value: any) =>
        option.username === value.username
      }
      filterOptions={filterOptions}
      loading={isLoading}
      open={open}
      value={selectedUser}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleSelect}
      onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
      id="assignee"
      componentName="assignee"
      renderInput={(params) => (
        <CustomInput
          {...params}
          id="assignee"
          name="assignee"
          placeholder="Search Supplier"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option: any) => (
        <Box
          component={"li"}
          {...props}
          sx={{
            backgroundColor: theme?.bgSecondary,
            color: theme?.inputText,
            "&.MuiAutocomplete-option.Mui-focused": {
              backgroundColor: theme?.bgPrimary,
            },
          }}
        >
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText
                primary={<CustomText>{option?.username}</CustomText>}
                secondary={
                  <Fragment>
                    <CustomText>{option?.email}</CustomText>
                  </Fragment>
                }
              />
            </ListItem>
            <Divider sx={{ borderBottomWidth: 4 }} />
          </List>
        </Box>
      )}
    />
  );
}
