import { Box, InputAdornment, MenuItem, Modal, Stack } from "@mui/material";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import CircleIcon from "@mui/icons-material/Circle";
import CustomDivider from "src/components/main/CustomDivider";
import useThemeStore from "src/store/themeStore";
import DifferenceIcon from "@mui/icons-material/Difference";
import CustomInput from "src/components/main/CustomInput";
import InputDate from "src/components/main/Datepicker";
import NewInputNominal from "src/components/main/NewInputNominal";
import { currencies, priorityLevel } from "src/utils/paramData";
import { useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import { useLoadingStore } from "src/store/loadingStore";
import { groupTask } from "src/api/task";
import ModalConfirm from "src/components/main/ModalConfirm";

interface Props {
  open: boolean;
  taskList: string[];
  userId: string;
  onClose: () => void;
  refetch: () => void;
}

export default function ModalGroupTask(props: Props) {
  const { open, onClose, userId, taskList, refetch } = props;
  const { theme } = useThemeStore();
  const { showNotification } = useLoadingStore();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [data, setData] = useState<GroupTask>({
    priority: priorityLevel[0].value,
  } as GroupTask);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validDate = new Date(data?.start_date || 0).getTime() <= new Date(data?.end_date || 0).getTime();

    if (!data?.start_date || !data?.end_date || !validDate) {
      showNotification("Invalid Start Date or End Date", "error");
      return;
    }

    setOpenModal(true);
  };

  const handleSubmit = async () => {
    const req: GroupTask = {
      ...data,
      list_task_id: taskList,
      user_id: userId,
      // cost:cost
    };

    const response = await groupTask(req);
    if (response?.status === 200) {
      await refetch();
      handleClose();
    }
  };

  const handleClose = () => {
    setData({} as GroupTask);
    setOpenModal(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "auto",
          boxShadow: 24,
          px: 5,
          py: 2,
        }}
      >
        <Box
          sx={{ width: "100%", flexGrow: 1 }}
          component={"form"}
          onSubmit={handleSubmitForm}
        >
          <Box>
            <Stack direction={"row"} gap={2}>
              <DifferenceIcon style={{ color: theme?.icon }} fontSize="large" />
              <CustomText variant="h6" gutterBottom>
                Group Task
              </CustomText>
            </Stack>
            <CustomDivider sx={{ mt: 1 }} />
            <Box sx={{ pt: 2 }}>
              <Stack mb={2}>
                <CustomText variant="body1" gutterBottom={true}>
                  Tasks to Group
                </CustomText>
                <Box display={"flex"} gap={1} flexWrap="wrap">
                  {taskList?.map((item: any, index: number) => (
                    <Stack
                      key={index}
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                      sx={{ flex: "1 1 40%" }}
                    >
                      <CircleIcon
                        sx={{ fontSize: 10, mx: 0.5, color: theme?.inputLabel }}
                      />
                      <CustomText variant="subtitle1">{item}</CustomText>
                    </Stack>
                  ))}
                </Box>
              </Stack>
            </Box>
            <Box
              display={"flex"}
              flexWrap="wrap"
              justifyContent={"flex-start"}
              gap={2}
            >
              <Stack sx={{ flex: "1 1 40%" }}>
                <CustomText variant="body1">Priority</CustomText>
                <CustomInput
                  fullWidth
                  required
                  size="small"
                  id="priority"
                  name="priority"
                  value={data?.priority || priorityLevel[0].value}
                  select
                  onChange={handleChange}
                >
                  {priorityLevel.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomInput>
              </Stack>
              <Stack flex={"1 1 40%"}>
                <CustomText variant="body1">Cost</CustomText>
                <NewInputNominal
                  required
                  size="small"
                  id="cost_string"
                  name="cost_string"
                  onChange={handleChange}
                  value={data?.cost_string?.toString() || "0"}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack flex={"1 1 40%"}>
                <CustomText variant="body1">Start Date</CustomText>
                <InputDate
                  id="start_date"
                  name="start_date"
                  value={data?.start_date}
                  onChange={handleChange}
                />
              </Stack>
              <Stack flex={"1 1 40%"}>
                <CustomText variant="body1">End Date</CustomText>
                <InputDate
                  id="end_date"
                  name="end_date"
                  minDate={data?.start_date}
                  value={data?.end_date}
                  onChange={handleChange}
                />
              </Stack>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
            <CustomButton variant="contained" type="submit">
              Submit
            </CustomButton>
          </Box>
        </Box>

        <ModalConfirm
          text="Are you sure you want to group this task?"
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSubmit={handleSubmit}
        />
      </CustomPaper>
    </Modal>
  );
}
