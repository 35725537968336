import { Box, Card, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { FINANCE_INCENTIVE_DETAIL_PERIOD_COLUMNS } from "src/constants/finance";
import { DecimalFormatter } from "src/utils/formatter";
import ModalIncentiveWeb from "../../components/ModalAddBonusIncentive";
import CustomButton from "src/components/main/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import useThemeStore from "src/store/themeStore";

function calculateTotalPage(totalItems: number, itemsPerPage: number): number {
  return Math.ceil(totalItems / itemsPerPage);
}
interface Props {
  data: any;
  totalAmount: number;
  fetchNewData:() => void;
  dataIncentive:any;
}
export default function incentiveFinishedTask({ data, totalAmount,fetchNewData ,dataIncentive}: Props) {
  const [openDisbursment, setOpenDisbursment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataDetail, setDataDetail] = useState<any>([]);
  const { theme } = useThemeStore();
  useEffect(() => {
  }, [data,totalAmount]);

  useEffect(() => {
    if (data) {
      setIsLoading(false); // Set loading to false once data is available
    }
  }, [data]);

  const fetchDataChange = () => {
    fetchNewData();

    setFilter((prevFilter) => ({
      ...prevFilter,
      ["total_page"]: calculateTotalPage(data?.length,filter.limit),
    })); 
    setFilter((prevFilter) => ({
      ...prevFilter,
      ["page"]: 1,
    })); 

  };
  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: calculateTotalPage(data?.length,10),
    order_date_start: "",
    order_date_end: "",
  });
  const [records, setRecords] = useState(data?.slice(0, filter.limit)); 

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ["total_page"]: calculateTotalPage(data?.length,filter.limit),
    }));    
    if (calculateTotalPage(data?.length,filter.limit) < filter.page  ){
      filter.page = 1
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      })); 
    }
    const from = (filter.page - 1) * filter.limit;
    const to = from + filter.limit;
    setRecords(data?.slice(from, to));

  }, [filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.order_date_start,
    filter.order_date_end,data]);
  const handleFilterChange = (name: string, value: any) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  if (isLoading) {
    return <p>Loading...</p>; // Display loading message until data is ready
  }


  const handleActionNewIncentive = () => {
 
    // setDataDetail(dataIncentive);
    setOpenDisbursment(true);
};

const onClose = () => {
  setOpenDisbursment(false);
};

  return (
    
    <Box>
      <ModalIncentiveWeb
        isOpen={openDisbursment}
        onClose={onClose}
        data={dataIncentive}
        refetch={fetchDataChange}
        onDetail={true}
      />
        <Card>
        <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Incentive List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
              
                { dataIncentive?.status === "Pending" && (
                <CustomButton
                  variant="contained"
                  onClick={handleActionNewIncentive}
                  startIcon={<AddIcon />
                  }
                >
                  Add Incentive
                </CustomButton>
            )}
              </Box>
            </Box>
        </Card>
      <CustomTable
        columns={FINANCE_INCENTIVE_DETAIL_PERIOD_COLUMNS}
        data={records}
        //data={data?.expense_list}
        onChangeFilter={handleFilterChange}
        filter={filter}
        sortable={false}
      />
      <Stack
        mt={4}
        direction={"row"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Stack width={"30%"}>
          <Stack
            display={"flex"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <CustomText variant="h6" fontWeight={"bold"}>Total Amounts</CustomText>
            <CustomText textAlign={"right"} variant="h6" fontWeight={"bold"}>
              {`Rp ${DecimalFormatter(totalAmount.toString())}`}
            </CustomText>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
