import { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomText from "src/components/main/CustomText";
import CustomerRow from "src/components/main/CustomerForms/row";
import CustomInput from "src/components/main/CustomInput";
import useThemeStore from "src/store/themeStore";
import { SearchCustomer } from "src/api/customer";
import NotificationAlert from "src/components/main/Alert";
import { useDebouncedCallback } from "use-debounce";
import { useOrderStore } from "src/store/orderStore";
import CustomCard from "src/components/main/CustomCard";

export default function Customer() {
  const [customerList, setCustomerList] = useState<any>([]);
  const [value, setValue] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { theme } = useThemeStore();
  const { customer, setCustomer } = useOrderStore();

  useEffect(() => {
    if (customer?.id) {
      setValue(customer);
    }
  }, []);

  const fetchSearchCustomer = async (key: string) => {
    if (
      key === "" ||
      key === null ||
      key === undefined ||
      key === value?.email
    ) {
      return;
    }

    setIsLoading(true); // Set loading state before API call
    try {
      const response = await SearchCustomer({ key: key });
      setCustomerList(response?.data?.Data ?? []);
    } catch (error: any) {
      console.error("Error fetching customer search:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      setIsLoading(false); // Reset loading state after API call
    }
  };

  const debouncedFetch = useDebouncedCallback(fetchSearchCustomer, 1000);

  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue); // Trigger debounced fetch
  };

  const handleSelect = (event: any, newValue: any | null) => {
    setCustomer(newValue);
    setValue(newValue);
  };

  const handleCustomerData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "phone_number") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }

    setCustomer({
      ...customer,
      [name]: newValue,
    });
  };

  return (
    <CustomCard text="Customer Data">
      <Box
        display={"flex"}
        sx={{
          flexDirection: "column",
          justifyContent: "normal",
          alignItems: "flex-start",
          gap: {
            xs: 0,
            md: 1,
            lg: 2,
          },
        }}>
        <Box
          display={"flex"}
          width={"100%"}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "normal",
            alignItems: "center",
            gap: {
              xs: 1,
              md: 2,
              lg: 3,
            },
          }}>
          <Stack width={"30%"}>
            <Autocomplete
              options={customerList}
              noOptionsText="Input Customer Name ..."
              getOptionLabel={(option: {
                name: string;
                phone_number: string;
                email: string;
              }) => option?.email}
              isOptionEqualToValue={(option, value) =>
                option?.email === value?.email
              }
              filterOptions={(x) => x}
              loading={isLoading}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              autoComplete
              value={value}
              onChange={handleSelect}
              onInputChange={(_, newInputValue) =>
                handleInputChange(newInputValue)
              }
              fullWidth
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  id="searchCustomer"
                  name="searchCustomer"
                  placeholder="Search Existing Customer"
                  size="small"
                  sx={{
                    backgroundColor: theme?.inputPrimary,
                    color: theme?.inputPrimary,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: theme?.inputText }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component={"li"}
                  {...props}
                  sx={{
                    backgroundColor: theme?.bgSecondary,
                    color: theme?.inputText,
                    "&.MuiAutocomplete-option.Mui-focused": {
                      backgroundColor: theme?.bgPrimary,
                    },
                  }}>
                  <List sx={{ width: "100%" }}>
                    <ListItem>
                      <ListItemText
                        primary={option?.name}
                        secondary={
                          <Fragment>
                            <CustomText variant="body1">
                              {option?.email}
                            </CustomText>
                            <CustomText variant="body1">
                              {option?.phone_number}
                            </CustomText>
                          </Fragment>
                        }
                      />
                    </ListItem>
                    <Divider sx={{ borderBottomWidth: 4 }} />
                  </List>
                </Box>
              )}
            />
          </Stack>
        </Box>
        <CustomerRow value={customer} onChange={handleCustomerData} />
      </Box>
    </CustomCard>
  );
}
