import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, Grid, ToggleButtonGroup } from "@mui/material";
import CustomTable from "../../../components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { InquiryTaskList } from "src/api/task";
import { useFilterStore } from "src/store/filterStore";
import { getDayLeft } from "src/utils/formatter";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomButton from "src/components/main/CustomButton";
import useThemeStore from "src/store/themeStore";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Filter from "./components/Filter";
import { TASK_LIST_COLUMNS } from "src/constants/task";
import ModalGroupTask from "./components/Group";
import { useLoadingStore } from "src/store/loadingStore";
import CustomToggle from "src/components/main/CustomToggle";

type TMode = "all" | "group";
function TaskList() {
  const { theme } = useThemeStore();
  const navigate = useNavigate();
  const { showNotification } = useLoadingStore();

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });
  const [data, setData] = useState<any>([]);
  const [taskList, setTaskList] = useState<string[]>([]);
  const [openModalGroup, setOpenModalGroup] = useState<boolean>(false);
  const [mode, setMode] = useState<TMode>("all");

  const { resetFilterStore } = useFilterStore();

  const onClickDetail = (id: string) => {
    navigate(`/task/task-list/${id}`);
  };

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  useEffect(() => {
    return () => {
      resetFilterStore();
    };
  }, []);

  // Handle whether if component is rendered first time or after filter changed
  useEffect(() => {
    setTaskList([]);
    fetchData();
  }, [
    filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
  ]);

  const fetchData = async () => {
    try {
      const response = await InquiryTaskList({
        status: filter.status,
        id: filter.key,
        order_key: filter.order_key,
        sort_type: filter.sort_type,
        start_date: filter.start_date,
        end_date: filter.end_date,
        page: filter.page,
        limit: filter.limit,
      });
      response?.data?.list ? setData([...response.data.list]) : setData([]);
      handleFilterChange("total_page", response?.data?.totalPage);
      response?.data?.totalPage < filter.page && handleFilterChange("page", 1);
    } catch (error: any) {
      console.error("Error fetching task list:", error);
    }
  };

  const processedData = data?.map((item: any) => {
    const dayLeft = getDayLeft(item?.end_date);
     const statusItem = item?.status
    return {
      ...item,
      end_date: item?.end_date?.split("T")[0],
      deadline:
        statusItem === "Done" || statusItem ==="Revision" ? "-":
        dayLeft > 0
          ? `${dayLeft} days left`
          : dayLeft === 0
          ? "Due Today"
          : `${Math.abs(dayLeft)} days overdue`,
    };
  });

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setTaskList(data?.map((item: any) => item.id));
    } else {
      setTaskList([]);
    }
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const { checked } = event.target;

    if (checked) {
      setTaskList((prev: any) => [...prev, id]);
    } else {
      setTaskList((prev: any) => prev.filter((item: string) => item !== id));
    }
  };

  const checkSameUserId = (data: any[], taskList: string[]) => {
    // Get the user_id of the first id in selected tasks from data task
    const firstUserId = data.find((item) => item.id === taskList[0])?.user_id;
    if (!firstUserId) return false; // If no user_id is found for the first ID

    // Check if all ids in selected tasks have the same user_id
    return taskList.every((id) => {
      const foundItem = data.find((item) => item.id === id);
      return foundItem?.user_id === firstUserId;
    });
  };

  const checkStatusNotDoneorRevision = (data: any[], taskList: string[]) => {
    // Get the user_id of the first id in selected tasks from data task
    const firstUserId = data.find((item) => item.id === taskList[0])?.user_id;
    if (!firstUserId) return false; // If no user_id is found for the first ID

    // Check if all ids in selected tasks have the same user_id
    return taskList.every((id) => {
      const foundItem = data.find((item) => item.id === id);
      return foundItem?.user_id === firstUserId;
    });
  };

  const handleGroup = () => {
    if (!checkSameUserId(data, taskList)) {
      showNotification("Please select tasks from the same user", "error");
      return;
    }

    setOpenModalGroup(true);
  };

  const handleChangeMode = (
    event: React.MouseEvent<HTMLElement>,
    mode: TMode
  ) => {
    if (mode === null) {
      return;
    }
    setMode(mode);
    if (mode === "group") {
      handleFilterChange("status", ["Task In Progress"]);
    } else {
      handleFilterChange("status", []);
    }
    setTaskList([]);
  };
  return (
    <Box>
      <PageTitle>Task List</PageTitle>
      <Grid container columnSpacing={2}>
        <Grid item sm={2}>
          <Filter
            mode={mode}
            filter={filter}
            onChangeFilter={handleFilterChange}
          />
        </Grid>
        <Grid item sm={10}>
          <Card>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}>
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Task List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}>
                <ToggleButtonGroup
                  color="primary"
                  value={mode}
                  exclusive
                  onChange={handleChangeMode}
                  aria-label="Platform"
                  sx={{ backgroundColor: theme?.inputPrimary }}>
                  <CustomToggle label="all" value="all" />
                  <CustomToggle label="group" value="group" />
                </ToggleButtonGroup>
                <CustomButton
                  variant="contained"
                  startIcon={<GroupOutlinedIcon />}
                  disabled={taskList?.length < 2}
                  onClick={handleGroup}>
                  Group Task
                </CustomButton>
              </Box>
            </Box>
            <CustomTable
              columns={TASK_LIST_COLUMNS}
              data={processedData}
              sortable={false}
              onClickDetail={onClickDetail}
              checkbox={mode !== "all"}
              selectedItem={taskList}
              rowCount={processedData?.length}
              onSelectAll={handleSelectAll}
              onCheckboxChange={handleClick}
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
          </Card>
        </Grid>
      </Grid>
      <ModalGroupTask
        open={openModalGroup}
        onClose={() => setOpenModalGroup(false)}
        taskList={taskList}
        userId={data?.find((item: any) => item.id === taskList[0])?.user_id}
        refetch={fetchData}
      />
    </Box>
  );
}

export default TaskList;
