import { Box, Card, Grid, IconButton, Paper, Stack } from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import { useEffect, useState } from "react";
import ModalConfirm from "../ModalConfirm";
import { useNavigate } from "react-router-dom";
import CustomTable from "../CustomTable";
import { MENU_MANAGEMENT_TABLE } from "src/constants/finance";
import AddIcon from "@mui/icons-material/Add";
import ModalAddMenuManagement from "src/pages/access-management/components/modalAddMenu";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DelteRoleMenuUnasignee, UpdateROleData } from "src/api/access-management/role";
import CustomInput from "../CustomInput";
import { useParameterStore } from "src/store/parameterStore";
import { getRoleUser } from "src/api/user";


function calculateTotalPage(totalItems: number, itemsPerPage: number): number {
  return Math.ceil(totalItems / itemsPerPage);
}

interface Props {
  data: any;
  listData:any;
  fetchNewData:() => void;
  roleId:string;
}
export default function ManagementDetail({ data,listData,fetchNewData,roleId }: Props) {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({ code: '', name: '', desc: '', id: '' });


  const [openModalAdd, setopenModalAdd] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string>("");
  const onCloseModal = () => {
    setOpenModalDelete(false);
    setSelectedData("");
  };

  const { setRoleList } = useParameterStore();


    
const fetchROleData = async () => {
  try {
    const response = await getRoleUser();
    setRoleList(response?.data?.data);
    // Reset the selected menu
  } catch (error) {
    // Handle error if fetching the data fails
    console.error("Error fetching role menu:", error);
  }
};

  useEffect(() => {
    if (data) {
      setFormData({
        code: data.Code,
        name: data.Name,
        desc: data.Desc,
        id: data.Id
      });
    }
  }, [data]);

  const onDelete = async () => {

    try {
      const response = await DelteRoleMenuUnasignee(selectedData);
    } catch (error: any) {
      console.error("Error fetching Leave detail:", error);
    }
    onCloseModal();
    fetchNewData();
  };
  const [modalUser, setModalUser] = useState<boolean>(false);

  // useEffect(() => {
  
  // }, [data,listData]);

  const handleSubmit = async () => {
    try {
      const response = await UpdateROleData(formData);
    } catch (error: any) {
      console.error("Error fetching Role detail:", error);
    }
    setIsEdit(!isEdit)
    setModalUser(false)
    fetchNewData()
    fetchROleData();
  };

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: calculateTotalPage(listData?.length,10),
    order_date_start: "",
    order_date_end: "",
  });

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    setOpenModalDelete(true);
    setSelectedData(id);
  };

  const formatData = (data: any) => {
    const processedData = data?.map((item: any) => {
      return {
        ...item,
        actions: (
          <Stack direction={"row"} spacing={1} justifyContent={"center"}>
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255,255,255, 0.2)",
              },
            }}
            onClick={(e) => handleDelete(e, item?.id_role)}
          >
            <DeleteOutlineOutlinedIcon color="error" />
          </IconButton>
          </Stack>
        ),
      };
    });
  
    return processedData;
  };
  const [records, setRecords] = useState(formatData(listData?.slice(0, filter.limit))); 

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ["total_page"]: calculateTotalPage(listData?.length,filter.limit),
    }));    
    if (calculateTotalPage(listData?.length,filter.limit) < filter.page  ){
      filter.page = 1
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      })); 
    }
    const from = (filter.page - 1) * filter.limit;
    const to = from + filter.limit;
    setRecords(formatData(listData?.slice(from, to)));

  }, [filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    filter.order_date_start,
    filter.order_date_end,data]);
  const handleFilterChange = (name: string, value: any) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const onClose = () => {
    setopenModalAdd(false);
  };

  const handleActionAddMenu = () => {
 
    // setDataDetail(dataIncentive);
    setopenModalAdd(true);
};
const handleEdit = () => {
  setIsEdit(!isEdit);
};
const handleDiscard = () => {
  setIsEdit(false);
  setFormData(data);
};

const handleSaveChanges = () => {
  setModalUser(true)
};


const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;
  setFormData((prevData: any) => ({
    ...prevData,
    [name]: value,
  }));
};

  const { theme } = useThemeStore();
  return (
    <Box>
      <ModalAddMenuManagement
        isOpen={openModalAdd}
        onClose={onClose}
        data={data}
        refetch={fetchNewData}
        onDetail={true}
        roleId={roleId}
        fetchRole={fetchROleData}
      />
      <Grid container rowGap={4}>
        <Grid item sm={12} md={6} lg={3}>
          <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
            Code
          </CustomText>
          <CustomInput
                disabled={!isEdit}
                value={formData?.code}
                onChange={onChange}
                required
                size="small"
                id="code"
                name="code"
                error={formData?.Code?.includes(" ")}
                helperText={
                  formData?.Code?.includes(" ")
                    ? "Code cannot contain spaces"
                    : ""
                }
              />        
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
            Role Name
          </CustomText>
          <CustomInput
                disabled={!isEdit}
                value={formData?.name}
                onChange={onChange}
                required
                size="small"
                id="name"
                name="name"
              />    
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
            Role Desc
          </CustomText>
          <CustomInput
                multiline
                minRows={2}
                disabled={!isEdit}
                value={formData?.desc}
                onChange={onChange}
                required
                size="small"
                id="desc"
                name="desc"
              />  
        </Grid>
      </Grid>
      <Box
          display={"flex"}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "flex-end",
            alignItems: {
              xs: "flex-start",
              md: "flex-end",
            },
            gap: {
              xs: 1,
              md: 2,
              lg: 3,
            },
          }}>
          {isEdit ? (
            <>
              <CustomButton
                variant="outlined"
                customType="cancel"
                onClick={handleDiscard}>
                Discard Changes
              </CustomButton>
              <CustomButton type="submit" variant="contained" onClick={handleSaveChanges}>
                Save Changes
              </CustomButton>
            </>
          ) : (
            <CustomButton variant="contained" onClick={handleEdit}>
              Edit
            </CustomButton>
          )}
        </Box>
      <Stack mt={4}>
        <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Menu List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
              
                <CustomButton
                  variant="contained"
                  onClick={handleActionAddMenu}
                  startIcon={<AddIcon />
                  }
                >
                  Add Menu Access
                </CustomButton>
              </Box>
              <ModalConfirm
        text="Are you sure you want to update Role Detail?"
        open={modalUser}
        onClose={() => setModalUser(false)}
        onSubmit={handleSubmit}
      />
            </Box>
      <CustomTable
        columns={MENU_MANAGEMENT_TABLE}
        data={records}
        //data={data?.expense_list}
        // onChangeFilter={handleFilterChange}
        // filter={filter}
        onChangeFilter={handleFilterChange}
        filter={filter}
        sortable={false}
      />
      </Stack>
      <ModalConfirm
        text="Are you sure you want to delete this Menu?"
        open={openModalDelete}
        onSubmit={onDelete}
        onClose={onCloseModal}
      />
    </Box>
  );
}
