export const getCurrencySymbol = (currencyValue: string) => {
  const currency = currencies.find(
    (currency) => currency.value === currencyValue
  );
  return currency ? currency.symbol : "";
};

export const Ekspedisi = [
  {
    value: "JNE",
    label: "JNE",
  },
  {
    value: "JNT",
    label: "JNT",
  },
  {
    value: "Antaraja",
    label: "Antaraja",
  },
  {
    value: "SiCepat",
    label: "SiCepat",
  },
];

export const userRole = [
  {
    value: "9faa490e-4ee3-44ad-a6d7-05560c9e7efb",
    label: "Admin",
  },
  {
    value: "2e8675f7-8663-456b-af59-7fb1a1e5d872",
    label: "Worker",
  },
  {
    value: "be8e03e0-efd6-4c83-bfb9-94c55fbf2060",
    label: "Logistic",
  },
];

export const userStatus = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];

export const priorityLevel = [
  {
    value: "Low",
    label: "Low",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "High",
    label: "High",
  }
];

export const userLevel = [
  {
    value: "Junior",
    label: "Junior",
  },
  {
    value: "Middle",
    label: "Middle",
  },
  {
    value: "Senior",
    label: "Senior",
  },
];

export const currencies = [
  {
    value: "IDR",
    symbol: "Rp",
  },
  {
    value: "USD",
    symbol: "$",
  },
];

export const currenciesShipment = [
  {
    value: "IDR",
    symbol: "Rp",
  }
];

export const orderFrom = [
  {
    value: "Instagram",
    label: "Instagram",
  },
  {
    value: "Whatsapp",
    label: "Whatsapp",
  },
  {
    value: "Email",
    label: "Email",
  },
  {
    value: "Walk In",
    label: "Walk In",
  },
];

export const paymentMethod = [
  {
    value: "Cash",
    label: "Cash",
    currency: "IDR", 
  },
  {
    value: "Transfer BCA",
    label: "Transfer BCA",
    currency: "IDR", 
  },
  {
    value: "PayPal",
    label: "PayPal",
    currency: "USD", 
  },
  {
    value: "Crypto",
    label: "Crypto",
    currency: "USD", 
  },
];

export const jaketSize = ["S", "M", "L", "XL", "XXL"];

export const dompetSize = ["Short", "Long"]

export const shoesSize = [
  "36", "36.5", "37", "37.5", "38", "38.5", "39", "39.5", "40", "40.5", "41", "41.5", "42", "42.5", "43",
  "43.5", "44", "44.5", "45", "45.5", "46", "46.5", "47", "47.5", "48", "48.5", "49", "49.5", "50", "50.5",
  "51", "51.5", "52", "52.5", "53", "53.5", "54", "54.5", "55", "55.5", "56", "56.5",
];

export const itemType = [
  {
    value: "Sepatu",
    label: "Sepatu",
    size: shoesSize,
  },
  {
    value: "Jaket",
    label: "Jaket",
    size: jaketSize,
  },
  {
    value: "Dompet",
    label: "Dompet",
    size: dompetSize,
  },
  {
    value: "Tas",
    label: "Tas",
    defaultSize:true
  },
]

export const serviceType = [
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f2",
    label: "Painting",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f3",
    label: "Decon Recon",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f4",
    label: "Mix Order",
  },
];

export const designType = [
  {
    value: "0707630e-b06a-4a35-8729-34305d1e8f1b",
    label: "Ready Design",
  },
  {
    value: "0707630e-b06a-4a35-8729-34305d1e8f1a",
    label: "Custom Design",
  },
];

export const taskType = [
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f2",
    label: "Painting",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f3",
    label: "Decon Recon",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f4",
    label: "Design",
  },
];

export const filterTable = [
  {
    page: "order",
    filter: [
      {
        name: "Order ID",
        key: "orderId",
      },
    ],
  },
  {
    page: "item",
    filter: [
      {
        name: "Order ID",
        key: "orderId",
      },
      {
        name: "Item ID",
        key: "itemId",
      },
      {
        name: "Supplier",
        key: "supplier",
      },
    ],
  },
  {
    page: "task",
    filter: [
      {
        name: "Assignee",
        key: "assignee",
      },
      {
        name: "Deadline",
        key: "deadline",
      },
    ],
  },
];

export const taskPoint = [
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc1",
    label: "Bronze",
  },
  {
    value: "479c885b-ff4c-4852-85fd-80c807e8aa3c",
    label: "Silver",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc2",
    label: "Silver (Detailing)",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc3",
    label: "Gold",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc4",
    label: "Gold (Detailing)",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05g44afc1",
    label: "Diamond",
  },
  {
    value: "f2913fb0-a535-436a-9e31-e5a05f44afc1",
    label: "Diamond (Detailing)",
  },
 
];
