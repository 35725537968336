import { useEffect, useState } from "react";
import CustomInput from "../CustomInput";
import NotificationAlert from "../Alert";
import { HideLoading } from "../Loading";
import { MenuItem } from "@mui/material";
import { GetTaskType } from "src/api/task";
import { useParameterStore } from "src/store/parameterStore";

export default function TaskPoint(props: any) {
  const { ...otherProps } = props ?? {};
  const { taskType } = useParameterStore();
  const [taskPoint, setTaskPoint] = useState<TaskPoint[]>(taskType);

  const fetchTaskPoint = async () => {
    try {
      const response = await GetTaskType();
      setTaskPoint(response?.data);
    } catch (error: any) {
      console.error("Error Get Task Point", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      HideLoading();
    }
  };

  useEffect(() => {
    if (taskType.length === 0) {
      fetchTaskPoint();
    }
  }, []);

  return (
    <CustomInput {...otherProps}>
      {taskPoint?.map(({ id, level_worker }: TaskPoint) => (
        <MenuItem key={id} value={id}>
          {level_worker}
        </MenuItem>
      ))}
    </CustomInput>
  );
}
