import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomText from "src/components/main/CustomText";
import DifferenceIcon from "@mui/icons-material/Difference";
import { Grid, InputAdornment, MenuItem, Modal, Stack } from "@mui/material";
import CustomDivider from "src/components/main/CustomDivider";
import useThemeStore from "src/store/themeStore";
import CustomButton from "src/components/main/CustomButton";
import { DecimalFormatter } from "src/utils/formatter";
import { hexToRgba } from "src/utils/utils";
import CustomPaper from "src/components/main/CustomPaper";
import { useCallback, useEffect, useState } from "react";
import CustomDropbox from "src/components/main/CustomDropbox";
import ModalConfirm from "src/components/main/ModalConfirm";
import { addIncentiveBonunusses, disburseIncentive } from "src/api/finance";
import { useNavigate } from "react-router-dom";
import NewInputNominal from "src/components/main/NewInputNominal";
import CustomInput from "src/components/main/CustomInput";
import NotificationAlert from "src/components/main/Alert";
import { AddMappingMenu, AddRoleData, GetRoleMenuUnasignee } from "src/api/access-management/role";
import { useParameterStore } from "src/store/parameterStore";
import { getRoleUser } from "src/api/user";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
  }



export default function ModalAddRole(props: Props) {
  const { setRoleList } = useParameterStore();


    
  const fetchROleData = async () => {
    try {
      const response = await getRoleUser();
      setRoleList(response?.data?.data);
      // Reset the selected menu
    } catch (error) {
      // Handle error if fetching the data fails
      console.error("Error fetching role menu:", error);
    }
  };

    const [formData, setFormData] = useState<any>({ code: "",name:"",desc:""});

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen, onClose, refetch } = props;
    const theme = useTheme();
    const { theme: myTheme } = useThemeStore();


    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [doneAction, setDoneAction] = useState<string[]>([]);
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData: any) => ({ ...prevData, [name]: value }));
      };
  
    const handleSubmit = async () => {
        const response = await AddRoleData(formData);
        if (response?.status === 200) {
          onClose();
        }
        fetchROleData();
        refetch()
        onClose();
    //   
    };
    const handleClose = () => {
      onClose();
    };
  
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <CustomPaper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "auto",
            boxShadow: 24,
            pt: 2,
            overflow:"auto"
          }}
        >
          <Box sx={{ width: "100%", flexGrow: 1 }}>
            <Box sx={{ px: 4 }}>
              <Stack direction={"row"} gap={2}>
                <DifferenceIcon
                  style={{ color: myTheme?.icon }}
                  fontSize="large"
                />
                <CustomText variant="h6" gutterBottom>
                  Add Role Data
                </CustomText>
              </Stack>
              <CustomDivider sx={{ mt: 1 }} />
              <Box sx={{ py: 2 }}>
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  alignContent={"center"}
                  justifyContent={"flex-start"}
                >
                </Stack>
                <Grid container columnSpacing={2}>
                  <Grid item sm={4}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Role Code
                      </CustomText>
                      <CustomInput
                value={formData?.code}
                onChange={handleChange}
                required
                size="small"
                id="code"
                name="code"
                error={formData?.Code?.includes(" ")}
                helperText={
                  formData?.Code?.includes(" ")
                    ? "Code cannot contain spaces"
                    : ""
                }
              /> 
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Role
                      </CustomText>
                      <CustomInput
                value={formData?.name}
                onChange={handleChange}
                required
                size="small"
                id="name"
                name="name"
              />    
                    </Stack>
                  </Grid>
                  <Grid item sm={4}>
                    <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
            Role Desc
          </CustomText>
          <CustomInput
                multiline
                minRows={2}
                value={formData?.desc}
                onChange={handleChange}
                required
                size="small"
                id="desc"
                name="desc"
              />  
                    </Stack>
                  </Grid>
                </Grid>  
                <Box mt={2}>
                    <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                      <CustomButton
                        variant="contained"
                        onClick={() => setConfirmDialog(true)}
                      >
                        Add Role
                      </CustomButton>
                    </Box>
                </Box>
              </Box>
            </Box>
  
            <ModalConfirm
              text="Are you sure you want to Add Role ?"
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              onSubmit={handleSubmit}
            />
          </Box>
        </CustomPaper>
      </Modal>
    );
}
