import { SxProps, Accordion, AccordionProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomAccordionProps extends Omit<AccordionProps, "variant"> {
  sx?: SxProps;
}

const CustomAccordion: React.FC<CustomAccordionProps> = (props) => {
  const { sx, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <Accordion
      slotProps={{ transition: { unmountOnExit: false } }}
      variant="outlined"
      sx={{
        "&&.MuiAccordion-root": {
          background: `linear-gradient(22.5deg, ${theme?.button100}, ${theme?.button200})`,
          color: theme?.inputText,
          border: "none",
          marginBottom: 4,
          borderRadius: "8px",
          "&:before": {
            display: "none",
            borderRadius: "8px",
          },
          "&.Mui-expanded": {
            marginBottom: 4,
            borderRadius: "8px",
          },
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default CustomAccordion;
