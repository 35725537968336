import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import CustomText from "src/components/main/CustomText";
import CustomButton from "src/components/main/CustomButton";
import { useOrderStore } from "src/store/orderStore";
import { GetShipmentByCustomer } from "src/api/shipment";
import NotificationAlert from "src/components/main/Alert";
import useThemeStore from "src/store/themeStore";
import CustomPaper from "src/components/main/CustomPaper";
import CustomCard from "src/components/main/CustomCard";
import countries from "src/assets/countries.json";

export default function ShippingAddress() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);
  const [shippingList, setShippingList] = useState<any[]>([]);

  const { customer, newShipment, shipment, setShipment } = useOrderStore();
  const { theme } = useThemeStore();

  const fetchShippingAddress = async () => {
    try {
      const response = await GetShipmentByCustomer(customer?.id);
      if (newShipment.isNewShipment) {
        setShippingList([
          newShipment.shipment,
          ...(response?.data ? response.data : []),
        ]);
        setShipment(newShipment.shipment);
      } else {
        const shipmentData = response?.data?.length ? response.data[0] : {};

        if (shipmentData.country) {
          const codeCountry = countries.find(
            (country) => country.name === shipmentData.country
          )?.code;
          shipmentData.codeCountry = codeCountry; // Assign codeCountry to shipmentData
        }

        setShippingList(response?.data);
        setShipment(shipmentData);
      }
    } catch (error: any) {
      console.error("Error fetching customer shipment:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  useEffect(() => {
    if (customer?.id) {
      fetchShippingAddress();
    } else if (!customer?.id) {
      newShipment.isNewShipment
        ? setShippingList([newShipment.shipment])
        : setShippingList([]);
    }
  }, [customer?.id]);

  useEffect(() => {
    if (newShipment.isNewShipment) {
      setShippingList([newShipment.shipment, ...shippingList]);
      setShipment(newShipment.shipment);
    } else {
      setShippingList(shippingList);
    }
    handleSelectShipment(0);
  }, [newShipment.isNewShipment]);

  const handleSelectShipment = (index: number) => {
    setSelected(index);
    if (shippingList?.length > 0) {
      if (shippingList[index].country) {
        const codeCountry = countries.find(
          (country) => country.name === shippingList[index].country
        )?.code;
        shippingList[index].codeCountry = codeCountry; // Assign codeCountry to shipmentData
      }
      setShipment(shippingList[index]);
    }
  };

  return (
    <CustomCard text="Shipping Address">
      <Box
        display={"flex"}
        sx={{
          flexDirection: "column",
          justifyContent: "normal",
          alignItems: "flex-start",
        }}>
        {!newShipment.isNewShipment && (
          <CustomButton
            onClick={() => navigate("/order/create/shipping")}
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ marginBottom: 2 }}>
            Add New Shipping Address
          </CustomButton>
        )}
        <Box width={"100%"} flexDirection={"row"}>
          <Grid spacing={2}>
            {shippingList &&
              shippingList.map((item, index) => (
                <Grid
                  item
                  xs={6}
                  key={index}
                  onClick={() => handleSelectShipment(index)}>
                  <Paper
                    style={{ padding: 20, marginBottom: 20, width: "100%" }}
                    sx={{
                      background: theme?.inputPrimary,
                      border:
                        index === selected
                          ? `1px solid ${theme?.button100}`
                          : `1px solid ${theme?.inputPrimary}`,
                    }}>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}>
                      <Stack direction={"column"}>
                        <Stack direction={"row"}>
                          <CustomText
                            variant="body1"
                            gutterBottom
                            fontWeight={600}>
                            {item?.recipient_name}
                          </CustomText>
                          <CustomText variant="body1">
                            &ensp;({item?.type_shipment})
                          </CustomText>
                        </Stack>
                        <CustomText variant="body1" gutterBottom>
                          {item?.recipient_contact}
                        </CustomText>
                        <CustomText variant="body1" gutterBottom>
                          {item?.address}
                        </CustomText>
                        <CustomText variant="body1">{item.country}</CustomText>
                        <CustomText variant="body1" gutterBottom>
                          {item?.city}, {item?.province}, {item?.postal_code}
                        </CustomText>
                        <CustomText
                          variant="body1"
                          gutterBottom
                          fontWeight={"bold"}
                          mt={2}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              textDecoration: "underline",
                              WebkitTextFillColor: theme?.inputLabelHover,
                            },
                          }}
                          onClick={() =>
                            item?.id
                              ? navigate(`/customer/${customer?.id}`)
                              : navigate("/order/create/shipping")
                          }>
                          Edit Address
                        </CustomText>
                      </Stack>
                      <Box>
                        {selected === index ? (
                          <CustomButton
                            variant="contained"
                            startIcon={<CheckIcon />}
                          />
                        ) : (
                          <CustomButton
                            variant="contained"
                            onClick={() => handleSelectShipment(index)}>
                            Select
                          </CustomButton>
                        )}
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </CustomCard>
  );
}
