import React, { useMemo } from "react";
import { Button, ButtonProps, SxProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomButtonProps extends ButtonProps {
  sx?: SxProps;
  customType?: "disable" | "cancel";
}

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { sx, customType, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  const outlinedStyle = useMemo(() => {
    if (customType === "disable") {
      return {
        border: `2px solid ${theme?.button100}`,
        background: `-webkit-linear-gradient(22.5deg, ${theme?.button100}, ${theme?.button300})`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        "&:hover": {
          background: `-webkit-linear-gradient(90deg, ${theme?.button100}, ${theme?.button200})`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
      };
    }
    if (customType === "cancel") {
      return {
        border: `2px solid ${theme?.button100}`,
        color: theme?.button100,
        transition: "opacity 0.3s ease",
        "&:hover": {
          opacity: 0.7,
        },
      };
    }
    return {};
  }, [customType, theme]);

  return (
    <Button
      sx={{
        fontWeight: 700,
        "&&.MuiButton-contained": {
          background: `linear-gradient(22.5deg, ${theme?.button100}, ${theme?.button200})`,
          color: theme?.buttonText,
          animation: "backgroundReverse 0.3s ease forwards", // Apply animation on hover
          "&:hover": {
            animation: "backgroundChange 0.3s ease forwards", // Apply animation on hover
          },
          "&.Mui-disabled": {
            color: theme?.inputTextDisabled,
          },
        },

        "&.MuiButton-outlined": outlinedStyle,
        "@keyframes backgroundChange": {
          "0%": {
            background: `linear-gradient(22.5deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "10%": {
            background: `linear-gradient(25deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "20%": {
            background: `linear-gradient(30deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "30%": {
            background: `linear-gradient(35deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "40%": {
            background: `linear-gradient(40deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "50%": {
            background: `linear-gradient(45deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "60%": {
            background: `linear-gradient(50deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "70%": {
            background: `linear-gradient(55deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "80%": {
            background: `linear-gradient(57.5deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "90%": {
            background: `linear-gradient(59deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "100%": {
            background: `linear-gradient(60deg, ${theme?.button100}, ${theme?.button200})`,
          },
        },

        "@keyframes backgroundReverse": {
          "0%": {
            background: `linear-gradient(60deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "10%": {
            background: `linear-gradient(59deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "20%": {
            background: `linear-gradient(57.5deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "30%": {
            background: `linear-gradient(55deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "40%": {
            background: `linear-gradient(50deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "50%": {
            background: `linear-gradient(45deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "60%": {
            background: `linear-gradient(40deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "70%": {
            background: `linear-gradient(35deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "80%": {
            background: `linear-gradient(30deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "90%": {
            background: `linear-gradient(25deg, ${theme?.button100}, ${theme?.button200})`,
          },
          "100%": {
            background: `linear-gradient(22.5deg, ${theme?.button100}, ${theme?.button200})`,
          },
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default CustomButton;
