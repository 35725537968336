import { useLoadingStore } from "src/store/loadingStore";
import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import { sendRequestGET, sendRequestPOST } from "../sendRequest";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();


export const getReimbursementList = async (data: FilterReimbursement) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/reimbursement");  const url = process.env.REACT_APP_ENDPOINT_REIMBURSEMENT_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    if (data.order_by==="expedtition"){
      data.order_by= "expedition"
    }
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION REIMBURSEMENT LIST", res);
    return res.data.data;
  } catch (error: any) {
    console.error("ACTION ERROR REIMBURSEMENT LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getReimbursementDetail = async (id: string) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/reimbursement");  const url = process.env.REACT_APP_ENDPOINT_REIMBURSEMENT_DETAIL || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION REIMBURSEMENT DETAIL", res);
    return res?.data as Reimbursement;
  } catch (error: any) {
    console.error("ACTION ERROR REIMBURSEMENT DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const approveRejectReimbursement = async (
  
  data: ApproveRejectReimbursement
) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/reimbursement");
  const url = process.env.REACT_APP_ENDPOINT_REIMBURSEMENT_APPROVE_REJECT || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION REIMBURSEMENT APPROVE REJECT", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR REIMBURSEMENT APPROVE REJECT", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const disburseReimbursement = async (data: DisburseReimbursement) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/reimbursement");
  const url = process.env.REACT_APP_ENDPOINT_REIMBURSEMENT_DISBURSE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION REIMBURSEMENT DISBURSE", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR REIMBURSEMENT DISBURSE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
}


export const getDetailGroupingReimbursement = async (
  
  data: any,
  id:string
) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/reimbursement");
  const url = process.env.REACT_APP_ENDPOINT_REIMBURSEMENT_DETAIL_GROUPING || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(`${url}/${id}`, data, header);
    console.info("ACTION REIMBURSEMENT APPROVE REJECTs", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR REIMBURSEMENT APPROVE REJECT", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};