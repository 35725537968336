// export const DecimalFormatter = (input: string): string => {
//   if (!input) return "0.00";

//   // Allow negative sign at the beginning
//   let newValue = input.replace(/[^0-9.-]/g, "");

//   // Handle multiple negative signs or incorrect placement
//   if ((newValue.match(/-/g) || []).length > 1 || newValue.indexOf("-") > 0) {
//     newValue = newValue.replace(/-/g, ""); // Remove all hyphens
//   }

//   newValue = parseFloat(newValue).toLocaleString("en-US", { maximumFractionDigits: 2 });

//   const decimalIndex = newValue.indexOf(".");
//   if (decimalIndex === -1) {
//     newValue += ".00";
//   } else if (newValue.length - decimalIndex === 2) {
//     newValue += "0";
//   }

//   return newValue;
// };

export const DecimalFormatter = (input: string): string => {
  if (!input) return "0";

  // Allow negative sign at the beginning
  let newValue = input.replace(/[^0-9.-]/g, "");

  // Handle multiple negative signs or incorrect placement
  if ((newValue.match(/-/g) || []).length > 1 || newValue.indexOf("-") > 0) {
    newValue = newValue.replace(/-/g, ""); // Remove all hyphens
  }

  newValue = parseFloat(newValue).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });

  // const decimalIndex = newValue.indexOf(".");
  // if (decimalIndex === -1) {
  //   newValue += "";
  // } else if (newValue.length - decimalIndex === 2) {
  //   newValue += "0";
  // }

  return newValue;
};

export const parseAmount = (input: string): number => {
  if (!input) return 0;
  const newValue = input.replace(/[^0-9.]/g, "");
  const floatValue = parseFloat(newValue);
  if (isNaN(floatValue)) return 0;
  return floatValue;
};

export function formatTime(date: Date): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  return hours + ":" + minutesStr + " " + ampm;
}

export function getCurrentTime(): string {
  const now = new Date();
  return formatTime(now);
}

export const getDayLeft = (date: string): number => {
  const dateObj = new Date(date);
  const now = new Date();
  const diff = dateObj.getTime() - now.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};

export const capitalizeEachWord = (input: string): string => {
  // Check if the input is empty or only whitespace
  if (!input.trim()) return "";

  // Capitalize each word
  return input.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
};
