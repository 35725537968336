import { SxProps, FormGroup, FormGroupProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomFormGroupProps extends Omit<FormGroupProps, "variant"> {
  sx?: SxProps;
}

const CustomFormGroup: React.FC<CustomFormGroupProps> = (props) => {
  const { sx, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <FormGroup
      sx={{
        "& .MuiFormControlLabel-root": {
          color: theme?.inputLabel,
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default CustomFormGroup;
