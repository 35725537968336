import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Navbar from "../Navbar";
import { useLocation, useNavigate } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AlarmOffOutlinedIcon from "@mui/icons-material/AlarmOffOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PaymentIcon from "@mui/icons-material/Payment";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import useThemeStore from "src/store/themeStore";
import BreadCrumbs from "../BreadCrumb";
import { DRAWER_WIDTH } from "src/constants/ui";
import { useLayoutStore } from "src/store/layoutStore";
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useUserStore } from "src/store/userStore";
const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  backgroundColor: "transparent",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "transparent",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export interface SidebarProps {
  children: React.ReactNode;
  open: boolean;
}

export default function Sidebar({ children, open }: SidebarProps) {
  const {menu_mapping} = useUserStore();
  const theme = useTheme();
  const layoutStore = useLayoutStore();
  const { theme: myTheme } = useThemeStore();
  const [openSub, setOpenSub] = React.useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleClickSub = (menu: string) => {
    setOpenSub((prev) => ({ ...prev, [menu]: !prev[menu] }));
    layoutStore.openSidebar();
  };

  const handleClickExpand = (menu: string, submenu: string) => {
    navigate(submenu);
    setOpenSub((prev) => ({ ...prev, [menu]: false }));
  };

  const handleDrawerClose = () => {
    setOpenSub({});
    layoutStore.closeSidebar();
  };

  const renderIcon = React.useCallback(
    (page: string) => {
      if (page === "/") {
        return <DesktopWindowsOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/order") {
        return <LocalMallOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/item") {
        return <WidgetsOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/task") {
        return <InventoryOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/task/task-list") {
        return <ChecklistOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/task/task-management") {
        return <ManageHistoryOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/user") {
        return <PeopleAltOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/customer") {
        return <SupportAgentOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/finance") {
        return <AccountBalanceOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/finance/monthly-recap") {
        return <AssessmentOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/finance/salary") {
        return <PaidOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/finance/incentive") {
        return <CurrencyExchangeIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/finance/debt") {
        return <AssessmentOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/company") {
        return <BusinessOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/company/company-settings") {
        return <SettingsIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/company/collective-leave") {
        return <NotificationsActiveIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/reimbursement") {
        return <PaymentIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/leave") {
        return <AlarmOffOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/purchase") {
        return <ShoppingBagOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/purchase/shipment") {
        return <LocalShippingOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/purchase/purchase") {
        return <ShoppingCartOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/access-management/role"){
        return <AccessibilityNewOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      if (page === "/access-management/menu"){
        return <MenuOutlinedIcon sx={{ color: myTheme?.icon }} />;
      }
      return <ConstructionOutlinedIcon sx={{ color: myTheme?.icon }} />;
    },
    [menu_mapping]
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        display: "flex",
        background: myTheme?.bgPrimary,
      }}
    >
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ bgcolor: myTheme?.bgPaper }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: myTheme?.icon }} />
            ) : (
              <ChevronLeftIcon sx={{ color: myTheme?.icon }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ borderColor: myTheme?.divider }} />
        <List
          sx={{ minHeight: "90vh", height: "100vh", bgcolor: myTheme?.bgPaper }}
        >
          {menu_mapping
            .filter((item) => !item?.isSubmenu)
            .map((item) => (
              <ListItem
                onClick={
                  item?.hasSubmenu
                    ? () => handleClickSub(item?.menu)
                    : () => navigate(item?.menu)
                }
                key={item?.id}
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    item?.menu.split("/")[1] === pathname.split("/")[1] &&
                    !openSub[item?.menu]
                      ? myTheme?.bgPrimary
                      : myTheme?.bgPaper,
                  borderRight:
                    item?.menu.split("/")[1] === pathname.split("/")[1] &&
                    !openSub[item?.menu]
                      ? `2px solid ${myTheme?.button100}`
                      : null,
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: myTheme?.bgPrimary,
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {renderIcon(item?.menu)}
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.title}
                    sx={{ opacity: open ? 1 : 0, color: myTheme?.text }}
                  />
                  {item?.hasSubmenu && open ? (
                    item?.hasSubmenu && openSub[item?.menu] ? (
                      <ExpandLess sx={{ color: myTheme?.icon }} />
                    ) : (
                      <ExpandMore sx={{ color: myTheme?.icon }} />
                    )
                  ) : null}
                </ListItemButton>
                {item?.hasSubmenu && openSub[item?.menu] ? (
                  <Collapse
                    in={openSub[item?.menu]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {menu_mapping
                      .filter(
                        (submenu) =>
                          submenu.isSubmenu && submenu.title === item.title
                      )
                      .map((submenu) => (
                        <List
                          component="div"
                          disablePadding
                          key={`${item?.title}-${submenu?.subtitle}`}
                          onClick={() =>
                            handleClickExpand(item?.menu, submenu?.menu)
                          }
                          sx={{
                            backgroundColor:
                              submenu?.menu?.split("/")[2] ===
                              pathname.split("/")[2]
                                ? myTheme?.bgPrimary
                                : "transparent",
                            "&:hover": {
                              backgroundColor: myTheme?.bgPrimary,
                            },
                            borderRight: pathname.includes(submenu?.menu)
                              ? `2px solid ${myTheme?.button100}`
                              : null,
                          }}
                        >
                          <ListItemButton sx={{ pl: 5 }}>
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {renderIcon(submenu?.menu)}
                            </ListItemIcon>
                            <ListItemText
                              primary={submenu?.subtitle}
                              sx={{ color: myTheme?.text }}
                            />
                          </ListItemButton>
                        </List>
                      ))}
                  </Collapse>
                ) : null}
              </ListItem>
            ))}
        </List>
        {/* <Divider /> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Box>
          <BreadCrumbs />
          <Box sx={{ px: 3, py: 2 }}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
}
