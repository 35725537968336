import { SxProps, Paper, PaperProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomPaperProps extends Omit<PaperProps, "elevation"> {
  sx?: SxProps;
}

const CustomPaper: React.FC<CustomPaperProps> = (props) => {
  const { sx, children, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <Paper
      elevation={3}
      sx={{
        "&.MuiPaper-root": {
          "background-color": theme?.bgPaper,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Paper>
  );
};

export default CustomPaper;
