import { Box, Stack } from "@mui/material";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import CustomButton from "../CustomButton";
import { useEffect, useState } from "react";
import { useLoadingStore } from "src/store/loadingStore";
import { formatPhoneNumber } from "src/utils/utils";
import useThemeStore from "src/store/themeStore";
import { useLocation } from "react-router-dom";
import { getWAMessageCompletedDeliveryOrder } from "src/api/order";




  
export default function WaCLientOrder({ information }: any) {
    const { showNotification } = useLoadingStore();

    const id = useLocation().pathname.split("/").pop();
    const [message, setMessage] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");

    const fetchWAMessage = async () => {
      const res = await getWAMessageCompletedDeliveryOrder(id!);
      setMessage(res?.data?.wa_message || "");
      setPhoneNumber(res?.data?.no_telp || "");
    };

const { theme } = useThemeStore();

useEffect(() => {
  fetchWAMessage();
}, []);

const handleCopyClipboard = () => {
  navigator.clipboard
    .writeText(decodeURIComponent(message?.replace(/\+/g, " ")))
    .then(() => {
      showNotification("Message copied to clipboard", "success");
    });
};


  useEffect(() => {
    setMessage("")
  }, []);

  return (
    <Box
      component={"form"}
      sx={{ width: { xs: "70%", md: "60%", lg: "50%" } }}
    >
    <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
    <CustomText variant="body1">Send a Message</CustomText>
          <Stack
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
            }}
            mt={2}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <CustomButton
              variant="contained"
              onClick={() =>
                window.open(
                  `https://wa.me/${formatPhoneNumber(
                    phoneNumber
                  )}?text=${message}`,
                  "_blank"
                )
              }
            >
              WA.me Link
            </CustomButton>
            <CustomText variant="body1">or</CustomText>
            <CustomButton
              variant="contained"
              onClick={() => handleCopyClipboard()}
            >
              Copy to clipboard
            </CustomButton>
          </Stack>
        </CustomPaper>
        </Box>

  );
}
