import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLoadingStore } from "./store/loadingStore";
import LoadingAtom from "./components/main/Loading/components";
import RootTheme from "./themes/rootTheme";
import AlertInfo from "./components/main/AlertInfo";

function App() {
  const { isLoading, isNotification } = useLoadingStore();
  return (
    <DndProvider backend={HTML5Backend}>
      <RootTheme>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <RouterProvider router={router} />
          {isNotification && <AlertInfo />}
          {isLoading && <LoadingAtom />}
        </LocalizationProvider>
      </RootTheme>
    </DndProvider>
  );
}

export default App;
