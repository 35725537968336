import { Box } from "@mui/material";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";

export default function Information({ information }: any) {
  return (
    <Box sx={{ width: { xs: "70%", md: "50%", lg: "40%" } }}>
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <CustomText variant="body1">{information}</CustomText>
      </CustomPaper>
    </Box>
  );
}
