import { ToggleButton } from "@mui/material";
import useThemeStore from "src/store/themeStore";

function CustomToggle({ value, label }: { value: string; label: string }) {
  const { theme } = useThemeStore();

  return (
    <ToggleButton
      size="small"
      sx={{
        "&.MuiToggleButton-root.Mui-selected": {
          background: `linear-gradient(22.5deg, ${theme?.button100}, ${theme?.button200})`,
          color: "white",
          fontWeight: 700,
        },
        color: "white",
        fontWeight: 700,
        minWidth: '80px',
      }}
      value={value}
    >
      {label}
    </ToggleButton>
  );
}

export default CustomToggle;
