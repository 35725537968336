import { create } from "zustand";

export const useProcessStore = create<any>()((set) => ({
  dataTask: {},
  taskNeedAction: {},
  allTaskCompleted: false,
  marketingTask: false,
  marketingTaskPre: false,
  setDataTask: (dataTask: any) => set(() => ({ dataTask: dataTask })),
  setTaskNeedAction: (taskNeedAction: any) => set(() => ({ taskNeedAction })),
  setAllTaskCompleted: (allTaskCompleted: boolean) => set(() => ({ allTaskCompleted })),
  setMarketingTask: (marketingTask: boolean) => set(() => ({ marketingTask })),
  setMarketingTaskPre: (marketingTaskPre: boolean) => set(() => ({ marketingTaskPre })),
}));
