import { Box, Card, CardProps, Divider, SxProps } from "@mui/material";
import CustomDivider from "../CustomDivider";
import CustomText from "../CustomText";

interface CustomCardProps extends CardProps {
  sx?: SxProps;
  text?: string;
  children?: React.ReactNode;
  actionHeader?: React.ReactNode;
}

export default function CustomCard(props: CustomCardProps) {
  const { sx, text, children, actionHeader, ...otherProps } = props ?? {};

  return (
    <Card sx={{ p: 2, my: 4, ...sx }} {...otherProps}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomText
          textTransform={"capitalize"}
          textAlign={"left"}
          variant="h6"
          fontWeight={400}
        >
          {text}
        </CustomText>
        {actionHeader}
      </Box>
      {text && <Divider sx={{ mt: 1, mb: 2 }} />}
      {children}
    </Card>
  );
}
