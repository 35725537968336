import axios from "axios";
import { redirect } from "react-router-dom";

export const loader = async () => {
  try {
    const res = await axios.get("https://jsonplaceholder.typicode.com/todos/1");
    if (res.status !== 200) {
      return redirect("/login");
    }
    return res.data;
  } catch (error) {
    return redirect("/login");
  }
};
