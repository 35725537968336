import React, { useEffect } from "react";
import {
  Box,
  Stack,
  Grid,
  Link,
  AccordionSummary,
  Button,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import { TypeService, TypeDesign } from "src/components/main/Item/Type";
import colors from "../../../../themes/colors";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import CustomButton from "src/components/main/CustomButton";
import ItemForm from "src/components/main/Item/Detail";
import { useOrderStore } from "src/store/orderStore";
import CustomAccordion from "src/components/main/CustomAccordion";
import useThemeStore from "src/store/themeStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";

export default function ItemList() {
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [initialData, setInitialData] = React.useState<any>([]);
  const [formData, setFormData] = React.useState<any>([{}]);

  const { theme } = useThemeStore();
  const { itemList } = useOrderStore();

  useEffect(() => {
    setFormData(itemList);
    setInitialData(itemList);
  }, [itemList]);

  const handleItemData = (e: any, index: number) => {
    const { name, value, checked } = e.target;
    let newValue = value;

    if (name === "from_client" || name === "leather_from") {
      newValue = checked;
    }

    const newData = [...formData];
    newData[index] = {
      ...newData[index],
      [name]: newValue,
    };

    setFormData(newData);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleDiscard = () => {
    setIsEdit(false);
    setFormData(initialData);
  };

  return (
    <Box
      display={"flex"}
      sx={{
        flexDirection: "column",
        justifyContent: "justify-normal",
        alignItems: "flex-start",
        gap: {
          xs: 0,
          md: 1,
          lg: 2,
        },
      }}>
      <Box width={"100%"} component={"form"} onSubmit={handleSubmit}>
        <Box component="form" width={"100%"}>
          {formData?.map((item: any, index: number) => (
            <CustomAccordion
              key={index}
              defaultExpanded={index === 0 ? true : false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1-content"
                id="status"
                sx={{
                  borderRadius: "8px",
                }}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent={"space-between"}
                  justifyItems={"center"}>
                  <Stack direction={"row"}>
                    <Link
                      href={`/item/${item?.id}`}
                      onClick={(e) => e.stopPropagation()}
                      sx={{
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                          textDecorationColor: theme?.inputLabel,
                          color: "inherit",
                        },
                      }}>
                      <CustomText variant="inherit" fontWeight={"bold"}>
                        {`Item ID : ${item?.id}`}
                      </CustomText>
                    </Link>
                    &nbsp;&nbsp;&nbsp;
                    <Link
                      href={`/item/${item?.id}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{ color: "inherit", textDecoration: "inherit" }}>
                      <Tooltip title="View Item">
                        <LaunchIcon />
                      </Tooltip>
                    </Link>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: theme?.bgPaper,
                  borderBottomRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}>
                <ItemForm
                  formData={formData}
                  data={item}
                  disabled={!isEdit}
                  index={index}
                  onChange={handleItemData}
                  isDetail={true}
                  sizeItem={item}
                />
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Box>
        {/* <Box
          mt={4}
          display={"flex"}
          width={"100%"}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "flex-end",
            alignItems: {
              xs: "flex-start",
              md: "flex-end",
            },
            gap: {
              xs: 1,
              md: 2,
              lg: 3,
            },
          }}
        >
          {isEdit ? (
            <>
              <CustomButton
                variant="outlined"
                customType="cancel"
                onClick={handleDiscard}
              >
                Discard Changes
              </CustomButton>
              <CustomButton
                type="submit"
                variant="contained"
                style={{ backgroundColor: colors?.primary200 }}
              >
                Save Changes
              </CustomButton>
            </>
          ) : (
            <CustomButton
              variant="contained"
              style={{ backgroundColor: colors?.primary200 }}
              onClick={handleEdit}
            >
              Edit
            </CustomButton>
          )}
        </Box> */}
      </Box>
    </Box>
  );
}
