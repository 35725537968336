import { createRoot } from "react-dom/client";
import LoadingAtom from "./components";

interface Props {
  message?: string;
}

export function ShowLoading({ message = "Loading..." }: Props) {
  const root = document.createElement("div");
  root.setAttribute("id", "loadingComponentAtom");
  document.body.appendChild(root);

  createRoot(root).render(
    <LoadingAtom message={message} />
  );
}

export function HideLoading() {
  const root = document.getElementById("loadingComponentAtom");
  if (root) {
    document.body.removeChild(root);
  }
}
