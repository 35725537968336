import { SxProps, Tabs as MuiTabs, Tab as MuiTab, Box } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomTabsProps {
  sx?: SxProps;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  children: React.ReactNode; // Content to render within the tabs
}

const CustomTabs: React.FC<CustomTabsProps> = (props) => {
  const { sx, value, onChange, children, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      centered
      sx={{
        "& .MuiTab-root": {
          color: theme?.inputLabel,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </MuiTabs>
  );
};

const CustomTab: React.FC<{ label: string; sx?: SxProps }> = (props) => {
  const { sx, label, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();
  
  return <MuiTab label={label} sx={{
    "& .MuiTab-root": {
      color: theme?.inputLabel,
    }
  }} {...otherProps} />;
};

export default CustomTabs;
export { CustomTab };
