import { darkTheme } from "src/themes/theme";
import { create } from "zustand";

interface IThemeState {
  theme: ITheme;
  setTheme: (themeVal: ITheme) => void;
}

const useThemeStore = create<IThemeState>()((set) => ({
  theme: darkTheme,
  setTheme: (themeVal: ITheme) => set(() => ({ theme: themeVal })),
}));

export default useThemeStore;
