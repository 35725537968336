import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface RoleState {
  role: RoleUser[];
  taskType: TaskPoint[];
  setRoleList: (role: RoleUser[]) => void;
  setTaskType: (taskType: TaskPoint[]) => void;
}

export const useParameterStore = create<RoleState>()(
  persist(
    (set) => ({
      role: [],
      taskType: [],
      setRoleList: (role: RoleUser[]) => set(() => ({ role })),
      setTaskType: (taskType: TaskPoint[]) => set(() => ({ taskType })),
    }),
    {
      name: "param-state", // Name for the persisted storage key
      storage: createJSONStorage(() => localStorage),
    }
  )
);
