import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import {
  sendRequestGET,
  sendRequestPOST,
  sendRequestPUT,
} from "../sendRequest";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();



interface CustomerList {
  status?: Array<string>;
  start_date?: string;
  end_date?: string;
  id?: string;
  page: number;
  limit: number;
  order_key?: string;
  sort_type?: string;
}

export const InquiryCustomerList = async (
  {
    status,
    start_date,
    end_date,
    id,
    page,
    limit,
    order_key,
    sort_type,
  }: CustomerList = { page: 1, limit: 10 }
) => {
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_LIST || "";

  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(url, header);

    console.info("ACTION CUSTOMER LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR CUSTOMER LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetCustomerDetail = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_LIST || "";
const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION CUSTOMER DETAIL", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR CUSTOMER DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

interface SearchCustomer {
  key: string;
  page_number?: number;
  number_of_record?: number;
  start_date?:string;
  end_date?:string,
}

export const SearchCustomer = async ({
  key,
  page_number = 1,
  number_of_record,
  start_date,
  end_date,
}: SearchCustomer) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_SEARCH || "";
const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    key: key !== undefined ? key : "",
    page_number: page_number !== undefined ? page_number : 0,
    number_of_record: number_of_record !== undefined ? number_of_record : 10,
    start_date:start_date,
    end_date:end_date
  };

  try {
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION SEARCH CUSTOMER", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR SEARCH CUSTOMER", error);
  }
};

export const updateCustomerDetail = async (data: Customer) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPUT(url, data, header);
    console.info("ACTION UPDATE CUSTOMER DETAIL", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE CUSTOMER DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const exportExcelCustomer = async () => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestGET(url, header);
    console.info("ACTION EXPORT EXCEL CUSTOMER", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR EXPORT EXCEL CUSTOMER", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};


const generateFilename = (prefix: string, extension: string): string => {
  const now = new Date();
  const formattedDate = now.toISOString().replace(/[-:T]/g, "").split(".")[0]; // e.g., "20230920T123456"
  return `${prefix}-${formattedDate}${extension}`;
};

export const downloadReportCustomer = async () => {
  const userStore = useUserStore.getState();

  const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const url = process.env.REACT_APP_ENDPOINT_DOWNLOAD_REPORT_CUSTOMER || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    // Fetch the file from the API endpoint
    const response = await fetch(url, {
      method: "GET",
      headers: header,
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error: ${response}`);
    }

    // Convert the response to a Blob (handles any file type)
    const blob = await response.blob();

    // Create a temporary URL for the Blob
    const fileUrl = window.URL.createObjectURL(blob);

    // Determine the filename from the Content-Disposition header if available
    const fileName = generateFilename("Customer-Report", ".xlsx");

    

    // Create an anchor element and set its href to the Blob URL
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = fileName; // Use the dynamic filename

    // Append the anchor to the body
    document.body.appendChild(downloadLink);
    // Trigger the download by programmatically clicking the anchor
    downloadLink.click();

    // Clean up: remove the anchor element and revoke the Blob URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileUrl);

    console.info("ACTION DOWNLOAD REPORT SALARY SUCCESS");
    showNotification("Report downloaded successfully", "success");
  } catch (error: any) {
    console.error("ACTION ERROR DOWNLOAD REPORT SALARY", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};


export const SearchCustomerPageList = async ({
  key,
  page_number = 1,
  number_of_record,
  start_date,
  end_date,
}: SearchCustomer) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_SEARCH || "";
const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    key: key !== undefined ? key : "",
    page_number: page_number !== undefined ? page_number : 0,
    number_of_record: number_of_record !== undefined ? number_of_record : 10,
    start_date:start_date,
    end_date:end_date
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION SEARCH CUSTOMER", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR SEARCH CUSTOMER", error);
  } finally {
    hideLoading();
  }
  
};


export const addCustomerShipmentAddress = async ({
  idCustomer,
  shipmentData,
}: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CUSTOMER_ADDSHIPMENT || "";
const menu_id = FindMenuID(userStore.menu_mapping, "/customer");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    customer_id:idCustomer,
    province:shipmentData?.province,
    city:shipmentData?.city,
    village:shipmentData?.village,
    district:shipmentData?.district,
    address:shipmentData?.address,
    postal_code:shipmentData?.postal_code,
    recipient_name:shipmentData?.recipient_name,
    recipient_contact:shipmentData?.recipient_contact,
    type_shipment:shipmentData?.type_shipment,
    country:shipmentData?.country,
     is_international:
        (shipmentData?.country ?? "").toLowerCase() !== "indonesia"
  };


  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION SEARCH CUSTOMER", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR SEARCH CUSTOMER", error);
    showNotification(error.message, "error");
    throw error
  } finally{
    hideLoading();
  }
};