import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUserStore } from "src/store/userStore";
import NotificationAlert from "src/components/main/Alert";


interface ProtectedRouteProps {
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
    const {
        menu_mapping,
      } = useUserStore();
  const location = useLocation();
 
  const basePath = location.pathname.split("/").slice(0, 2).join("/");

  // Check if the user has access to the base path
  const isBasePathAccessible = menu_mapping.some((menu) => menu?.menu === basePath);

  useEffect(() => {
    // If the user does not have access to the base path, redirect them to the first accessible path
    if (!isBasePathAccessible) {
      const firstAccessiblePath = menu_mapping[0]?.menu ? menu_mapping[0]?.menu :"/login";  // Redirect to the first accessible path
      NotificationAlert({ message: `You do not have access to ${location.pathname}. Redirecting to ${firstAccessiblePath}`, status: "error" });
    }
  }, [isBasePathAccessible, location.pathname, menu_mapping]);

  // If the base path is not accessible, redirect to the first accessible base path
  if (!isBasePathAccessible) {
    let targetMenu = "/login"
    for (let index = 0; index < menu_mapping?.length; index++) {
      if (!menu_mapping[index]?.hasSubmenu){
        targetMenu = menu_mapping[index]?.menu
        break;
      }
    }
        return <Navigate to={targetMenu} replace />;
  }

  // Render the nested routes if accessible
  return <Outlet />;
};

export default ProtectedRoute;
