import { InputAdornment } from "@mui/material";
import CustomInput from "../CustomInput";
import SearchIcon from "@mui/icons-material/Search";
import { useDebouncedCallback } from "use-debounce";
import useThemeStore from "src/store/themeStore";

interface Props {
  onChangeFilter: (name: string, value: any) => void;
}
export default function FilterSearch({ onChangeFilter }: Props) {
  const { theme } = useThemeStore();

  const filterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeFilter("key", value);
  };

  const debouncedFilter = useDebouncedCallback(filterSearch, 1000);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e);
  };

  return (
    <CustomInput
      placeholder="Search"
      size="small"
      onChange={handleFilterChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: theme?.icon }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
