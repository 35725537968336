import { useLocation, useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import NotificationAlert from "src/components/main/Alert";
import {
  changeUserPassword,
  GetUserDetail,
  updateUserDetail,
} from "src/api/user";
import UserForm from "../../components/UserForm";
import CustomCard from "src/components/main/CustomCard";
import CustomText from "src/components/main/CustomText";
import Password from "../../components/Password";
import useThemeStore from "src/store/themeStore";
import ModalConfirm from "src/components/main/ModalConfirm";
import { useLoadingStore } from "src/store/loadingStore";
import { validatePassword } from "src/utils/utils";

export default function Detail() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const id = useLocation().pathname.split("/").pop();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<User>({ salary: 0 } as User);
  const [formData, setFormData] = useState<User>({ salary: 0 } as User);
  const [newPassword, setNewPassword] = useState<ChangePassword>({
    user_id: id,
  } as ChangePassword);
  const [modalUser, setModalUser] = useState<boolean>(false);
  const [modalPassword, setModalPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [errorNewPassword, setErrorNewPassword] = useState<string>("");

  const fetchUserDetail = async () => {
    try {
      const response = await GetUserDetail(id!);
      setFormData(response?.data);
      setInitialData(response?.data);
    } catch (error: any) {
      console.error("Error fetching user detail:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "username") {
      if (value.includes(" ")) {
        newValue = value.replace(/\s+/g, "");
      }
    }

    if (name === "email") {
      if (value.includes(" ")) {
        newValue = value.replace(/\s+/g, "");
      }
    }
    if (name === "phone_number") {
      newValue = value.replace(/[^0-9]/g, "");
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setModalUser(true);
  };

  const handleSubmit = async () => {
    await updateUserDetail(formData);

    navigate("/user");
  };

  const handleDiscard = () => {
    setIsEdit(false);
    setFormData(initialData);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "new_password") {
      newValue = value.replace(/\s+/g, "");
      if (value && value.length !== 0 && !validatePassword(value)) {
        setErrorNewPassword("Password must be at least 8 characters.");
      } else {
        setErrorNewPassword("");
      }
    }

    if (name === "password") {
      newValue = value.replace(/\s+/g, "");
      if (value && value.length !== 0 && !validatePassword(value)) {
        setError("Password must be at least 8 characters.");
      } else {
        setError("");
      }
    }

    setNewPassword((prevData: ChangePassword) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmitPasswordForm = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setModalPassword(true);
  };

  const handleSubmitPassword = async () => {
    const response = await changeUserPassword(newPassword);
    if (response?.status === 200) {
      navigate("/user");
    }

    setModalPassword(false);
  };

  return (
    <Box>
      <Box component={"form"} mt={2} onSubmit={handleSubmitForm}>
        <UserForm
          title="Account Details"
          action="update"
          isEdit={isEdit}
          formData={formData}
          onChange={handleInputChange}
        />
        <Box
          display={"flex"}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "flex-end",
            alignItems: {
              xs: "flex-start",
              md: "flex-end",
            },
            gap: {
              xs: 1,
              md: 2,
              lg: 3,
            },
          }}>
          {isEdit ? (
            <>
              <CustomButton
                variant="outlined"
                customType="cancel"
                onClick={handleDiscard}>
                Discard Changes
              </CustomButton>
              <CustomButton type="submit" variant="contained">
                Save Changes
              </CustomButton>
            </>
          ) : (
            <CustomButton variant="contained" onClick={handleEdit}>
              Edit
            </CustomButton>
          )}
        </Box>
      </Box>
      <Box component={"form"} mt={2} onSubmit={handleSubmitPasswordForm}>
        <CustomCard text="Change Password">
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={4}
            justifyContent={"space-between"}>
            <Stack width={"100%"}>
              <CustomText variant="body1">Old Password</CustomText>
              <Password
                fullWidth
                onChange={handleChangeNewPassword}
                required
                size="small"
                id="old_password"
                name="old_password"
              />
            </Stack>
            <Stack width={"100%"}>
              <CustomText variant="body1">New Password</CustomText>
              <Password
                fullWidth
                onChange={handleChangeNewPassword}
                required
                size="small"
                id="new_password"
                name="new_password"
                error={!!errorNewPassword}
                helperText={errorNewPassword}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    background: theme?.inputPrimary,
                    "& fieldset": {
                      borderColor: errorNewPassword
                        ? theme?.button100
                        : theme?.inputBorder,
                    },
                    "&:hover fieldset": {
                      borderColor: errorNewPassword
                        ? theme?.button100
                        : theme?.inputBorder,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: errorNewPassword
                        ? theme?.button100
                        : theme?.inputBorder,
                    },
                  },
                  "& .MuiFormHelperText-root": { color: theme?.button100 },
                }}
              />
            </Stack>
            <Stack width={"100%"}>
              <CustomText variant="body1">Confirm New Password</CustomText>
              <Password
                fullWidth
                onChange={handleChangeNewPassword}
                required
                helperText={
                  newPassword?.new_password !==
                  newPassword?.confirm_new_password
                    ? "Password does not match"
                    : ""
                }
                size="small"
                id="confirm_new_password"
                name="confirm_new_password"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    background: theme?.inputPrimary,
                    "& fieldset": {
                      borderColor:
                        newPassword?.new_password !==
                        newPassword?.confirm_new_password
                          ? theme?.button100
                          : theme?.inputBorder,
                    },
                    "&:hover fieldset": {
                      borderColor:
                        newPassword?.new_password !==
                        newPassword?.confirm_new_password
                          ? theme?.button100
                          : theme?.inputBorder,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        newPassword?.new_password !==
                        newPassword?.confirm_new_password
                          ? theme?.button100
                          : theme?.inputBorder,
                    },
                  },
                  "& .MuiFormHelperText-root": { color: theme?.button100 },
                }}
              />
            </Stack>
          </Box>
        </CustomCard>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <CustomButton
            type="submit"
            variant="contained"
            disabled={
              newPassword?.new_password !== newPassword?.confirm_new_password
            }>
            Change Password
          </CustomButton>
        </Box>
      </Box>
      <ModalConfirm
        text="Are you sure you want to update user detail?"
        open={modalUser}
        onClose={() => setModalUser(false)}
        onSubmit={handleSubmit}
      />
      <ModalConfirm
        text="Are you sure you want to change password?"
        open={modalPassword}
        onClose={() => setModalPassword(false)}
        onSubmit={handleSubmitPassword}
      />
    </Box>
  );
}
