import { create } from "zustand";

interface FinanceStore {
  id_grouping: string;
  setIdGroup: (id_grouping: string) => void;
}

export const useFinanceStore = create<FinanceStore>((set) => ({
  id_grouping: "",
  setIdGroup: (id_grouping: string) =>
    set(() => ({ id_grouping: id_grouping })),
}));
