import {
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import FilterAccordionDetail from "src/components/main/Filter/FilterAccordionDetail";
import FilterAccordion from "src/components/main/Filter/FilterAccordion";
import FilterHeader from "src/components/main/Filter/FilterHeader";
import { FINANCE_INCENTIVE_STATUSES } from "src/constants/finance";
import CustomCheckbox from "src/components/main/CustomCheckbox";
import InputDate from "src/components/main/Datepicker";
import InputYearPicker from "src/components/main/Datepicker/year_picker";
import { useEffect, useState } from "react";
import UserDropdown from "src/components/main/CustomDropDown";
import CustomUserDropdown from "src/components/main/CustomDropDown";

interface Props {
  mode: string;
  filter: FilterTable;
  onChangeFilter: (name: string, value: any) => void;
}
export default function Filter(props: Props) {
  const [periodeYear,setPeriodeYear] = useState<string>(new Date().getFullYear().toString());
  const [periode,setPeriode] = useState<string>("");

  const { theme } = useThemeStore();
  const { mode, filter, onChangeFilter } = props;

  const handleFilterStatus = (event: any) => {
    const filterStatus = event.target;
    if (filterStatus.checked) {
      onChangeFilter("status", [...filter.status, filterStatus.name]);
    } else {
      onChangeFilter(
        "status",
        filter.status.filter((status) => status !== filterStatus.name)
      );
    }
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "periode_year") {
      setPeriodeYear(value);
    }
  };

  const handleDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "periode") {
      setPeriode(value);
    }
  };

  useEffect(() => {
    if (periodeYear) {
      onChangeFilter("periode_year", periodeYear);
      onChangeFilter("periode", "");

    }
  }, [periodeYear]);

  useEffect(() => {
    if (periode) {
      onChangeFilter("periode", periode);
    }
  }, [periode]);

  return (
    <Box>
      <FilterHeader />
      <FilterAccordion
        defaultExpanded
        variant="outlined"
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          aria-controls="panel1-content"
          id="status"
        >
          <CustomText fontWeight={600}>Status</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          {FINANCE_INCENTIVE_STATUSES.map((item) => (
            <FormGroup key={item}>
              <FormControlLabel
                control={<CustomCheckbox sx={{ color: theme?.checkbox }} />}
                label={item}
                name={item}
                id={item}
                disabled={mode === "all" ? false : true}
                checked={filter.status.includes(item) ? true : false}
                onChange={handleFilterStatus}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: theme?.inputLabel,
                    "&.Mui-disabled": {
                      color: theme?.inputTextDisabled,
                    },
                  },
                }}
              />
            </FormGroup>
          ))}
        </FilterAccordionDetail>
      </FilterAccordion>

      <FilterAccordion
        defaultExpanded
        variant="outlined"
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          aria-controls="panel1-content"
          id="periode_year"
        >
          <CustomText fontWeight={600}>Period Year</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
        <Stack spacing={2}>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                Year Period
              </CustomText>

                <InputYearPicker
        id="year-picker"
        name="periode_year"
        value={periodeYear}// Example: Preselected year
        onChange={handleYearChange}
      />
            </Stack>

            <Stack>
              <CustomText variant="body2" gutterBottom>
                Period
              </CustomText>

              <CustomUserDropdown id="periode" name="periode" fetchCondition={periodeYear} onChange={handleDropdownChange}/>
            </Stack>
            
        </Stack>
          
        </FilterAccordionDetail>
      </FilterAccordion>

    </Box>
  );
}
