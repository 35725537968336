import { Stack, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import useThemeStore from "src/store/themeStore";
import CustomCheckbox from "../CustomCheckbox";

interface Props {
  columns: any[];
  handleSorting: (sortField: string | number, sortOrder: string) => void;
  sticky: boolean;
  checkbox: boolean;
  numSelected: number;
  rowCount: number;
  sortable: boolean;
  filter?: FilterTable;
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFilter?: (name: string, value: any) => void;
}
export default function TableHeader({
  columns,
  handleSorting,
  sticky,
  checkbox,
  numSelected,
  rowCount,
  sortable,
  filter,
  onSelectAll,
  onChangeFilter = () => {},
}: Props) {
  const { theme } = useThemeStore();

  const [hover, setHover] = useState<string | null>(null);

  const handleSortingChange = (field: string, originalValue: string) => {
    const sortOrder =
      field === filter?.order_key && filter?.sort_type === "ASC"
        ? "DESC"
        : "ASC";
    onChangeFilter("order_key", field);
    onChangeFilter("sort_type", sortOrder);
    sortable && handleSorting(originalValue, sortOrder);
  };

  const handleMouseEnter = (field: string | null) => {
    setHover(field);
  };

  const handleMouseLeave = () => {
    setHover(null);
  };

  const calculateMinWidth = (column: any) => {
    const titleLength = column.title?.length || 0;
    const padding = 20;
    const iconWidth = 20;

    return Math.max(titleLength * 8 + padding * 2 + iconWidth, iconWidth * 2);
  };

  return (
    <TableHead>
      <TableRow>
        {checkbox && (
          <TableCell
            padding={"checkbox"}
            sx={{
              p: 0,
              backgroundColor: theme?.tableHeader,
              color: theme?.tableText,
              textAlign: "center",
              borderColor: theme?.tableBorder,
            }}>
            <CustomCheckbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAll}
            />
          </TableCell>
        )}

        {columns?.map(
          (
            { title, field, originalValue, sortable, align, minWidth },
            index
          ) => (
            <TableCell
              key={field}
              onClick={
                sortable
                  ? () => handleSortingChange(field, originalValue)
                  : undefined
              }
              sx={{
                fontWeight: "bold",
                position: index === 0 && sticky ? "sticky" : "",
                backgroundColor: theme?.tableHeader,
                left: index === 0 && sticky ? 0 : "",
                color: theme?.tableText,
                cursor: sortable ? "pointer" : "default",
                "&:hover": {
                  color: sortable ? theme?.tableDivider : theme?.tableText,
                },
                // paddingRight:
                //   align === "right" ? "16px" : align === "left" ? 0 : "16px",
                // paddingLeft: align === "right" ? 0 : "16px",
                width: minWidth
                  ? minWidth
                  : calculateMinWidth(
                      columns.find((col) => col.field === field)
                    ),
                borderColor: theme?.tableBorder,
                textAlign: "left",
              }}
              onMouseEnter={() => handleMouseEnter(field)}
              onMouseLeave={() => handleMouseLeave()}>
              <Stack
                display={"flex"}
                alignItems={"center"}
                justifyContent={align === "center" ? "center" : "flex-start"}
                flexDirection={align === "right" ? "row-reverse" : "row"}>
                {title.toUpperCase()}
                {sortable && (
                  <>
                    {filter?.order_key === field &&
                      filter?.sort_type === "ASC" && (
                        <KeyboardArrowUpRoundedIcon />
                      )}
                    {filter?.order_key === field &&
                      filter?.sort_type === "DESC" && (
                        <KeyboardArrowDownRoundedIcon />
                      )}
                    {hover === field && filter?.order_key !== field && (
                      <UnfoldMoreRoundedIcon />
                    )}
                  </>
                )}
              </Stack>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}
