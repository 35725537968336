import { useEffect, useState } from "react";
import CustomTable from "../../../components/main/CustomTable";
import { useNavigate } from "react-router-dom";
import { Box, Card, Grid, Stack } from "@mui/material";
import FilterTable from "src/components/main/Filter";
import CustomText from "src/components/main/CustomText";
import { InquiryItemList } from "src/api/item";
import { useFilterStore } from "src/store/filterStore";
import { DecimalFormatter, getDayLeft } from "src/utils/formatter";
import useThemeStore from "src/store/themeStore";
import PageTitle from "src/components/main/CustomText/PageTitle";
import FilterSearch from "src/components/main/Filter/FilterSearch";
import { ITEM_COLUMNS } from "src/constants/item";

function ItemList() {
  const [data, setData] = useState<any[]>([]);
  const [itemStatuses, setItemStatuses] = useState<string[]>([]);
  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const { user_id, status, resetFilterStore } = useFilterStore();

  const navigate = useNavigate();
  const { theme } = useThemeStore();

  const onClickDetail = (id: string) => {
    navigate(`/item/${id}`);
  };

  const handleFilterChange = (name: string, value: any) => {
    if(name !== "page" && name !== "total_page"){
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await InquiryItemList({
        status: filter.status,
        id: filter.key,
        order_key: filter.order_key,
        sort_type: filter.sort_type,
        start_date: filter.start_date,
        end_date: filter.end_date,
        page: filter.page,
        limit: filter.limit,
        supplier_id: user_id,
        created_at: {
          start_date: filter.order_date_start,
          end_date: filter.order_date_end,
        },
      });
      response?.data?.list ? setData([...response.data.list]) : setData([]);
      handleFilterChange("total_page", response?.data?.totalPage);
      response?.data?.totalPage < filter.page && handleFilterChange("page", 1);
      setItemStatuses(response?.data?.StatusItem);
    } catch (error: any) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    filter.status,
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
    user_id,
    filter.order_date_start,
    filter.order_date_end,
  ]);

  useEffect(() => {
    // Apply filter status from selected item in dashboard
    handleFilterChange("status", [...status]);
    return () => {
      resetFilterStore();
    };
  }, []);

  const processedData = data.map((item: any) => {
    const dayLeft = getDayLeft(item?.end_date);
    const statusItem = item?.status;

    return {
      ...item,
      cost: `Rp ${DecimalFormatter(item?.cost?.toString())}`,
      end_date:
        statusItem === "COMPLETED" ? "-" :
        dayLeft > 0
          ? `${dayLeft} days left`
          : dayLeft === 0
          ? "Due Today"
          : `${Math.abs(dayLeft)} days overdue`,
    };
  });

  return (
    <Box>
      <PageTitle>Item List</PageTitle>
      <Grid container columnSpacing={2}>
        <Grid item sm={2}>
          <FilterTable
            statuses={itemStatuses}
            filter={filter}
            onChangeFilter={handleFilterChange}
          />
        </Grid>
        <Grid item sm={10}>
          <Card>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Stack sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Item List
                </CustomText>
              </Stack>
              <Box>
                <FilterSearch onChangeFilter={handleFilterChange} />
              </Box>
            </Box>
            <CustomTable
              columns={ITEM_COLUMNS}
              data={processedData}
              sortable={false}
              onClickDetail={onClickDetail}
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ItemList;
