import { AccordionSummary, Box, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import InputDate from "src/components/main/Datepicker";
import FilterAccordion from "src/components/main/Filter/FilterAccordion";
import FilterAccordionDetail from "src/components/main/Filter/FilterAccordionDetail";
import FilterHeader from "src/components/main/Filter/FilterHeader";
import { useEffect, useState } from "react";

interface Props {
  filter: FilterTable;
  onChangeFilter: (name: string, value: any) => void;
}

export default function Filter({ filter, onChangeFilter }: Props) {
  const { theme } = useThemeStore();
  const [tempStartDate, setTempStartDate] = useState<string>(filter.start_date);
  const [tempEndDate, setTempEndDate] = useState<string>(filter.end_date);

  useEffect(() => {
    if (tempStartDate && tempEndDate) {
      onChangeFilter("start_date", tempStartDate);
      onChangeFilter("end_date", tempEndDate);
    }
    if (tempStartDate === "" && tempEndDate === "") {
      onChangeFilter("start_date", "");
      onChangeFilter("end_date", "");
    }
  }, [tempStartDate, tempEndDate]);

  const filterData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "start_date") {
      setTempStartDate(value);
      value > tempEndDate && tempEndDate !== "" && setTempEndDate(value);
    }

    if (name === "end_date") {
      setTempEndDate(value);
    }
  };

  return (
    <Box>
      <FilterHeader />
      <FilterAccordion variant="outlined" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          id="date"
        >
          <CustomText>Last Order Date</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                Start Date
              </CustomText>
              <InputDate
                id="start_date"
                name="start_date"
                value={filter.start_date}
                onChange={(e) => filterData(e)}
              />
            </Stack>
            <Stack>
              <CustomText variant="body2" gutterBottom>
                End Date
              </CustomText>
              <InputDate
                id="end_date"
                name="end_date"
                minDate={tempStartDate}
                value={filter.end_date}
                onChange={(e) => filterData(e)}
              />
            </Stack>
          </Stack>
        </FilterAccordionDetail>
      </FilterAccordion>
    </Box>
  );
}
