import { formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const FindMenuID = (data: any[], menu: string) => {
  // Find the item where the menuParent matches the given menu
  const foundItem = data.find((item: { menuParent: string }) => item.menuParent === menu);

  // If found, return its id; otherwise, return the id of the first item or undefined if the array is empty
  return foundItem?.id ?? data[0]?.id;
};


export const GetWeekNumber = (date: Date): number => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - startOfYear.getTime()) / 86400000;

  const dayOfWeek = (startOfYear.getDay() + 6) % 7;
  return Math.ceil((pastDaysOfYear + dayOfWeek) / 7);
};

export const GetMonthName = (monthNumber: number) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthNumber - 1];
};

export const GetDateRangeOfWeek = (week: number, year: number) => {
  const firstDayOfYear = new Date(year, 0, 1);
  const daysOffset = (week - 1) * 7;
  const firstWeekDay = firstDayOfYear.getDay();
  const daysToAdd =
    daysOffset - (firstWeekDay <= 4 ? firstWeekDay - 1 : firstWeekDay - 8);
  const startDate = new Date(year, 0, daysToAdd + 1);

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  const startMonth = GetMonthName(startDate.getMonth() + 1);
  const endMonth = GetMonthName(endDate.getMonth() + 1);

  return `${startDate.getDate()} ${startMonth} - ${endDate.getDate()} ${endMonth}`;
};

export const hexToRgba = (hex: string, alpha: number) => {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const sortByProperty = (items: any[], prop: string) => {
  items.sort((a: { [x: string]: string }, b: { [x: string]: string }) => {
    const valueA = a[prop].toLowerCase();
    const valueB = b[prop].toLowerCase();

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });

  return items;
};

export const formatLabelChart = (value: string) => {
  const parts = value.split(" ");
  const weekPart = `${parts[0]} ${parts[1]}`;
  const dates = parts[2].split("-");
  const startDay = dates[1];
  const startMonth = dates[0];
  const endDay = dates[3];
  const endMonth = dates[2];

  return `${weekPart} ( ${startDay} ${startMonth} - ${endDay} ${endMonth} )`;
};

export const formatRelativeTime = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  const zonedDate = toZonedTime(date, "Asia/Bangkok");

  return formatDistanceToNow(zonedDate, { addSuffix: true });
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.startsWith("0")) {
    return "62" + phoneNumber.slice(1);
  }
  
  return phoneNumber;
};

export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
};
