import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomText from "src/components/main/CustomText";
import DifferenceIcon from "@mui/icons-material/Difference";
import { Grid, InputAdornment, Modal, Stack } from "@mui/material";
import CustomDivider from "src/components/main/CustomDivider";
import useThemeStore from "src/store/themeStore";
import CustomButton from "src/components/main/CustomButton";
import { DecimalFormatter } from "src/utils/formatter";
import { hexToRgba } from "src/utils/utils";
import CustomPaper from "src/components/main/CustomPaper";
import { useCallback, useEffect, useState } from "react";
import CustomDropbox from "src/components/main/CustomDropbox";
import ModalConfirm from "src/components/main/ModalConfirm";
import { addIncentiveBonunusses, disburseIncentive } from "src/api/finance";
import { useNavigate } from "react-router-dom";
import NewInputNominal from "src/components/main/NewInputNominal";
import CustomInput from "src/components/main/CustomInput";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    data: FinanceIncentiveList;
    refetch: () => void;
    onDetail?:boolean
  }



export default function ModalIncentiveWeb(props: Props) {
    const { isOpen, onClose, data, refetch,onDetail } = props;
    const theme = useTheme();
    const { theme: myTheme } = useThemeStore();
    const navigate = useNavigate();

  
  
    const [files, setFiles] = useState<BuktiPaymentBase64[][]>([[]]);
    const [activeStep, setActiveStep] = useState(0);
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [doneAction, setDoneAction] = useState<string[]>([]);
  
    const [formData, setFormData] = useState<AddIncentiveNew>({
        amount:0,
        id_grouping:"",
        periode:data?.year_month,
        user_id:data?.user_id,
        type_incentive:"",
      });
  
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData: any) => ({ ...prevData, [name]: value }));
      };
  
    const handleSubmit = async () => {
        const response = await addIncentiveBonunusses(formData);
        if (response?.status === 200) {
          refetch()
          onClose();
        }
        onClose();
    //   
    };

    const handleClose = () => {
      onClose();
    };
  
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <CustomPaper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "auto",
            boxShadow: 24,
            pt: 2,
            overflow:"auto"
          }}
        >
          <Box sx={{ width: "100%", flexGrow: 1 }}>
            <Box sx={{ px: 4 }}>
              <Stack direction={"row"} gap={2}>
                <DifferenceIcon
                  style={{ color: myTheme?.icon }}
                  fontSize="large"
                />
                <CustomText variant="h6" gutterBottom>
                  Add Incentive
                </CustomText>
              </Stack>
              <CustomDivider sx={{ mt: 1 }} />
              <Box sx={{ py: 2 }}>
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  alignContent={"center"}
                  justifyContent={"flex-start"}
                >
                  <CustomText
                    variant="subtitle1"
                    fontWeight={"bold"}
                    gutterBottom
                  >
                    {data?.year_month}
                  </CustomText>
                </Stack>
                <Grid container columnSpacing={2}>
                  <Grid item sm={3}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        User Name
                      </CustomText>
                      <CustomText variant="subtitle1" fontWeight={700}>
                        {data?.nama_worker}
                      </CustomText>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Role
                      </CustomText>
                      <CustomText variant="subtitle1" fontWeight={700}>
                        {data?.role_name}
                      </CustomText>
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Type Incentive
                      </CustomText>
                      <CustomInput
              required
              value={formData?.type_incentive}
              onChange={handleChange}
              size="small"
              id="type_incentive"
              name="type_incentive"
            />
                    </Stack>
                  </Grid>
                  <Grid item sm={3}>
                    <Stack mb={2}>
                      <CustomText variant="body2" gutterBottom={false}>
                        Amount Incentive
                      </CustomText>
                      {/* <CustomText variant="subtitle1" fontWeight={700}>
                        {`Rp ${DecimalFormatter(
                          data?.[activeStep]?.total_cost?.toString()
                        )}`}
                      </CustomText> */}
                                      <NewInputNominal
                  required
                  size="small"
                  id="amount"
                  name="amount"
                  value={formData?.amount.toString()}
                    onChange={handleChange}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
                    </Stack>
                  </Grid>
                </Grid>  
                <Box mt={2}>
                  {!doneAction?.includes(
                    `${data?.year_month}-${data?.user_id}`
                  ) && (
                    <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                      <CustomButton
                        variant="contained"
                        onClick={() => setConfirmDialog(true)}
                      >
                        Add Incentive
                      </CustomButton>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
  
            <ModalConfirm
              text="Are you sure you want to Add Incentive ?"
              open={confirmDialog}
              onClose={() => setConfirmDialog(false)}
              onSubmit={handleSubmit}
            />
          </Box>
        </CustomPaper>
      </Modal>
    );
}
