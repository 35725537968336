import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import useThemeStore from "src/store/themeStore";

interface InputYearPickerProps {
  id: string;
  name: string;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?: (e: any) => void;
}

export default function InputYearPicker({
  id,
  name,
  disabled,
  defaultValue,
  value,
  onChange,
}: InputYearPickerProps) {
  const { theme } = useThemeStore();

  const disableKeyboardEntry = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleChange = (date: any | null) => {
    if (onChange) {
      onChange({
        target: {
          name: name,
          value: date ? date.format("YYYY") : "", // Format date to only include the year
        },
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <DatePicker
        views={["year"]} // Only show the year view
        disabled={disabled}
        onChange={handleChange}
        value={value ? moment(value, "YYYY") : null} // Parse value as a year
        defaultValue={defaultValue ? moment(defaultValue, "YYYY") : null}
        sx={{
          "& .MuiOutlinedInput-root": {
            background: `linear-gradient(22.5deg, ${theme?.inputPrimary}, ${theme?.inputSecondary})`,
            "& fieldset": {
              borderColor: theme?.inputBorder,
            },
            "&:hover fieldset": {
              borderColor: theme?.inputBorder,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme?.inputBorder,
            },
            height: "40px",
          },
          "& .MuiInputBase-root": {
            color: theme?.inputText,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: theme?.inputTextDisabled, // Disabled input text color
            },
            "& .MuiSvgIcon-root": {
              color: theme?.icon,
            },
          },
        }}
        slotProps={{
          field: { clearable: false },
          textField: {
            size: "small",
            fullWidth: true,
            id: id,
            name: name,
            required: true,
            onBeforeInput: disableKeyboardEntry,

          },
        }}
      />
    </LocalizationProvider>
  );
}
