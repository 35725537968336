import {
  Box,
  Button,
  Grid,
  MobileStepper,
  Modal,
  Stack,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import CustomDivider from "src/components/main/CustomDivider";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import ModalConfirm from "src/components/main/ModalConfirm";
import DifferenceIcon from "@mui/icons-material/Difference";
import { DecimalFormatter } from "src/utils/formatter";
import { disburseSalary } from "src/api/finance";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@material-ui/icons";
import CustomDropbox from "src/components/main/CustomDropbox";
import CustomInput from "src/components/main/CustomInput";
import { hexToRgba } from "src/utils/utils";
import useThemeStore from "src/store/themeStore";
import { getItemLogAttachmentGroup } from "src/api/item";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: FinanceSalary[];
  refetch: () => void;
  attachmentDetai:string;
}

function ActionDisbursment({ isOpen, onClose, data,refetch ,attachmentDetai}: Props) {
  const theme = useTheme();

  const { theme: myTheme } = useThemeStore();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [files, setFiles] = useState<BuktiPaymentBase64[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [doneAction, setDoneAction] = useState<string[]>([]);
  const [attachments, setAttachment] = useState<Attachment[]>([{}] as Attachment[]) 
  const maxSteps = data?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };


     const fetchAttachment = async () => {
      console.log("DATA",attachmentDetai)
        if (attachmentDetai) {
          console.log("ATTACHMENT")
          const response = await getItemLogAttachmentGroup(attachmentDetai);
          setAttachment(response?.data);
        }
      };

      useEffect(() => {
        fetchAttachment(); // No argument passed
      }, [attachmentDetai]); 

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setFiles([])

  }, [data?.[activeStep]?.id]);
  const fileReader = useCallback(
    (acceptedFiles: File[]) => {
      const fileDataArray: BuktiPaymentBase64[] = [];

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryStr = reader.result as string;
          const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
          if (matches) {
            const mimeType = matches[1];
            const base64Data = matches[2];

            fileDataArray.push({
              bukti_payment_base64: base64Data,
              tipe_file: mimeType.split("/")[1],
            });

            if (fileDataArray?.length === acceptedFiles?.length) {
              setFiles((prevData) => [...prevData, ...fileDataArray]);
            }
          } else {
            console.error("Invalid file data");
          }
        };

        reader.readAsDataURL(file);
      });
    },
    [files]
  );

  const handlePaymentFileUpload = (acceptedFiles: File[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setFiles((prevData) => {
        const updatedAttachments = [...prevData];
        updatedAttachments.splice(index, 1);
        return updatedAttachments;
      });
    },
    [setFiles]
  );

  const handleSubmit = async () => {
    const req: DisburseSalary = {
      id: data?.[activeStep].id,
      bukti_payment:files,
    };

    console.log("FIless",req)
    const response = await disburseSalary(req);
    if (response?.status === 200) {
      setOpenModal(false);
      onClose();
      return;
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "auto",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ width: "100%", flexGrow: 1 }}>
          <Box sx={{ px: 4 }}>
            <Stack direction={"row"} gap={2}>
              <DifferenceIcon
                style={{ color: myTheme?.icon }}
                fontSize="large"
              />
              <CustomText variant="h6" gutterBottom>
                Disburse Salary
              </CustomText>
            </Stack>
            <CustomDivider sx={{ mt: 1 }} />
            <Box sx={{ py: 2 }}>
              <Grid container columnSpacing={2} alignItems={"flex-start"}>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      User Name
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data?.[activeStep]?.user_name || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Role
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data?.[activeStep]?.role_name || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Period
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data?.[activeStep]?.period || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Amount
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={"bold"}>
                      {`Rp ${DecimalFormatter(
                        data?.[activeStep]?.salary?.toString()
                      )}` || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Disburse Date
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={"bold"}>
                    {data?.[activeStep]?.disbursed_date || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
              </Grid>
              {data?.[activeStep]?.status === "Pending" ? (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    gap={2}
  >
    <Stack mb={2}>
      <CustomText variant="body2" gutterBottom>
        Proof of Payment
      </CustomText>
      <CustomDropbox
        filesType="base64"
        files={files}
        onUpload={(file) => handlePaymentFileUpload(file)}
        deleteByIndex={true}
        onDeleteByIndex={(index) => handlePaymentFileDelete(index)}
      />
    </Stack>
  </Box>
) : (
  <Box
    display="flex"
    flexDirection={attachments?.length > 1 ? "column" : "row"}
    justifyContent="flex-start"
    alignContent="center"
    gap={2}
  >
    <Stack mb={2}>
      <CustomDropbox existingFiles={attachments || []} viewOnly={true} />
    </Stack>
  </Box>
)}



              <Box mt={2}>
              {!doneAction?.includes(data?.[activeStep]?.id) && data?.[activeStep]?.status === "Pending" && (
  <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
    <CustomButton
      variant="contained"
      onClick={() => setOpenModal(true)}
    >
      Approve
    </CustomButton>
  </Box>
)}
              </Box>
            </Box>
          </Box>

          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              background: myTheme?.bgPrimary,
              color: myTheme?.inputLabel,
            }}
            nextButton={
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                gap={8}
                mr={2}
              >
                <Button
                  sx={{
                    color: myTheme?.inputLabel,
                    "&.Mui-disabled": {
                      color: hexToRgba(myTheme?.inputLabel, 0.5),
                    },
                  }}
                  size="medium"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
                <Button
                  sx={{
                    color: myTheme?.inputLabel,
                    "&.Mui-disabled": {
                      color: hexToRgba(myTheme?.inputLabel, 0.5),
                    },
                  }}
                  size="medium"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              </Box>
            }
            backButton={<Box />}
          />

          <ModalConfirm
            text="Are you sure you want to disburse?"
            open={openModal}
            onClose={() => setOpenModal(false)}
            onSubmit={handleSubmit}
          />
        </Box>
      </CustomPaper>
    </Modal>
  );
}

export default ActionDisbursment;
