import { createTheme, ThemeProvider } from "@mui/material";
import useThemeStore from "src/store/themeStore";

export default function RootTheme({ children }: { children: React.ReactNode }) {
  const { theme } = useThemeStore();
  const rootTheme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
            borderRadius: "8px",
            background: theme?.bgPaper,
            border: `1px solid ${theme?.border}`,
            variants: "outlined",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: theme?.border,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: theme?.inputLabel,
            "&.Mui-selected": {
              color: "primary",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "primary",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: theme?.icon,
            "&.Mui-disabled": {
              color: theme?.inputTextDisabled,
              cursor: "not-allowed",
              "&.Mui-checked": {
                color: "#1976d2",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            "&.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled":
              {
                color: theme?.inputTextDisabled,
                cursor: "not-allowed",
              },
            "& .MuiFormControlLabel-label": {
              color: theme?.inputLabel,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: theme?.icon,
            "&.Mui-disabled": {
              color: theme?.inputTextDisabled,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              color: theme?.inputText,
            },
            "& .MuiInputBase-root .Mui-disabled": {
              "& .MuiSvgIcon-root": {
                color: theme?.inputTextDisabled,
              },
            },
          },
          option: {
            "&.MuiBox-root.Mui-focused": {
              backgroundColor: theme?.bgPrimary, // Change the color for focused option
            },
            '&[aria-selected="true"]': {
              backgroundColor: theme?.bgPrimary, // Change the color for selected option
            },
          },
        },
      },
    },
  });
  return <ThemeProvider theme={rootTheme}>{children}</ThemeProvider>;
}
