export const SALARY_COLUMNS = [
  { title: "Period", field: "period", sortable: false, minWidth: 100 },
  {
    title: "Name",
    field: "user_name",
    sortable: false,
    minWidth: 150,
  },
  { title: "Role", field: "role_name", sortable: false, minWidth: 100 },
  {
    title: "Disbursed Date",
    field: "disbursed_date",
    sortable: false,
    minWidth: 150,
  },
  {
    title: "Amount",
    field: "amount",
    originalValue: "salary",
    sortable: false,
    minWidth: 150,
  },
  { title: "Status", field: "status", sortable: false, minWidth: 100 },
];

export const FINANCE_REPORT_COLUMNS = [
  { title: "Period", field: "id", sortable: true, minWidth: 100 },
  {
    title: "Total (IDR)",
    field: "amountIdr",
    originalValue: "amount_idr",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    title: "Total (USD)",
    field: "amountUsd",
    originalValue: "amount_usd",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    title: "Total Payment",
    field: "totalPayment",
    originalValue: "total_payment",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    title: "Item Cost",
    field: "totalItemExpense",
    originalValue: "total_item_expense",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    title: "Salary Cost",
    field: "expenseSalary",
    originalValue: "expense_salary",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    title: "Incentive Cost",
    field: "incentiveExpense",
    originalValue: "incentive_expense",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    title: "Nett Profit (IDR)",
    field: "totalRevenue",
    originalValue: "revenue",
    sortable: true,
    align: "right",
    minWidth: 125,
  },
  { title: "Status Report", field: "status", sortable: true, minWidth: 100 },
];

export const FINANCE_DETAIL_INCOME_COLUMNS = [
  { title: "Date", field: "date", sortable: false, minWidth: 100 },
  { title: "Order Date", field: "order_date", sortable: false, minWidth: 100 },
  {
    title: "Month Repayment",
    field: "month_repayment",
    sortable: false,
    minWidth: 150,
  },
  { title: "Order ID", field: "order_id", sortable: false, minWidth: 100 },
  { title: "Item ID", field: "item_id", sortable: false, minWidth: 100 },
  {
    title: "Payment Type",
    field: "payment_type",
    sortable: false,
    minWidth: 130,
  },
  { title: "Currency", field: "currency", sortable: false, minWidth: 100 },
  {
    title: "Payment Method",
    field: "payment_method",
    sortable: false,
    minWidth: 150,
  },
  {
    title: "Amounts",
    field: "amount",
    sortable: false,
    minWidth: 100,
    align: "right",
  },
];

export const FINANCE_DETAIL_EXPENSE_COLUMNS = [
  { title: "Pay To", field: "pay_to", sortable: true },
  { title: "Type Outcome", field: "type_outcome", sortable: true },
  { title: "Amounts", field: "amount", sortable: true, align: "right" },
  { title: "Date", field: "tanggal", sortable: true },
  { title: "Status", field: "status", sortable: true },
];

export const FINANCE_INCENTIVE_COLUMNS = [
  { title: "Period", field: "label_year_month", sortable: true, minWidth: 120 },
  {
    title: "Name",
    field: "nama_worker",
    sortable: true,
    minWidth: 100,
  },
  { title: "Role", field: "role_name", sortable: true, minWidth: 100 },
  {
    title: "Disburse Date",
    field: "disburse_date",
    sortable: true,
    minWidth: 100,
  },
  {
    title: "Amount",
    field: "cost",
    originalValue: "total_cost",
    sortable: true,
    minWidth: 150,
  },
  { title: "Status", field: "status", sortable: true, minWidth: 100 },
];

export const FINANCE_INCENTIVE_STATUSES = ["Pending", "Paid","Ongoing"];

export const FINANCE_INCENTIVE_DETAIL_PERIOD_COLUMNS = [
  {title:"Type Incentive",field:"incentive_type",sortable:false},
  { title: "Task ID", field: "taskId", sortable: false },
  { title: "Task Point", field: "taskLevel", sortable: false },
  { title: "Man Days", field: "mainDay", sortable: false, align: "right" },
  { title: "Start Date", field: "startDate", sortable: false },
  { title: "End Date", field: "endDate", sortable: false },
  { title: "Incentive (IDR)", field: "amount", sortable: false, align: "right" },
];


export const MENU_MANAGEMENT_TABLE = [
  {title:"Menu",field:"menu_name",sortable:false},
  { title: "Sub Menu", field: "sub_menu", sortable: false },
  { title: "Route", field: "route", sortable: false },
  { title: "Updated At", field: "updated_date", sortable: false },
  { title: "Updated By", field: "updated_by", sortable: false },
  { title: "Actions", field: "actions", sortable: false, align: "center" },

];