import { Box, Modal, Stack } from "@mui/material";
import CustomDivider from "src/components/main/CustomDivider";
import CustomText from "src/components/main/CustomText";
import DifferenceIcon from "@mui/icons-material/Difference";
import CustomTable from "src/components/main/CustomTable";
import { DecimalFormatter } from "src/utils/formatter";
import { useCallback, useEffect, useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import { disburseReimbursement } from "src/api/reimburse";
import ModalConfirm from "src/components/main/ModalConfirm";
import CustomDropbox from "src/components/main/CustomDropbox";
import { getItemLogAttachmentGroup } from "src/api/item";
import CustomInput from "src/components/main/CustomInput";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Reimbursement[];
  refetch: () => void;
  fetchData?:() => void;
  attachmentDetai?:string;
  statusReimburse?:string;
}

export default function ActionReimbursement(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { isOpen, onClose, data, refetch,statusReimburse,attachmentDetai } = props;
  const [attachments, setAttachment] = useState<any>([]);
  const [files, setFiles] = useState<Attachment[]>([{}] as Attachment[]);


    const fetchAttachment = async () => {
      if (attachmentDetai) {
        const response = await getItemLogAttachmentGroup(attachmentDetai);
        setFiles(response?.data);
      }
    };

    useEffect(() => {
      // This code runs only once when the component mounts
      setAttachment([])
    }, [isOpen]); 

    useEffect(() => {
      fetchAttachment(); // No argument passed
    }, [attachmentDetai]); 

    useEffect(() => {
      setFiles([])
      setAttachment([])
    }, [statusReimburse]); 
  
  const totalPrice = data?.reduce((accumulator, item) => {
    return accumulator + (item.cost || 0);
  }, 0);

  const formatData = () => {
    const processedData = data?.map((item: any, index: number) => {
      return {
        ...item,
        no: index + 1,
        cost: `Rp ${DecimalFormatter(item?.cost?.toString())}`,
        claim_date: item?.claim_date?.split("T")[0],
      };
    });

    return processedData;
  };


   const fileReader = useCallback((acceptedFiles: any[]) => {
      const fileDataArray: any[] = [];
  
      acceptedFiles.forEach((file: Blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryStr: any = reader.result;
          const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
          if (matches) {
            const mimeType = matches[1];
            const base64Data = matches[2];
  
            fileDataArray.push({
              bukti_payment_base64: base64Data,
              tipe_file: mimeType.split("/")[1],
            });
  
            if (fileDataArray?.length === acceptedFiles?.length) {
              setAttachment((prevData: any) => [
                ...(prevData || []), 
                ...fileDataArray
              ]);
            }
          } else {
            console.error("Invalid file data");
          }
        };
        reader.readAsDataURL(file);
      });
    }, []);
  
    const handlePaymentFileUpload = (acceptedFiles: any[]) => {
      fileReader(acceptedFiles);
    };
  
    const handlePaymentFileDelete = useCallback(
      (index: number) => {
        setAttachment((prevData: any) => {
          const updatedAttachments = [...prevData];
          updatedAttachments.splice(index, 1);
          return updatedAttachments; // Return the updated array directly
        });
      },
      [setAttachment]
    );

  const handleSubmit = async () => {
    const req: DisburseReimbursement = {
      reimburse_id: data?.map((item) => item.reimburse_id),
      payment_method: "Bank Transfer",
      payment_currency: "IDR",
      bukti_payment:attachments
      
    };
    const response = await disburseReimbursement(req);
    if (response?.status === 200) {
      setOpenModal(false);
      onClose();
      await refetch();
    }
    setFiles([]);
    setAttachment([]);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "auto",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ width: "100%", flexGrow: 1 }}>
          <Box>
            <Stack direction={"row"} gap={2}>
              <DifferenceIcon style={{ color: "#fff" }} fontSize="large" />
              <CustomText variant="h6" gutterBottom>
                Reimbursement
              </CustomText>
            </Stack>
            <CustomDivider sx={{ mt: 1 }} />
            <Box sx={{ py: 2 }}>
              <Box>
                <CustomTable
                  columns={columns}
                  data={formatData()}
                  pagination={false}
                  stickyHeader
                  sx={{ maxHeight: "50vh" }}
                />
              </Box>
              {
                statusReimburse === "Approved" ? (
                  <>
                                <Box mt={2}>
              <CustomText variant="body1" gutterBottom required>
                Proof of Payment
              </CustomText>
              <CustomDropbox
                files={attachments}
                filesType="base64"
                onUpload={handlePaymentFileUpload}
                deleteByIndex={true}
                onDeleteByIndex={handlePaymentFileDelete}
              />
            </Box>
                  </>
                ): <>
                <Box
                display={"flex"}
                flexDirection={files?.length > 1 ? "column" : "row"}
                justifyContent={"flex-start"}
                alignContent={"center"}
                gap={2}>
                <Stack mb={2}>
                  <CustomDropbox existingFiles={files} viewOnly={true} />
                </Stack>
              </Box>
                </>
              }
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={2}
                gap={6}
              >
                <Stack>
                  <CustomText variant="h6" gutterBottom fontWeight={"bold"}>
                    {`${data?.length} data selected`}
                  </CustomText>
                </Stack>
                <Stack>
                  <CustomText variant="h6" gutterBottom fontWeight={"bold"}>
                    Total Shipment Price :&emsp;
                    {`Rp ${DecimalFormatter(totalPrice?.toString())}`}
                  </CustomText>
                </Stack>
              </Box>
              {statusReimburse === "Approved" ? (
                <>
                <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                <CustomButton
                  variant="contained"
                  onClick={() => setOpenModal(true)}
                >
                  Reimburse
                </CustomButton>
              </Box>
                </>
              ) : null}
            </Box>
          </Box>
          <ModalConfirm
            text="Are you sure to do this action?"
            open={openModal}
            onClose={() => setOpenModal(false)}
            onSubmit={handleSubmit}
          />
        </Box>
      </CustomPaper>
    </Modal>
  );
}

const columns = [
  { title: "No", field: "no", sortable: false, minWidth: 50 },
  { title: "Expense ID", field: "ship_id", sortable: false, minWidth: 100 },
  {
    title: "Item Name",
    field: "reimburse_name",
    sortable: false,
    minWidth: 250,
  },
  { title: "User Name", field: "user_name", sortable: false },
  { title: "Expedition", field: "expedtition", sortable: false, minWidth: 100 },
  {
    title: "Price",
    field: "cost",
    sortable: false,
    align: "right",
    minWidth: 100,
  },
  { title: "Date", field: "claim_date", sortable: false },
];
