import { Box } from "@mui/material";
import ReportForms from "../components/ReportForms";
import BreadCrumbs from "src/components/navigators/BreadCrumb";
import colors from "src/themes/colors";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import CustomText from "src/components/main/CustomText";

export default function FinanceReportCreate() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = value;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      
      <CustomText textAlign={"center"} variant="h5">
        Create Report
      </CustomText>
      <ReportForms value={formData} onChange={handleInputChange} />
      <Box
        mt={4}
        display={"flex"}
        width={"100%"}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "flex-end",
          alignItems: {
            xs: "flex-start",
            md: "flex-end",
          },
          gap: {
            xs: 1,
            md: 2,
            lg: 3,
          },
        }}
      >
        <CustomButton
          onClick={() => navigate("/finance/monthly-recap")}
          variant="contained"
          customType="cancel"
        >
          Cancel
        </CustomButton>
        <CustomButton
          type="submit"
          variant="contained"
          style={{ backgroundColor: colors?.primary200 }}
        >
          Create Report
        </CustomButton>
      </Box>
    </Box>
  );
}
