import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { GetDesignDataComplte } from "src/api/user";
import { useDebouncedCallback } from "use-debounce";
import CustomInput from "../../CustomInput";
import CustomText from "../../CustomText";
import useThemeStore from "src/store/themeStore";

export default function AutoCompleteDesign(props: any) {
  const { onChange, valueItem, isEdit,...otherProps } = props ?? {}; // Destructure onChange from props
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assigneeList, setAssigneeList] = useState<any>([]);

  const { theme } = useThemeStore();

  // Fetch search results from the API
  const fetchSearchAssignee = async (key: string) => {
    try {
      const response = await GetDesignDataComplte(key);
      setAssigneeList(response?.data || []); // Update the list with the response data
    } catch (error) {
      console.error("Error fetching data:", error);
      setAssigneeList([]); // Handle error scenario by clearing the list
    }
  };

  // Debounced function to call the fetch function after delay
  const debouncedFetch = useDebouncedCallback((key: string) => {
    if (key.length >= 3) {
      fetchSearchAssignee(key); // Fetch search result if input length is greater than 3
    } else {
      setAssigneeList([]); // Clear the list if input length <= 3
    }
  }, 1000);

  // Handle input change and trigger debounced function
  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue);
  };

  // Set valueItem on mount if it's provided
  useEffect(() => {
    if (valueItem?.design_name) {
      setAssigneeList((prevList:any) => [
        ...prevList,
        { design_name: valueItem?.design_name }, // Make sure this matches the structure of your options
      ]);
    }
  }, [valueItem]);

  return (
    <Autocomplete
      {...otherProps}
      disabled={isEdit}
      options={assigneeList}
      noOptionsText="Type Design Name..."
      fullWidth
      autoComplete
      freeSolo // Allow custom user input
      getOptionLabel={(option: { design_name: string } | string) =>
        typeof option === "string" ? option : option?.design_name || ""
      }
      isOptionEqualToValue={(option: any, value: any) =>
        option?.design_name === value?.design_name // Ensure the value is correctly compared
      }
      loading={isLoading}
      open={open}
      value={valueItem?.design_name || ""}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
      onBlur={onChange} // Handles blur to trigger search or action
      id="design_name"
      componentName="design_name"
      renderInput={(params) => (
        <CustomInput
          {...params}
          id="design_name"
          name="design_name"
          placeholder="Type Design Name"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option: any) => (
        <Box
          component={"li"}
          {...props}
          sx={{
            backgroundColor: theme?.bgSecondary,
            color: theme?.inputText,
            "&.MuiAutocomplete-option.Mui-focused": {
              backgroundColor: theme?.bgPrimary,
            },
          }}
        >
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText
                primary={<CustomText>{option?.design_name}</CustomText>}
              />
            </ListItem>
            <Divider sx={{ borderBottomWidth: 4 }} />
          </List>
        </Box>
      )}
    />
  );
}
