import React, { useEffect, useState } from "react";
import { Box, Card, Stack, ToggleButtonGroup } from "@mui/material";
import {
  BarPlot,
  ChartsGrid,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer,
  ChartsLegend,
  ChartsTooltip,
} from "@mui/x-charts";
import useThemeStore from "src/store/themeStore";
import CustomText from "src/components/main/CustomText";
import { dataDaily, dataWeekly, dataMonthly, dataYearly } from "../chartData";
import CustomButton from "src/components/main/CustomButton";
import CustomToggle from "src/components/main/CustomToggle";
import InputDate from "src/components/main/Datepicker";
import { GetChartData } from "src/api/dashboard";
import NotificationAlert from "src/components/main/Alert";
import {
  hexToRgba,
  GetMonthName,
  GetWeekNumber,
  formatLabelChart,
} from "src/utils/utils";
import CustomPaper from "src/components/main/CustomPaper";
import { DecimalFormatter } from "src/utils/formatter";
import CustomDivider from "src/components/main/CustomDivider";
import CustomCard from "src/components/main/CustomCard";
import InputYearPicker from "src/components/main/Datepicker/year_picker";
import InputMonthPicker from "src/components/main/Datepicker/month_picker";

type TPeriod = "daily" | "monthly" | "weekly" | "yearly";
type TCurr = "idr" | "usd";

function Chart() {
  const { theme } = useThemeStore();

  const [data, setData] = useState<any>(dataWeekly);
  const [alignment, setAlignment] = useState<TPeriod>("monthly");
  const [curr, setCurr] = useState<TCurr>("idr");
  const [date, setDate] = useState<any>();
  const [week, setWeek] = useState<number>(GetWeekNumber(new Date()));
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [yearSelect, setYearSelect] = useState<string>(new Date().getFullYear().toString());
  const [monthSelect, setMonthSelect] = useState<string>((new Date().getMonth()+1).toString());


  const labelsArray = Object.values(data).map((item: any) => item?.label);
  const idrArray = Object.values(data).map((item: any) => item?.idr);
  const usdIdrArray = Object.values(data).map((item: any) => item?.usd_idr);
  const usdArray = Object.values(data).map((item: any) => item?.usd);
  const amountArray = Object.values(data).map((item: any) => item?.total_order);

  const fetchDataChart = async () => {
    const req = {
      filter: alignment,
      week: week,
      month: month,
      year: year,
    };
    try {
      const response = await GetChartData(req);
      setData(response?.data ?? dataWeekly);
    } catch (error: any) {
      console.error("Error fetching chart data:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setYearSelect(value)
    setYear(Number(value))
    fetchDataChart();
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMonthSelect(value)
    setMonth(Number(value))
    fetchDataChart();
  };

  useEffect(() => {
    fetchDataChart();
  }, [alignment, week, month, year]);

  useEffect(() => {
    setWeek(GetWeekNumber(new Date()));
    setMonth(new Date().getMonth() + 1);
    setYear(new Date().getFullYear());
  }, []);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: TPeriod
  ) => {
    if (newAlignment === null) {
      return;
    }
    setAlignment(newAlignment);
  };

  return (
    <CustomCard text="Analytics" sx={{ height: "100%", mt: 0 }}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{ backgroundColor: theme?.inputPrimary }}
          >
            <CustomToggle label="Daily" value="daily" />
            <CustomToggle label="Weekly" value="weekly" />
            <CustomToggle label="Monthly" value="monthly" />
          </ToggleButtonGroup>
        </Box>
        <Box display={"flex"} flexDirection={"row"} gap={"6px"}>
          {alignment !== "monthly" ? (
                 <Stack>
                 <CustomText variant="body2" gutterBottom>
                   Month Period
                 </CustomText>
   
                   <InputMonthPicker
           id="month-picker"
           name="month_select"
           value={monthSelect}// Example: Preselected year
           onChange={handleMonthChange}
           calendarYear={year}
         
         />
               </Stack>
          ):""}
            <Stack>
              <CustomText variant="body2" gutterBottom>
                Year Period
              </CustomText>

                <InputYearPicker
        id="year-picker"
        name="year_select"
        value={yearSelect}// Example: Preselected year
        onChange={handleYearChange}
      />
            </Stack>
        </Box>
      </Box>
      <Box bgcolor={theme?.bgPaper} textAlign={"center"}>
        <CustomText variant="h5" mt={2}>
          {year}
        </CustomText>
        <CustomText variant="h5" mt={2}>
          {alignment === "weekly"
            ? GetMonthName(month)
            : alignment === "daily"
            ? GetMonthName(month)
            : ""}
        </CustomText>
        <ResponsiveChartContainer
          height={alignment === "weekly" ? 380 : 300}
          margin={{ left: 100, bottom: alignment === "weekly" ? 80 : 50 }}
          series={[
            {
              id: "idrId",
              type: "bar",
              data: idrArray,
              color: theme?.secondary100,
              yAxisKey: "idrId",
              valueFormatter: (value) => {
                const safeValue = value ?? 0; // Fallback to 0 if value is null or undefined
                return `Rp ${DecimalFormatter(safeValue.toString())}`;
              },
              label: "IDR",
              stack: "total",
            },
            {
              id: "usdId",
              type: "bar",
              data: usdIdrArray,
              color: theme?.secondary200,
              yAxisKey: "idrId",
              valueFormatter: (_, { dataIndex }) => {
                const { usd } = data[dataIndex] ?? 0;
                return `$ ${DecimalFormatter(usd?.toString())}`;
              },
              label: "USD",
              stack: "total",
            },
            {
              id: "amountId",
              type: "line",
              data: amountArray,
              color: "gold",
              yAxisKey: "amountId",
              label: "Order",
              stack: "total",
            },
          ]}
          xAxis={[
            {
              id: "labelId",
              scaleType: "band",
              data: labelsArray,
              tickLabelStyle: {
                angle: alignment === "weekly" ? 45 : 0,
                dominantBaseline: "hanging",
                textAnchor: "start",
              },
              valueFormatter: (value, context) => {
                if (alignment === "daily") {
                  return value?.split("-")?.[0];
                }
                if (alignment === "weekly") {
                  return context.location === "tick"
                    ? value?.split(" ")?.slice(0, 2).join(" ")
                    : formatLabelChart(value);
                } else {
                  return value;
                }
              },
            },
          ]}
          yAxis={[{ id: "idrId" }, { id: "amountId" }]}
          sx={{
            // change all labels fontFamily shown on both xAxis and yAxis
            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
              fontFamily: "Roboto",
            },
            // change bottom label styles
            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
              strokeWidth: "0.5",
              fill: "white",
            },
            // bottomAxis Line Styles
            "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
              stroke: "white",
              fill: "white",
            },
            // leftAxis Line Styles
            "& .MuiChartsAxis-left .MuiChartsAxis-line": {
              stroke: "white",
              fill: "white",
            },
            //change left yAxis label styles
            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
              strokeWidth: "0.4",
              fill: "white",
            },
            // leftAxis Left Tick
            "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
              stroke: "white",
            },
            // leftAxis Line Styles
            "& .MuiChartsAxis-right .MuiChartsAxis-line": {
              stroke: "white",
              fill: "white",
            },
            //change left yAxis label styles
            "& .MuiChartsAxis-right .MuiChartsAxis-tickLabel": {
              strokeWidth: "0.4",
              fill: "white",
            },
            // leftAxis Left Tick
            "& .MuiChartsAxis-right .MuiChartsAxis-tick": {
              stroke: "white",
            },
            // leftAxis Left Tick
            "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
              strokeWidth: "0",
            },

            "& .MuiChartsLegend-series text": { fill: "white !important" },

            "& .MuiChartsGrid-line": { stroke: "#505050" },
          }}
        >
          <ChartsGrid horizontal />
          <ChartsXAxis axisId="labelId" />
          <ChartsYAxis position="left" axisId="idrId" />
          <ChartsYAxis position="right" axisId="amountId" />
          <BarPlot />
          <LinePlot />
          <ChartsTooltip />
          <ChartsLegend
            position={{ horizontal: "middle", vertical: "top" }}
            sx={{
              marginTop: "100px",
            }}
          />
        </ResponsiveChartContainer>
      </Box>
    </CustomCard>
  );
}

export default Chart;
