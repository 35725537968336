import {
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import FilterAccordionDetail from "src/components/main/Filter/FilterAccordionDetail";
import FilterAccordion from "src/components/main/Filter/FilterAccordion";
import FilterHeader from "src/components/main/Filter/FilterHeader";
import { FINANCE_INCENTIVE_STATUSES } from "src/constants/finance";
import CustomCheckbox from "src/components/main/CustomCheckbox";

interface Props {
  mode: string;
  filter: FilterTable;
  onChangeFilter: (name: string, value: any) => void;
}
export default function Filter(props: Props) {
  const { theme } = useThemeStore();
  const { mode, filter, onChangeFilter } = props;

  const handleFilterStatus = (event: any) => {
    const filterStatus = event.target;
    if (filterStatus.checked) {
      onChangeFilter("status", [...filter.status, filterStatus.name]);
    } else {
      onChangeFilter("status", filter.status.filter((status) => status !== filterStatus.name));
    }
  };

  return (
    <Box>
      <FilterHeader />
      <FilterAccordion
        defaultExpanded
        variant="outlined"
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme?.icon }} />}
          aria-controls="panel1-content"
          id="status"
        >
          <CustomText fontWeight={600}>Status</CustomText>
        </AccordionSummary>
        <FilterAccordionDetail>
          {FINANCE_INCENTIVE_STATUSES.map((item) => (
            <FormGroup key={item}>
              <FormControlLabel
                control={<CustomCheckbox sx={{ color: theme?.checkbox }} />}
                label={item}
                name={item}
                id={item}
                checked={filter.status.includes(item) ? true : false}
                onChange={handleFilterStatus}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: theme?.inputLabel,
                    "&.Mui-disabled": {
                      color: theme?.inputTextDisabled,
                    },
                  },
                }}
              />
            </FormGroup>
          ))}
        </FilterAccordionDetail>
      </FilterAccordion>
    </Box>
  );
}
