import { Box, Grid, Paper, Stack } from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import useThemeStore from "src/store/themeStore";
import { useEffect, useState } from "react";
import { approveLeave, rejectLeave } from "src/api/user";
import ModalConfirm from "../ModalConfirm";
import { useNavigate } from "react-router-dom";

interface Props {
  data: any;
}
export default function Leave({ data }: Props) {
  const navigate = useNavigate();
  const [notes, setNotes] = useState(data?.admin_notes || "");
  const [openApprove, setOpenApprove] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState<boolean>(false);

  useEffect(() => {
    if (data?.admin_notes) {
      setNotes(data.admin_notes);
    }
  }, [data]);
  const handleApprove = async () => {
    const req: ApproveRejectLeave = {
      leave_id: data?.id,
      notes: notes,
    };

    try {
      await approveLeave(req);
      navigate("/leave");
    } catch (error) {
      console.error("Error handling approve leave", error);
    } finally {
      setOpenApprove(false);
    }
  };

  const handleReject = async () => {
    const req: ApproveRejectLeave = {
      leave_id: data?.id,
      notes: notes,
    };

    try {
      await rejectLeave(req);
      navigate("/leave");
    } catch (error) {
      console.error("Error handling reject leave", error);
    } finally {
      setOpenReject(false);
    }
  };

  return (
    <Box>
      <Grid container rowGap={4}>
        <Grid item sm={12} md={6} lg={3}>
          <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
            Title Leave
          </CustomText>
          <CustomText variant="subtitle1">{data?.title_leave}</CustomText>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
            Type Leave
          </CustomText>
          <CustomText variant="subtitle1">{data?.type_leave}</CustomText>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
            Start Date
          </CustomText>
          <CustomText variant="subtitle1">
            {data?.start_date?.split("T")[0]}
          </CustomText>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
            End Date
          </CustomText>
          <CustomText variant="subtitle1">
            {data?.end_date?.split("T")[0]}
          </CustomText>
        </Grid>
      </Grid>
      <Stack mt={4}>
        <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
          User Notes
        </CustomText>
        <CustomText variant="body1" gutterBottom>
          {data?.user_notes}
        </CustomText>
      </Stack>
      <Stack mt={4}>
        <CustomText variant="body1" fontWeight={"bold"} gutterBottom>
          Admin Notes
        </CustomText>
        <CustomInput
          multiline
          minRows={2}
          size="small"
          id="notes"
          name="notes"
          placeholder="Input Notes...."
          onChange={(e) => setNotes(e.target.value)}
          value={notes}
        />
      </Stack>
      {data?.is_active && (
        <Box mt={3} display={"flex"} justifyContent={"flex-end"} gap={3}>
        <CustomButton
          customType="cancel"
          variant="outlined"
          onClick={() => setOpenReject(true)}
        >
          Reject
        </CustomButton>
        <CustomButton variant="contained" onClick={() => setOpenApprove(true)}>
          Approve
        </CustomButton>
      </Box>
      )}
      <ModalConfirm
        text="Are you sure you want to approve this leave?"
        open={openApprove}
        onClose={() => setOpenApprove(false)}
        onSubmit={() => handleApprove()}
      />
      <ModalConfirm
        text="Are you sure you want to reject this leave?"
        open={openReject}
        onClose={() => setOpenReject(false)}
        onSubmit={() => handleReject()}
      />
    </Box>
  );
}
