import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import { sendRequestDELETE, sendRequestGET, sendRequestPOST, sendRequestPUT } from "../sendRequest";
import { useLoadingStore } from "src/store/loadingStore";
import { HideLoading, ShowLoading } from "src/components/main/Loading";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();

interface ItemList {
    status?: Array<string>;
    start_date?: string;
    end_date?: string;
    id?: string;
    page: number;
    limit: number;
    order_key?: string;
    sort_type?: string;
  }
export const InquiryRolePaginationList = async (
    {
      status,
      start_date,
      end_date,
      id,
      page,
      limit,
      order_key,
      sort_type,
    }: ItemList = { page: 1, limit: 10 }
  ) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_ROLE_PAGINATION_DATA || "";
    const menu_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menu_id && { "app-menu-id": menu_id }),
    };
  
    try {
  
      const req = {
        status,
        start_date,
        end_date,
        id,
        page,
        limit,
        order_key,
        sort_type,
      };
      ShowLoading({});
      const res = await sendRequestPOST(url, req,header);
      console.info("ACTION LEAVE LIST", res);
      return res.data;
    } catch (error: any) {
      showNotification(error.message, "error");
      console.error("ACTION ERROR LEAVE LIST", error);
  
      throw new Error(error);
    } finally {
      HideLoading();
    }
  };

  export const GetRoleDetail = async (id: string) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_ROLE_DETAIL || "";
    const menu_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menu_id && { "app-menu-id": menu_id }),
    };
  
    try {
      ShowLoading({});
      const res = await sendRequestGET(`${url}/${id}`, header);
      HideLoading();
      console.info("ACTION LEAVE DETAIL", res);
      return res.data;
    } catch (error: any) {
      HideLoading();
      showNotification(error.message, "error");
      console.error("ACTION ERROR LEAVE DETAIL", error);
      throw new Error(error);
    }
  };


  export const GetRoleMenuUnasignee = async (id: string) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_ROLE_MENU_UNASIGNEE || "";
    const menu_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menu_id && { "app-menu-id": menu_id }),
    };
  
    try {
      ShowLoading({});
      const res = await sendRequestGET(`${url}/${id}`, header);
      HideLoading();
      console.info("ACTION LEAVE DETAIL", res);
      return res.data;
    } catch (error: any) {
      HideLoading();
      showNotification(error.message, "error");
      console.error("ACTION ERROR LEAVE DETAIL", error);
      throw new Error(error);
    }
  };


  interface AddMenuMapping {

    menu_id: string;
    role_id: string;
    selectedMenu:string[];
  }
export const AddMappingMenu = async (
    {
      menu_id,
      role_id,
      selectedMenu
    }: AddMenuMapping = { menu_id: "", role_id: "" ,selectedMenu:[]}
  ) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_MAPPING_MENU_ADD || "";
    const menus_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menus_id && { "app-menu-id": menus_id }),
    };
  
    try {
  
      const req = {
        menu_id,
        role_id,
        selectedMenu
      };
      ShowLoading({});
      const res = await sendRequestPOST(url, req,header);
      console.info("ACTION LEAVE LIST", res);
      return res.data;
    } catch (error: any) {
      showNotification(error.message, "error");
      console.error("ACTION ERROR LEAVE LIST", error);
  
      throw new Error(error);
    } finally {
      HideLoading();
    }
  };


  export const DelteRoleMenuUnasignee = async (id: string) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_MAPPING_MENU_DELETE || "";
    const menu_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menu_id && { "app-menu-id": menu_id }),
    };
  
    try {
      ShowLoading({});
      const res = await sendRequestDELETE(`${url}/${id}`, header);
      HideLoading();
      console.info("ACTION LEAVE DETAIL", res);
      return res.data;
    } catch (error: any) {
      HideLoading();
      showNotification(error.message, "error");
      console.error("ACTION ERROR LEAVE DETAIL", error);
      throw new Error(error);
    }
  };


  interface UpdateRoleData {

    id: string;
    code: string;
    name:string;
    desc:string;
  }
export const UpdateROleData = async (
    {
      id,
      code,
      name,
      desc,
    }: UpdateRoleData = { id: "", code: "",name:"",desc:"" }
  ) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_ROLE_UPDATE || "";
    const menus_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menus_id && { "app-menu-id": menus_id }),
    };
  
    try {
  
      const req = {
        id,
        code,
        name,
        desc
      };
      ShowLoading({});
      const res = await sendRequestPUT(url, req,header);
      console.info("ACTION LEAVE LIST", res);
      return res.data;
    } catch (error: any) {
      showNotification(error.message, "error");
      console.error("ACTION ERROR LEAVE LIST", error);
  
      throw new Error(error);
    } finally {
      HideLoading();
    }
  };


  export const AddRoleData = async (
    {
      id,
      code,
      name,
      desc,
    }: UpdateRoleData = { id: "", code: "",name:"",desc:"" }
  ) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_ROLE_UPDATE || "";
    const menus_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menus_id && { "app-menu-id": menus_id }),
    };
  
    try {
  
      const req = {
        id,
        code,
        name,
        desc
      };
      ShowLoading({});
      const res = await sendRequestPOST(url, req,header);
      console.info("ACTION LEAVE LIST", res);
      return res.data;
    } catch (error: any) {
      showNotification(error.message, "error");
      console.error("ACTION ERROR LEAVE LIST", error);
  
      throw new Error(error);
    } finally {
      HideLoading();
    }
  };


  export const DelteRole = async (id: string) => {
    const userStore = useUserStore.getState();
    const url = process.env.REACT_APP_ENDPOINT_ROLE_DELETE || "";
    const menu_id = FindMenuID(userStore.menu_mapping, "/access-management");
    const header = {
      ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      ...(menu_id && { "app-menu-id": menu_id }),
    };
  
    try {
      ShowLoading({});
      const res = await sendRequestDELETE(`${url}/${id}`, header);
      HideLoading();
      console.info("ACTION LEAVE DETAIL", res);
      return res.data;
    } catch (error: any) {
      showNotification(error.message, "error");
      HideLoading();
      console.error("ACTION ERROR LEAVE DETAIL", error);
      throw new Error(error);
    }
  };